<template>
  <div style="margin-top: 10px" class="ml-3">
    <b-row>
      <b-col class="col-custom" lg="2" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="editReport"
          variant="success"
          size="sm"
          class="float-light"
          @click="alertBeforeNewRecord"
        >
          <b-icon icon="plus-circle" font-scale="1"></b-icon> Añadir
          Fila</b-button
        >
      </b-col>
      <b-col class="col-custom" lg="2" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="editReport"
          variant="success"
          size="sm"
          class="float-light"
          @click="openModalNewColumn"
        >
          <b-icon icon="plus-circle" font-scale="1"></b-icon> Añadir
          Columna</b-button
        >
      </b-col>
      <b-col class="col-custom" lg="3" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="dataTable.columns.length > 0 && editReport"
          @click="openModalDeleteCol"
          variant="danger"
          size="sm"
          class="float-light"
        >
          <b-icon icon="x-circle" font-scale="1"></b-icon> Eliminar
          Columna</b-button
        >
      </b-col>
      <b-col class="col-custom" lg="2" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="select_row.length > 0 && editReport"
          @click="deleteRow"
          variant="danger"
          size="sm"
          class="float-light"
        >
          <b-icon icon="x-circle" font-scale="1"></b-icon> Eliminar
          fila(s)</b-button
        >
      </b-col>
      <b-col class="col-custom" lg="3" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="hideButton"
          variant="primary"
          size="sm"
          class="float-light"
          @click="saveTemplateReport"
        >
          Actualizar plantilla</b-button
        >
      </b-col>
      <b-col class="col-custom" lg="2" md="4" sm="6" align-self="center">
        <b-button
          block
          v-if="exportFile"
          class="float-light"
          variant="success"
          size="sm"
          @click="exportAsExcel"
        >
          <b-icon icon="download" font-scale="1"></b-icon>
          Exportar
        </b-button>
      </b-col>

      <b-col class="col-custom" lg="2" md="4" sm="6" align-self="center">
        <UPLOADEXCEL
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
          :simple="true"
        />
      </b-col>

      <b-col class="col-custom" v-if="delete_cols.length > 0" lg="12">
        <p>
          <strong>* Columnas eliminadas, no se pueden editar. </strong>
        </p>
        <b-badge
          class="m-1"
          variant="warning"
          v-for="(col, index) in delete_cols"
          :key="index"
          >{{ col.name }}</b-badge
        >
        <!-- <pre>{{ delete_cols }}</pre> -->
      </b-col>
      <b-col lg="12" md="12" sm="12" xs="12">
        <vue-excel-editor
          remember
          @select="onSelect"
          ref="grid"
          v-model="this.table.rows"
          disable-panel-filter
          :readonly="!editTemplate"
          no-header-edit
          style="width: 100%; margin-top: 20px"
          :page="10"
          :localized-label="myLabels"
        >
          <vue-excel-column
            v-for="(c, i) in this.table.columns"
            :key="i"
            :field="c"
            :sort="sort"
            :label="c"
            sticky
            key-field
          />
        </vue-excel-editor>
      </b-col>
    </b-row>
    <MODALADDCOLUMN
      ref="modalAddColumn"
      :tableHeader="this.table.columns"
      @save-name-modal="saveColumn"
    />
    <MODALDELETECOLUMN
      ref="modalDeleteColumn"
      :tableHeader="this.table.columns"
      @delete-column-modal="deleteColumn"
    />
  </div>
</template>

<script>
import MODALADDCOLUMN from "@/components/departments-module/reports/add-column-name";
import MODALDELETECOLUMN from "@/components/departments-module/reports/delete-column-modal";
import UPLOADEXCEL from "@/components/departments-module/reports/excel";
import Swal from "sweetalert2";
var _ = require("lodash");

export default {
  props: {
    dataTable: Object,
    editReport: Boolean,
    hideButton: {
      default: false,
      type: Boolean
    },
    exportFile: {
      default: false,
      type: Boolean
    },
    name: {
      require: false,
      type: String,
      default: "file"
    },
    editTemplate: {
      require: false,
      default: true,
      type: Boolean
    },
    onDataChange: Function
  },
  components: { MODALADDCOLUMN, MODALDELETECOLUMN, UPLOADEXCEL },

  data() {
    return {
      table: { rows: this.dataTable.rows, columns: this.dataTable.columns },
      col_name: "",
      delete_cols: [],
      select_row: [],
      myLabels: {
        footerLeft: (top, bottom, total) =>
          `Record ${top} to ${bottom} of ${total}`,
        first: "Primero",
        previous: "Anterior",
        next: "Siguiente",
        last: "Ultimo",
        footerRight: {
          selected: "Seleccionado:",
          filtered: "Filtrado:",
          loaded: "Cargado:"
        },
        processing: "Procesando",
        tableSetting: "Ajuste de la tabla",
        exportExcel: "Exportar Excel",
        importExcel: "Importar Excel",
        back: "Atrás",
        reset: "Defecto",
        sortingAndFiltering: "Clasificación y filtrado",
        sortAscending: "Orden ascendente",
        sortDescending: "Orden descendiente",
        near: "≒ Cerca",
        exactMatch: "= Coincidencia exacta",
        notMatch: "≠ No coincide",
        greaterThan: "&lt;Mas grande que",
        greaterThanOrEqualTo: "≥ Mayor o igual que",
        lessThan: "&lt; Menos que",
        lessThanOrEqualTo: "≤ Menor o igual a",
        regularExpression: "~ Expresión regular",
        customFilter: "Filtro personalizado",
        listFirstNValuesOnly: n => `Mostrar solo los primeros ${n} valores`,
        apply: "Aplicar",
        noRecordIsRead: "No se lee ningún registro",
        readonlyColumnDetected: "Columna de solo lectura detectada",
        columnHasValidationError: (name, err) =>
          `La columna ${name} tiene un error de validación: ${err}`,
        noMatchedColumnName: "Sin nombre de columna coincidente",
        invalidInputValue: "Valor de entrada no válido",
        missingKeyColumn: "Falta la columna de clave",
        noRecordIndicator: "Sin registro"
      }
    };
  },

  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.table.rows = results;
      let index = 1;
      this.table.rows.forEach(element => {
        element.indice = index;
        index++;
      });

      this.table.columns = header;
      this.table.columns.unshift("indice");

      this.onDataChange && this.onDataChange({ results, header });
    },
    saveTemplateReport() {
      this.$emit("template-report", this.dataTable);
    },
    deleteColumn(columns) {
      this.delete_cols = _.concat(this.delete_cols, columns);
      columns.forEach(element => {
        this.deleteOneCol(element.name);
      });
      this.$emit("change-data", this.dataTable);
    },
    deleteOneCol(column) {
      this.dataTable.columns = _.filter(this.dataTable.columns, function(col) {
        return col !== column;
      });
      this.$refs.grid.fields.forEach(field => {
        if (field.label === column) {
          field.invisible = false;
          field.readonly = true;
          field.initStyle = {
            background: "#ced4da"
          };
        }
      });
      this.dataTable.rows.forEach(element => {
        delete element[column];
      });
    },
    openModalDeleteCol() {
      this.$refs.modalDeleteColumn.show();
    },
    deleteRow() {
      Swal.fire({
        title: `<strong>Eliminar fila(s)</strong>`,
        icon: "info",
        html: `Estas seguro de eliminar la(s) fila(s) ${this.select_row} `,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel'
      }).then(result => {
        if (result.isConfirmed) {
          if (this.select_row.length > 0) {
            this.select_row.forEach(element => {
              this.$refs.grid.deleteRecord(element);
            });
            this.$emit("change-data", this.dataTable);

            // this.$emit("change-data", {
            //   tableData: this.dataTable.rows,
            //   tableHeader: this.dataTable.columns,
            // });
          }
          Swal.close();
        } else {
          this.select_row = [];
          Swal.close();
        }
      });
    },
    onSelect(selectedRows) {
      this.select_row = selectedRows;
    },

    alertBeforeNewRecord() {
      let aux =
        this.select_row.length > 0 ? parseInt(this.select_row[0]) + 1 : 0;
      Swal.fire({
        html: `<h4>Añadir fila ${
          this.select_row.length > 0
            ? "despues de la fila # " + aux
            : "al final"
        } </h4>`,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel'
      }).then(result => {
        if (result.isConfirmed) {
          this.newRecord();
        }
        this.select_row = [];
        Swal.close();
      });
    },

    newRecord() {
      let allData = this.dataTable.rows;
      let left = [];
      let right = [];
      const rec = {};
      // console.log("que gila", this.select_row[0]);
      // console.log("que gila esd", this.dataTable.rows.length);

      if (
        this.select_row.length > 0 &&
        this.select_row[0] + 1 < this.dataTable.rows.length
      ) {
        let index = this.select_row[0] + 1;
        this.dataTable.rows = _.take(allData, index);
        right = _.takeRight(allData, allData.length - this.select_row[0] - 1);
        this.dataTable.rows.push({
          $id: allData[index].$id
        });
        right.forEach(element => {
          let ids = element.$id.split("-");
          ids[1] = this.addZero(parseInt(ids[1]) + 1);
          element.$id = _.join(ids, "-");
          this.dataTable.rows.push(element);
        });

        allData = left;
        this.$emit("change-data", this.dataTable);

        // this.$emit("change-data", {
        //   tableData: this.dataTable.rows,
        //   tableHeader: this.dataTable.columns
        // });
      } else {
        this.dataTable.rows.push(rec);
        // this.$emit("change-data", {
        //   tableData: this.dataTable.rows,
        //   tableHeader: this.dataTable.columns
        // });
        this.$emit("change-data", this.dataTable);
      }
    },
    addZero(data) {
      let d = "";
      if (data < 10) {
        d = "000000" + data;
      } else if (data >= 10 && data < 100) {
        d = "00000" + data;
      } else if (data >= 100) {
        d = "0000" + data;
      }
      return d;
    },
    saveColumn(data) {
      if (data.column === "first") {
        this.dataTable.columns.unshift(data.name);
      } else {
        let index = this.dataTable.columns.indexOf(data.column.trim()) + 1;
        this.dataTable.columns.splice(index, 0, data.name);
      }
      this.$emit("change-data", this.dataTable);
    },
    openModalNewColumn() {
      this.$refs.modalAddColumn.show();
    },
    sort() {
      return null;
    },
    exportAsExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = `${this.name}`;
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    }
  }
};
</script>

<style scoped>
.col-custom {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
