<template>
  <div>
    <b-row>
      <b-col lg="4">
        <b-form-group
          v-if="type != 'direct'"
          id="fieldset-1"
          label="Tipo de selección"
          label-for="input-1"
        >
          <multiselect
            @input="selectionEmployees"
            v-model="selection"
            :options="selection_employees"
            :group-select="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione uno tipo"
            label="label"
            track-by="label"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <b-form-group id="fieldset-1" label="Empleados" label-for="input-1">
          <multiselect
            v-model="employees"
            :options="all_employees"
            :group-select="true"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione uno tipo"
            label="name"
            track-by="name"
          >
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <div class="text-right mt-3">
      <b-button ref="btnSubmit" @click="save" variant="primary" size="sm">
        Añadir
      </b-button>
      <b-button
        class="ml-3"
        variant="secondary"
        size="sm"
        @click="$emit('close-modal-add')"
      >
        Cerrar
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module";
import {
  LIST_CHATS,
  UPDATE_CHAT,
  SELECT_CHAT,
  LIST_MESSAGES
} from "@/services/store/chat.module";

import Swal from "sweetalert2";
export default {
  props: ["chat", "action", "type"],
  data() {
    return {
      selection_employees: [
        { id: 7, label: "Personalizado" },
        { id: 6, label: "Todos" },
        { id: 1, label: "Socios" },
        { id: 2, label: "Lideres" },
        { id: 3, label: "Empleados" },
        { id: 5, label: "Becarios" }
      ],
      selection: { id: 7, label: "Personalizado" },
      pruba_arreglo: "",
      employees: [],
      form: {
        id: this.chat.id,
        name: this.chat.attributes.name,
        type: this.chat.attributes.type,
        employees: []
      },
      errors: {}
    };
  },
  created() {
    this.form.employees = _.map(this.chat.relationships.employees, d => {
      return d.id;
    });
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "getChat", "currentUser"]),
    all_employees() {
      return _.map(this.getEmployeesAll, d => {
        return { id: d.id, name: d.attributes.name };
      });
    },
    gChat() {
      return this.getChat;
    }
  },
  methods: {
    selectionEmployees() {
      this.employees = [];

      this.getEmployeesAll.forEach(e => {
        if (this.selection.id === 6) {
          this.employees.push({ id: e.id, name: e.attributes.name });
        } else if (this.selection.id === e.attributes.role_id) {
          this.employees.push({ id: e.id, name: e.attributes.name });
        }
      });
    },
    save() {
      if (this.employees.length === 0) {
        Swal.fire("Error!!", "Seleciones uno o mas empleados", "error");
        return;
      }
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let emp = _.map(this.employees, d => {
        return d.id;
      });

      emp.forEach(element => {
        if (this.form.employees.indexOf(element) === -1) {
          this.form.employees.push(element);
        }
      });
      this.$store
        .dispatch(UPDATE_CHAT, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire(
              "Ok!",
              "Se actualizo los empleados del chat correctamente",
              "success"
            );
            this.$socket.emit("ADD_CHAT", response.data.data);

            this.$emit("close-modal-add");
            this.$store.dispatch(LIST_CHATS);
            this.$store.dispatch(SELECT_CHAT, atob(this.$route.params.chat));
            this.$store.dispatch(LIST_MESSAGES, {
              chat: atob(this.$route.params.chat),
              page: 1
            });
          }
        })
        .catch(e => {
          this.errors = e;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("Error!!", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    }
  }
};
</script>
