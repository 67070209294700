<template>
  <b-container>
    <b-overlay :show="overlay" rounded="md">
      <b-row>
        <b-col cols md="12">
          <b-card v-show="permissionsAsign">
            <b-row>
              <b-col v-show="permissionsCreate" cols md="4">
                <div
                  @click="showModalAddDepartment = true"
                  class="d-flex align-items-center rounded p-4 gutter-b bg-primary newFormation"
                  style="cursor: pointer"
                >
                  <span class="svg-icon mr-5 svg-icon-light iconFolder">
                    <inline-svg
                      style="width:50px;height:50px"
                      :src="
                        `media/svg/icons/Department/hierarchical-structure-organization.svg`
                      "
                    />
                  </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      class="font-weight-bold text-light-75 text-light font-size-lg mb-1"
                    >
                      Departamento
                    </a>
                  </div>
                </div>
              </b-col>
              <b-col cols md="4">
                <div
                  @click="showModalAssignEmployeeDepartment = true"
                  class="d-flex align-items-center rounded p-4 gutter-b bg-primary newFormation"
                  style="cursor: pointer"
                >
                  <span class="svg-icon mr-5 svg-icon-light iconFolder">
                    <inline-svg
                      style="width:50px;height:50px"
                      :src="`media/svg/icons/Department/svgrepo.svg`"
                    />
                  </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      class="font-weight-bold text-light-75 text-light font-size-lg mb-1"
                    >
                      Asignar Personal
                    </a>
                  </div>
                </div>
              </b-col>

              <b-col v-if="permissionsCreate" cols md="4">
                <div
                  @click="showReport"
                  class="d-flex align-items-center rounded p-4 gutter-b bg-primary "
                  style="cursor: pointer"
                >
                  <span class="svg-icon mr-5 svg-icon-light iconFolder">
                    <inline-svg
                      style="width: 50px; height: 50px;"
                      :src="`media/svg/icons/Department/report.svg`"
                    />
                  </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a
                      class="font-weight-bold text-light-75 text-light font-size-lg mb-1"
                    >
                      Reportes
                    </a>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="getDepartments.length > 0">
        <b-col
          cols
          md="4"
          class="mt-4"
          v-for="(element, idx) in getDepartments"
          :key="idx"
        >
          <b-card
            class="card-custom card-stretch gutter-b bg-success"
            @click="show(element)"
            style='cursor:pointer;background-position: right top; 
              background-size: 18%; background-image: url("media/svg/shapes/departments.svg");'
          >
            <b-row>
              <blockquote class="blockquote mb-0">
                <p
                  class="font-weight-bold text-light-75 text-light font-size-lg mb-1"
                  style="cursor:pointer"
                >
                  {{ element.attributes.name }}
                </p>
              </blockquote>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col cols md="12" class="mt-4">
          <b-card no-body>
            <b-card-text class="text-center">
              <h5>
                <img src="media/svg/misc/empty.svg" />
                <p class="mt-6">No perteneces a un departamento</p>
              </h5>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!--=============================MODAL DE DEPARTAMENTOS DE EMPLEADO================================= --->

    <b-modal
      v-model="showModalAddDepartment"
      id="modal-add-department"
      size="lg"
      hide-footer
      title="Añadir Departamento"
    >
      <div class="d-block">
        <WIDGETADDDEPARTMENT
          :department="{}"
          :action="'create'"
          @close-modal-add="closeModalAdd"
        />
      </div>
    </b-modal>
    <!-- ============================FIN DE MODAL DEPARTAMENTOS DE EMPLEADO======================================= -->

    <!--=============================MODAL DE ASIGNAR  DE EMPLEADO================================= --->
    <b-modal
      v-model="showModalAssignEmployeeDepartment"
      id="modal--department"
      size="xl"
      hide-footer
      title="Asignar Departamentos/Empleados/Lideres"
    >
      <div class="d-block">
        <WIDGETASSIGNDEPARTMENT @close-modal-update="closeModalUpdate" />
      </div>
    </b-modal>
    <!-- ============================FIN DE MODAL ASIGNAR  DE EMPLEADO======================================= -->
  </b-container>
</template>
<style>
inline-svg.sizeSvg {
  width: 50px;
  height: 50px;
}
</style>

<script>
import { mapGetters } from "vuex";
import WIDGETADDDEPARTMENT from "@/components/departments-module/widget-add-department";
import WIDGETASSIGNDEPARTMENT from "@/components/departments-module/widget-assign-department";

import {
  LIST_DEPARTMENTS,
  SHOW_DEPARTMENT
} from "@/services/store/department.module";

export default {
  components: {
    WIDGETADDDEPARTMENT,
    WIDGETASSIGNDEPARTMENT
  },
  data() {
    return {
      rolesCreate: [1],
      rolesAsign: [1, 2],
      overlay: false,
      showModalAddDepartment: false,
      showModalAssignEmployeeDepartment: false
    };
  },
  computed: {
    ...mapGetters(["getDepartments", "currentUser"]),
    permissionsCreate() {
      return this.rolesCreate.indexOf(this.currentUser.attributes.rol_id) != -1;
    },
    permissionsAsign() {
      return this.rolesAsign.indexOf(this.currentUser.attributes.rol_id) != -1;
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_DEPARTMENTS);
  },
  methods: {
    show(element) {
      this.overlay = true;
      this.$store
        .dispatch(SHOW_DEPARTMENT, element.id)
        .then(response => {
          if (response !== null || response !== {}) {
            this.overlay = false;
            this.$router.push({
              name: "showDepartment",
              params: { department: element.attributes.name }
            });
          }
        })
        .catch(e => console.error(e));
    },

    showReport() {
      this.$router.push({
        name: "showReportDepartment",
        params: { report: "Reporte" }
      });
    },
    closeModalAdd(status) {
      this.showModalAddDepartment = status;
    },
    closeModalUpdate(status) {
      this.showModalAssignEmployeeDepartment = status;
    }
  },
  mounted() {}
};
</script>
