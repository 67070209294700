import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_DEPARTMENTS = "listDepartments";
export const SHOW_DEPARTMENT = "showDepartmentview";
export const STORE_DEPARTMENT = "storeDepartment";
export const UPDATE_DEPARTMENT = "update";

export const UPDATE_TEMPLATE_REPORT = "updateTemplateReport";

export const DELETE_DEPARTMENT = "delete";
export const JOBS_DEPARTMENTS_USER = "jobsDepartmentsUser";
export const ASSIGN_EMPLOYEES_TO_DEPARTMENT = "assignEmployeesToDepartment";
export const ASSIGN_DEPARTMENTS_TO_EMPLOYEE = "assignDepartmentsToEmployee";
export const UNASSIGN_EMPLOYEE_TO_DEPARTMENT = "unassignEmployeeToDepartment";
export const REPORTS_DEPARTMENTS = "reportsDepartments";
export const DELETE_DEPARTMENT_REPORT = "deleteReportDepartment";

// mutation types
export const SET_DEPARTMENTS = "setDepartments";
export const SET_ERROR = "setError";
export const SET_JOBS_USER = "setJobsUser";
export const SET_SHOW_DEPARTMENT = "setShowDepartments";

export const SET_STORE_DEPARTMENT = "setStoreDepartments";
export const SET_ASSIGN_DEPARTMENTS_TO_EMPLOYEE = "setDepartmentsToEmployees";
export const SET_REPORTS_DEPARTMENTS = "setReportsDepartments";
export const SET_UPDATE_DEPARTMENT = "setUpdateDepartment";
export const SET_DELETE_DEPARTMENT_REPORT = "setDeleteReportDepartment";
export const GET_DEPARTMENTS = "getDepartments";
export const GET_JOBS_USER = "getJobsUser2";
export const GET_SHOW_DEPARTMENT = "getShowDepartment";
export const GET_REPORTS_DEPARTMENTS = "getReportsDepartments";

const state = {
  errors: null,
  departments: [],
  user_jobs: [],
  department: {},
  unassign_user: {},
  employees_departments: [],
  reports_departments: []
};

const getters = {
  [GET_SHOW_DEPARTMENT](state) {
    return state.department;
  },
  [GET_DEPARTMENTS](state) {
    return state.departments;
  },
  [GET_JOBS_USER](state) {
    return state.user_jobs;
  },
  [GET_REPORTS_DEPARTMENTS](state) {
    return state.reports_departments;
  }
};

const actions = {
  [UPDATE_TEMPLATE_REPORT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/reports/update/template/report/${payload.report}`,
        payload
      )
        .then(response => {
          // context.commit(UPDATE_TEMPLATE_REPORT, {
          //   report: response.data.data,
          //   department: payload.department
          // });

          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [DELETE_DEPARTMENT_REPORT](context, data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/departments/report/delete/${data.report}`)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_DELETE_DEPARTMENT_REPORT, {
              report: data.report,
              department: data.department
            });
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [DELETE_DEPARTMENT](context, department) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/departments/${department.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_DEPARTMENT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_DEPARTMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/departments`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_STORE_DEPARTMENT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_DEPARTMENT](context, department) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/departments/find/${department}`)
        .then(response => {
          context.commit(SET_SHOW_DEPARTMENT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [LIST_DEPARTMENTS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/departments`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_DEPARTMENTS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [JOBS_DEPARTMENTS_USER](context, user) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/departments/jobs/user/${user}`)
        .then(response => {
          context.commit(SET_JOBS_USER, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [ASSIGN_EMPLOYEES_TO_DEPARTMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/departments/${payload.id}`, payload)
        .then(response => {
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [ASSIGN_DEPARTMENTS_TO_EMPLOYEE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/departments/update/employee/${payload.id}`,
        payload
      )
        .then(response => {
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UNASSIGN_EMPLOYEE_TO_DEPARTMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/departments/unassign/employee/${payload.id}`,
        payload
      )
        .then(response => {
          context.commit(SET_SHOW_DEPARTMENT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [REPORTS_DEPARTMENTS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/departments/reports/all`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_REPORTS_DEPARTMENTS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_DEPARTMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/departments/assign/employee/${payload.id}`,
        payload
      )
        .then(response => {
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  [DELETE_DEPARTMENT](state, department) {
    state.departments = _.dropWhile(state.departments, function(f) {
      return f.id == department.id;
    });
  },

  [SET_DELETE_DEPARTMENT_REPORT](state, data) {
    if (state.department?.id == data.department.id) {
      state.department.relationships.reportsOf = _.dropWhile(
        state.department.relationships.reportsOf,
        function(f) {
          return f.id == data.report.id;
        }
      );
    }
    _.find(state.departments, function(d) {
      if (d.id == data.department.id) {
        d.relationships.reportsOf = _.dropWhile(
          d.relationships.reportsOf,
          function(f) {
            return f.id == data.report.id;
          }
        );
        return d;
      }
    });
  },
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_DEPARTMENTS](state, departments) {
    state.departments = departments;
  },
  [SET_JOBS_USER](state, departments) {
    state.user_jobs = departments;
  },
  [SET_SHOW_DEPARTMENT](state, department) {
    state.department = department;
  },
  [SET_STORE_DEPARTMENT](state, department) {
    state.departments = [...state.departments, department];
  },
  [SET_REPORTS_DEPARTMENTS](state, departments) {
    state.reports_departments = departments;
  },
  [SET_UPDATE_DEPARTMENT](state, department) {
    state.departments = [...state.departments, department];
  }
  // [UPDATE_TEMPLATE_REPORT](state, data) {
  //   if(state.department.id==data.department ){

  //   }else{
  //     state.departments
  //   }
  // }
};

export default {
  state,
  actions,
  mutations,
  getters
};
