<template>
  <b-modal
    id="modal-delete-action"
    :title="element.title"
    @hide="handleHide"
    hide-footer
  >
    <b-alert variant="danger" v-if="errors.length">
      {{ errors }}
    </b-alert>

    <b-row>
      <b-col class="mt-4" align="center">
        <b-button-group>
          <b-button variant="secondary" @click="handleHide()">Cerrar</b-button>
          <b-button id="btn-delete" variant="danger" @click="deleteRegister">
            <b-spinner small type="grow" v-if="this.spinner"></b-spinner>
            Eliminar
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      element: "",
      spinner: false,
      errors: " "
    };
  },
  methods: {
    setData(value) {
      this.element = {
        id: value.id,
        title: `Estas seguro de eliminar ${value.name}`,
        action: value.action
      };
      this.$bvModal.show("modal-delete-action");
    },
    handleHide() {
      this.$bvModal.hide("modal-delete-action");
    },
    deleteRegister() {
      var btn = document.getElementById(`btn-delete`);
      btn.setAttribute("disabled", "true");
      this.spinner = true;
      this.errors = "";
      this.$store
        .dispatch(this.element.action, this.element.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.spinner = false;
            btn.removeAttribute("disabled");
            this.$bvModal.hide("modal-delete-action");
            if (this.element.action === `deleteTask`) {
              // Se emite un evento al componente padre, para que el padre sepa que debe cerrar el modal
              this.$emit("close-modal-show", response.data);
            }
          }
        })
        .catch(e => {
          console.error(e);
          this.spinner = false;
          btn.removeAttribute("disabled");
          this.errors = `Por favor, Intente de nuevo.`;
        });
    }
  },
  mounted() {}
};
</script>
