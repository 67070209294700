<template>
  <div>
    <div v-if="this.$route.name === `chats`" class="container-fluid">
      <b-row>
        <b-col
          v-if="showContact"
          sm="5"
          md="4"
          lg="3"
          v-b-scrollspy:contact-scroller
        >
          <WIDGETCONTACT @select-chat="selectChat" />
        </b-col>
        <b-col
          :class="widhtScreen && !showChat ? 'chat-container' : ''"
          sm="7"
          md="8"
          lg="9"
        >
          <WIDGETCHAT
            ref="messagesContent"
            :chat="chat_select"
            :me="currentUser"
            @atras="evtAtras"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import WIDGETCHAT from "@/components/chats-module/widget-chat";
import WIDGETCONTACT from "@/components/chats-module/widget-contact";
import { mapGetters } from "vuex";
import { LIST_MESSAGES } from "@/services/store/chat.module";

export default {
  components: {
    WIDGETCONTACT,
    WIDGETCHAT
  },
  data() {
    return {
      page: 3,
      chat_select: {
        attributes: {
          name: "Chat"
        },
        relationships: {
          messages: []
        }
      },
      col_list: 3,
      col_message: 6,
      col_answer: 3,
      showColAnswer: false,
      showContact: true,
      showChat: true,
      tamWindows: window.innerWidth
    };
  },
  computed: {
    ...mapGetters(["getChat", "currentUser", "getListChats"]),
    screen() {
      return this.tamWindows < 576 ? true : false;
    },
    widhtScreen() {
      return this.tamWindows < 576 ? true : false;
    }
  },
  beforeMount() {
    let chat_id = atob(this.$route.params.chat);
    if (chat_id != 1) {
      chat_id = 1;
    }

    this.selectChat(this.getListChats.filter(_c => _c.id == chat_id)[0]);
  },
  mounted() {},

  methods: {
    showAnswer(value) {
      this.showColAnswer = value;
      this.col_message = value ? 6 : 9;
    },
    selectChat(chat) {
      this.chat_select = chat;
      this.$store.commit("setChat", chat);
      this.$store.dispatch(LIST_MESSAGES, {
        chat: this.chat_select.id,
        page: 1
      });
      if (window.innerWidth < 576) {
        this.showContact = false;
      } else {
        this.showContact = true;
      }
      this.showChat = true;
      if (this.getChat.id != chat.id) {
        this.$store.commit("setChat", chat);
        this.$store.dispatch(LIST_MESSAGES, {
          chat: this.chat_select.id,
          page: 1
        });
        this.$refs.messagesContent.setPageMessage({
          page: 1,
          show: !this.showContact
        });
      }
      if ("/chats/" + btoa(this.chat_select.id) != this.$route.path) {
        this.$router.push({
          name: "chats",
          params: {
            chat: btoa(this.chat_select.id)
          }
        });
      }
      this.$socket.emit("VIEW_CHAT_USER", {
        user_id: this.currentUser.id,
        chat_id: this.getChat.id
      });
    },
    evtAtras() {
      this.showContact = true;
      this.showChat = false;
    },
    myEventChatHandler() {
      this.tamWindows = window.innerWidth;
      if (this.tamWindows > 576) {
        this.showContact = true;
        this.showChat = true;
      } else if (this.tamWindows < 576) {
        this.showChat = false;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.myEventChatHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventChatHandler);
  }
};
</script>

<style scoped>
@media only screen and (max-width: 576px) {
  .chat-container {
    display: none;
  }
}
</style>
