<template>
  <b-list-group>
    <b-list-group-item
      v-for="employee in getChat.relationships.employees"
      :key="employee.id"
      class="d-flex justify-content-between align-items-center"
    >
      {{ employee.attributes.name }}
      <small variant="info" pill>{{ employee.attributes.type }}</small>
      <template v-if="employee.id !== currentUser.id">
        <b-badge
          style="cursor: pointer"
          @click="removeEmployee(employee)"
          variant="danger"
          pill
          >Eliminar</b-badge
        >
      </template>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { DELETE_EMPLOYEE_CHAT } from "@/services/store/chat.module";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";
export default {
  props: {
    employees: Array
  },
  computed: {
    ...mapGetters(["getChat", "currentUser"])
  },
  methods: {
    removeEmployee(employee) {
      Swal.fire({
        title: "<strong>Eliminar empleado del chat</strong>",
        icon: "info",
        html: "Estas seguro de eliminar al empleado del chat",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_EMPLOYEE_CHAT, {
            employee: employee.id,
            chat: this.getChat.id
          });
        }
        Swal.close();
      });
    }
  }
};
</script>

<style></style>
