<template>
  <div>
    <b-overlay :show="show" rounded="md">
      <b-row>
        <b-col cols>
          <!-- CUANDO NO ESTA RESUELTO AUN -->
          <ul
            v-if="
              !this.resolved &&
                this.test.attributes.owner != this.currentUser.id &&
                this.$route.name != `profileEmployee`
            "
          >
            <li
              class="list-group-item"
              v-for="(element, idx) in this.questions"
              :key="idx"
            >
              <p>
                Pregunta
              </p>
              <input
                type="text"
                disabled
                class="form-control mb-4"
                :value="element.text"
              />
              <br />
              <div>
                <p>Seleccione la respuesta correcta</p>
                <b-list-group-item
                  v-for="(answer, i) in element.answers"
                  :key="i"
                  :variant="answer.correct ? `primary` : `light`"
                  @click="selectAnswer(idx, i)"
                  button
                >
                  {{ answer.text }}
                  <b-icon
                    icon="check2-circle"
                    scale="2"
                    variant="primary"
                    class="ml-6"
                    v-if="answer.correct"
                  ></b-icon>
                </b-list-group-item>
              </div>
            </li>
          </ul>
          <!-- CUANDO YA ESTA RESUELTO -->
          <ul
            v-else-if="
              (this.resolved &&
                this.test.attributes.owner === this.currentUser.id &&
                this.$route.name === `profileEmployee`) ||
                (this.resolved &&
                  this.test.attributes.owner != this.currentUser.id)
            "
          >
            <li
              class="list-group-item"
              v-for="(element, idx) in this.answered"
              :key="idx"
            >
              <b-card :bg-variant="element.correct ? `primary` : `danger`">
                <p>Pregunta</p>
                <div>
                  <strong>{{ element.question.text }}</strong>
                </div>
                <br />
                <div>
                  <p>Respuestas</p>
                  <b-list-group-item
                    v-for="(answer, i) in element.question.answers"
                    :key="i"
                  >
                    {{ answer.text }}
                    <b-icon
                      icon="check2-circle"
                      scale="2"
                      variant="primary"
                      class="ml-6"
                      v-if="answer.correct && element.correct"
                    ></b-icon>
                  </b-list-group-item>
                </div>
              </b-card>
            </li>
          </ul>
          <!-- CUANDO EL OWNER INGRESA -->
          <ul
            v-else-if="
              this.currentUser.attributes.rol === `socio` ||
                (this.currentUser.attributes.rol === `lider` &&
                  this.$route.name === `profileEmployee`) //cuando el lider ingresa al test
            "
          >
            <li
              class="list-group-item"
              v-for="(element, idx) in this.test.attributes.questions"
              :key="idx"
            >
              <b-card>
                <p>Pregunta</p>
                <div>
                  <strong>{{ element.text }}</strong>
                </div>
                <br />
                <div>
                  <p>Respuestas</p>
                  <b-list-group-item
                    v-for="(answer, i) in element.answers"
                    :key="i"
                  >
                    {{ answer.text }}
                    <b-icon
                      icon="check2-circle"
                      scale="2"
                      variant="primary"
                      class="ml-6"
                      v-if="answer.correct"
                    ></b-icon>
                  </b-list-group-item>
                </div>
              </b-card>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          align-self="end"
          v-if="
            !resolved &&
              this.$route.name != `profileEmployee` &&
              this.test.attributes.owner != this.currentUser.id
          "
        >
          <button
            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            ref="btnSubmit"
            @click="onSubmit"
          >
            Enviar
          </button>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { STORE_RESULT_TEST_USER } from "@/services/store/formation.module";
import Swal from "sweetalert2";
var _ = require("lodash");
export default {
  props: ["test"],
  data() {
    return {
      questions: [],
      answered: [],
      resolved: false,
      show: false
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getEmployeeProfile"])
  },
  methods: {
    checkUser() {
      let found = _.find(this.test.relationships.result, {
        user_id:
          this.$route.name != `profileEmployee`
            ? this.currentUser.id
            : this.getEmployeeProfile.id
      });

      if (found) {
        this.resolved = !this.resolved;
        this.$emit("listener-resolved");
        this.checkResult(found);
      }
    },
    selectAnswer(idx, i) {
      let answers = this.questions[idx].answers;
      if (answers[i].correct == true) {
        answers[i].correct = !answers[i].correct;
      } else {
        answers[i].correct = !answers[i].correct;
      }
      this.$emit("setAnswers", this.questions);
    },
    getQuestions() {
      let questions = this.test.attributes.questions;
      let questions2 = [];
      for (var idx in questions) {
        questions2.push({
          text: questions[idx].text,
          answers: questions[idx].answers.map(a => {
            return { text: a.text, correct: false };
          })
        });
      }
      this.questions = questions2;
    },
    onSubmit() {
      Swal.fire({
        title: "¿Quieres enviar las respuestas?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Responder`,
        denyButtonText: `Cancelar`
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true;
          const payload = {
            test_id: this.test.id,
            formation_id: this.test.attributes.formation_id,
            answered: this.questions
          };
          this.$store
            .dispatch(STORE_RESULT_TEST_USER, payload)
            .then(response => {
              if (response.status == 200 || response.status == 201) {
                const resp = this.checkResult(response.data.data);
                if (resp) {
                  Swal.fire("Respondido!", "", "success");
                  this.show = false;
                  this.resolved = true;
                  this.$emit("listener-resolved");
                }
              }
            });
        } else if (result.isDenied) {
          this.show = false;
          Swal.fire("Puede continuar el test", "", "info");
        }
      });
    },
    checkResult(results) {
      const original = this.test.attributes.questions ?? undefined;
      const result = results.answered ?? undefined;
      _.each(original, obj => {
        var element_answer = _.find(result, obj);
        if (typeof element_answer === "undefined") {
          this.answered.push({ question: obj, correct: false });
        } else {
          this.answered.push({ question: obj, correct: true });
        }
      });
      return true;
    }
  },
  mounted() {
    this.getQuestions();

    if (
      this.currentUser.id !== this.test.attributes.owner ||
      this.$route.name === `profileEmployee`
    ) {
      this.checkUser();
    } else {
      this.$emit("listener-resolved");
    }
  }
};
</script>
