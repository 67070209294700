import ApiService from "@/services/api.service";
const httpStatus = [200, 201];

// action types
export const LIST_ACTIVITIES = "listActivities";
export const SHOW_ACTIVITY = "showActivity";
export const LIST_ACTIVITIES_BY_USER = "listActivitiesByUser";
export const TIME_WORKDAY = "timeWorkday";
// mutation types
export const SET_ACTIVITIES = "setActivities";
export const SET_ACTIVITY = "setActivity";
export const SET_ERROR = "setError";
export const PURGE_ACTIVITIES = "purgeActivities";
export const SET_ACTIVITIES_BY_USER = "setActivitiesByUser";
export const SET_TIME_WORKDAY = "setTimeWorkday";

const state = {
  errors: null,
  activities: [],
  activity: [],
  activityByUser: [],
  timeWorkday: 480 // 8 hrs
};

const getters = {
  getActivities(state) {
    return state.activities;
  },
  getActivity(state) {
    return state.activity;
  },
  getActivityByUser(state) {
    return state.activityByUser;
  },
  getTimeWorkday(state) {
    return state.timeWorkday;
  }
};

const actions = {
  [TIME_WORKDAY](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/activities/type/workday`, {
        params: payload
      })
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_TIME_WORKDAY, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_ACTIVITIES](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/activities`, { params: payload })
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_ACTIVITIES, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_ACTIVITIES);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_ACTIVITIES_BY_USER](context, userId) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/activities/user/${userId}`)
        .then(response => {
          context.commit(SET_ACTIVITIES_BY_USER, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_ACTIVITY](context, activity) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/activities/${activity}`)
        .then(response => {
          context.commit(SET_ACTIVITY, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_TIME_WORKDAY](state, timeWorkday) {
    state.timeWorkday = timeWorkday;
  },
  [SET_ACTIVITIES](state, activities) {
    state.activities = activities;
  },
  [SET_ACTIVITIES_BY_USER](state, activities) {
    state.activityByUser = activities;
  },
  [SET_ACTIVITY](state, activity) {
    state.activity = activity;
  },
  [PURGE_ACTIVITIES](state) {
    state.activities = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
