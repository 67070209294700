import ApiService from "@/services/api.service";
const httpStatus = [200, 201];

//action
export const LIST_HOLIDAYS = "listHolidays";
export const PURGE_HOLIDAY = "purgeHoliday";
//mutation
export const SET_HOLIDAYS = "setHolidays";
export const SET_ERROR = "setError";
//getter
export const GET_HOLIDAYS = "getHolidays";

export const state = {
  errors: null,
  festivitys: [],
  festivity: ""
};

export const getters = {
  [GET_HOLIDAYS](state) {
    return state.festivitys;
  }
};

export const actions = {
  [LIST_HOLIDAYS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/festivitys`, { params: payload })
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_HOLIDAYS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_HOLIDAY);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [PURGE_HOLIDAY](context) {
    context.commit(PURGE_HOLIDAY);
  }
};

export const mutations = {
  [SET_HOLIDAYS](state, festivitys) {
    state.festivitys = festivitys;
    state.errors = {};
  },
  [PURGE_HOLIDAY](state) {
    state.festivity = [];
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
