import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_FORMATIONS = "listFormations";
export const LIST_LESSONS = "listLessons";
export const SHOW_FORMATIONS_BY_DEPARTMENT = "showFormationsByDepartment";
export const SHOW_FORMATION = "showFormation";
export const STORE_FORMATION = "storeFormation";
export const UPDATE_FORMATION = "updateFormation";
export const DELETE_FORMATION = "deleteFormation";
export const ADD_FORMATION_SEGMENT = "addFormationSegment";
export const VIEW_FORMATION_BY_USER = "viewFormationByUser";
export const SHOW_TEST = "showTest";
export const STORE_RESULT_TEST_USER = "storeResultTestUser";
export const CHANGE_STATUS_FORMATION = "changeStatusFormation";
export const PURGE_FORMATION = "purgeFormation";
export const PURGE_TEST = "purgeTest";
// mutation types
export const INITIAL_STATE_FORMATIONS = "initialStateFormations";
export const SET_FORMATIONS = "setFormations";
export const SET_FORMATION = "setFormation";
export const SET_ERROR = "setError";
export const SET_LOG = "setLog";
export const PURGE_LOG = "purgeLog";
export const SET_TEST = "setTest";
export const SET_STATUS_TEST = "setStatusTest";
export const SET_STATUS_FORMATION = "setStatusFormation";
export const SET_LESSONS = "setLessons";
export const SET_CURRENTLY_FORMATION_SELECTED = "setCurrentlyFormationSelected";
// getter types
export const GET_FORMATIONS = "getFormations";
export const GET_PAGINATION = "getPagination";
export const GET_FORMATION = "getFormation";
export const GET_LOG = "getLog";
export const GET_TEST = "getTest";
export const GET_CURRENTLY_FORMATION_SELECTED = "getCurrentlyFormationSelected";

const state = {
  errors: null,
  formations: [],
  lessons: [],
  pagination: {
    total: null,
    per_page: null,
    current_page: null,
    last_page: null
  },
  formation: [],
  log: "",
  test: {},
  currentlyFormationSelected: {}
};

const getters = {
  getFormations(state) {
    return state.formations;
  },
  getPagination(state) {
    return state.pagination;
  },
  getFormation(state) {
    return state.formation;
  },
  getTest(state) {
    return state.test;
  },
  getLog(state) {
    return state.log;
  },
  getListLessons(state) {
    return state.lessons;
  },
  getCurrentlyFormationSelected(state) {
    return state.currentlyFormationSelected;
  }
};

const actions = {
  [LIST_FORMATIONS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/formations`, { params: payload })
        .then(response => {
          context.commit(SET_FORMATIONS, response.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_LESSONS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/formations/lessons/show`)
        .then(response => {
          context.commit(SET_LESSONS, response.data);
          context.commit(SET_ERROR, null);
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_FORMATIONS_BY_DEPARTMENT](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/departments/${id}`)
        .then(response => {
          context.commit(SET_FORMATIONS, response.data);
          context.commit(SET_ERROR, null);
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_FORMATION](context, params) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/formations`, params)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_FORMATION_SEGMENT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_FORMATION](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/formations/${id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_FORMATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_TEST](context, test) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/formation/test/${test}`)
        .then(response => {
          context.commit(SET_TEST, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [VIEW_FORMATION_BY_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/formations/user`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            if (response.data.action === "enter") {
              context.commit(SET_LOG, response.data.data);
            } else {
              context.commit(PURGE_LOG);
            }
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_RESULT_TEST_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/formation/test/result`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_STATUS_TEST);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [CHANGE_STATUS_FORMATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/formation/status/${payload}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_STATUS_FORMATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_FORMATION](context, formation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/formations/${formation.id}`, formation)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_FORMATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_FORMATION](context, formation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/formations/${formation.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_FORMATION, formation);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [PURGE_FORMATION](context) {
    context.commit(PURGE_FORMATION);
    context.commit(SET_ERROR, null);
  },
  [PURGE_TEST](context) {
    context.commit(PURGE_TEST);
    context.commit(SET_ERROR, null);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [INITIAL_STATE_FORMATIONS](state) {
    (state.errors = null),
      (state.formations = []),
      (state.lessons = []),
      (state.formation = []),
      (state.log = ""),
      (state.test = {}),
      (state.currentlyFormationSelected = {});
  },
  [SET_FORMATIONS](state, formations) {
    state.formations = formations.data;
  },
  [SET_LESSONS](state, lessons) {
    state.lessons = lessons.data;
  },
  [SET_TEST](state, test) {
    state.test["questions"] = test;
  },
  [SET_STATUS_TEST](state) {
    state.test["resolved"] = true;
  },
  [SET_STATUS_FORMATION](state, formation) {
    let formations = [...state.formations];
    formations.map(f => {
      if (f.id == formation.id) {
        f.attributes.active = formation.attributes.active;
      }
    });
    state.formations = formations;
  },
  [ADD_FORMATION_SEGMENT](state, formation) {
    let formations = state.formations;
    formations.push(formation);
  },
  [SET_FORMATION](state, formation) {
    state.formation = formation;
  },
  [SET_CURRENTLY_FORMATION_SELECTED](state, formation) {
    state.currentlyFormationSelected = formation;
  },
  [SET_LOG](state, log) {
    state.log = log.id;
  },
  [PURGE_LOG](state) {
    state.log = "";
  },
  [UPDATE_FORMATION](state, formation) {
    let stateFormations = state.formations;
    for (let i = 0; i < stateFormations.length; i++) {
      if (stateFormations[i].id == formation.id) {
        stateFormations[i] = formation;
      }
    }
    state.formations = stateFormations;
  },
  [DELETE_FORMATION](state, formation) {
    state.formations = _.dropWhile(state.formations, { id: formation.id });
  },
  [PURGE_FORMATION](state) {
    state.formation = [];
  },
  [PURGE_TEST](state) {
    state.test = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
