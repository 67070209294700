var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',[[_c('div',{staticClass:"col-12"},[_c('b-card',{staticClass:"card card-custom card-stretch gutter-b font-weight-bold text-primary",attrs:{"title":"Mi Vacación"}},[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Nombre: ","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.name))])]),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Dias disponible de vacación: ","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.available_days)+" ")])]),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Fechas Solicitadas: ","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.moment(_vm.date_request_init).format("YYYY-MM-DD"))+"/"+_vm._s(_vm.moment(_vm.date_request_end).format("YYYY-MM-DD"))+" - ("+_vm._s(_vm.days_request)+") ")])]),_c('div',[(
                    this.vacation.relationships.status.attributes.name ===
                      'aprobado'
                  )?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Fechas Aprobadas:","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.date_approved_start)+"/"+_vm._s(_vm.date_approved_over)+" - ("+_vm._s(_vm.days_approved)+") ")])])],1):_vm._e(),(
                    this.vacation.relationships.status.attributes.name ===
                      'aprobado'
                  )?_c('div',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Reemplazo: ","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.replacement))])])],1):_vm._e(),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Estado: ","label-for":"initial-sort-select","label-cols-sm":"6","label-align-sm":"light","label-size":"sm","label-class":"font-weight-bold text-primary"}},[_c('label',{attrs:{"label-cols-sm":"7"}},[_vm._v(_vm._s(_vm.status))])])],1)],1),(
                this.vacation.relationships.status.attributes.name ==
                  'pendiente'
              )?_c('b-col',{staticClass:"col align-self-end",attrs:{"lg":"6","md":"4"}},[_c('b-form-group',[_c('b-button',{staticClass:"float-right ml-3",attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteRequest}},[_c('b-icon',{attrs:{"icon":"trash-fill","font-scale":"1"}}),_vm._v(" Eliminar solicitud ")],1)],1)],1):_vm._e()],1)],1)],1)],[_c('div',{staticClass:"container-fluid"},[_c('form',{staticClass:"bg-white shadow-md rounded px-8 pt-6 pb-8"},[_c('div',{staticClass:"mb-4"},[_c('DatePicker',{attrs:{"color":"red","is-success":"","is-expanded":"","from-date":new Date(_vm.date_request_init),"attributes":_vm.attrs,"value":null}})],1)])]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$emit('close-modal-show', false)}}},[_vm._v(" Cerrar ")])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }