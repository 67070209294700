<template>
  <table class="table table-borderless table-vertical-center">
    <thead>
      <tr>
        <th class="p-0" style="width: 20px"></th>
        <th class="p-0" style="min-width: 40px"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="py-5 pl-0">
          <div class="symbol symbol-50 symbol-light mr-2">
            <span class="symbol-label">
              <img
                src="media/svg/shapes/pdf.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </td>
        <td
          class="pl-0"
          :text-variant="document.attributes.active ? `white` : `black`"
        >
          <a
            @click="show(document)"
            class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
            style="cursor:pointer;"
            >{{ document.attributes.name }}</a
          >
          <span class="text-muted font-weight-bold d-block">
            {{ document.attributes.created }}
          </span>
        </td>
        <td>
          <WIDGETDROPDOWN :data="document" @listener-active="active" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETDROPDOWN from "@/components/employees-module/profiles/documents/payrolls/widget-dropdown";
import {
  SHOW_DOCUMENT,
  CHANGE_STATUS_DOCUMENT
} from "@/services/store/document.module";
export default {
  props: ["document"],
  computed: {
    ...mapGetters([
      "getBreadcrumbDoctype",
      "currentUser",
      "getDocuments",
      "getDoctype"
    ])
  },
  data() {
    return {
      for: this.document
    };
  },
  components: {
    WIDGETDROPDOWN
  },
  methods: {
    show() {
      this.$store.dispatch(SHOW_DOCUMENT, this.document.id);
      this.$emit("show-document", "selecciono");
    },
    active() {
      this.$store
        .dispatch(CHANGE_STATUS_DOCUMENT, this.document.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.for = response.data.data;
          }
        })
        .catch(e => console.error(e));
    }
  }
};
</script>
