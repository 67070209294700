import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

import formations from "./formation.module";
import departments from "./department.module";
import reports from "./report.module";
import folders from "./folder.module";
import segments from "./segment.module";
import roles from "./roles.module";

import activities from "./activity.module";
import employees from "./employee.module";
import comment from "./comment.module";
import chats from "./chat.module";
import messages from "./message.module";

import tasks from "./task.module";

import documents from "./document.module";
import doctypes from "./doctype.module";

import holidays from "./holiday.module";
import profiles from "./profile.module";
import festivitys from "./festivity.module";

import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";
// var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    formations,
    departments,
    folders,
    segments,
    activities,
    employees,
    tasks,
    comment,
    documents,
    doctypes,
    holidays,
    profiles,
    reports,
    chats,
    messages,
    festivitys,
    roles
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage })
    // createPersistedState()
    // createPersistedState({
    //   storage: {
    //     getItem: key => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: key => ls.remove(key)
    //   }
    // })
  ]
});
