<template>
  <div>
    <b-card>
      <b-card-text>
        <b-form-group
          class="pl-3 pr-3"
          id="fieldset-1"
          label="Departamentos"
          label-for="input-1"
          valid-feedback="Correcto!"
          :invalid-feedback="invalidFeedback"
          :state="state"
        >
          <multiselect
            @input="getEmployeesDepartment"
            v-model="department"
            :options="departments_all"
            :multiple="false"
            :group-select="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un(os) usuarios(s)"
            label="name"
            track-by="id"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          v-if="this.currentUser.attributes.rol_id === 1"
          class="pl-3 pr-3"
          id="fieldset-1"
          label="Lideres"
          label-for="input-1"
        >
          <multiselect
            v-model="leaders_assign"
            :options="users_leaders"
            :multiple="true"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un(os) usuarios(s)"
            label="name"
            track-by="id"
          >
          </multiselect>
        </b-form-group>
        <b-form-group
          class="pl-3 pr-3"
          id="fieldset-1"
          label="Empleados"
          label-for="input-1"
        >
          <multiselect
            v-model="employees_assign"
            :options="users_employees"
            :multiple="true"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un(os) usuarios(s)"
            label="name"
            track-by="id"
          >
          </multiselect>
        </b-form-group>
      </b-card-text>
      <template #footer>
        <b-button
          @click="updateUsersToDepartment"
          variant="primary"
          size="sm"
          class="float-right"
          ref="btnUpdateUsersToDepartment"
        >
          Actualizar
        </b-button>

        <b-button
          @click="closeModalAssing"
          variant="secondary"
          size="sm"
          class=" mr-1 ml-1 float-right"
          ref="btnUpdateUsersToDepartment"
        >
          Cerrar
        </b-button>
      </template>
    </b-card>

    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

var _ = require("lodash");
import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module";
import {
  LIST_DEPARTMENTS,
  ASSIGN_EMPLOYEES_TO_DEPARTMENT
} from "@/services/store/department.module";

import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
    this.$store.dispatch(LIST_DEPARTMENTS);
  },
  data() {
    return {
      name: "",
      employees_assign: [],
      leaders_assign: [],
      leaders_old: [],

      department: {},
      employee: {},
      departments_unassign: [],

      formUpdateDepartment: {
        id: "",
        name: "",
        employees: [],
        leaders_old: []
      },
      formUpdateEmployee: {
        id: "",
        departments: []
      }
    };
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "getDepartments", "currentUser"]),
    state() {
      return this.name.length >= 4;
    },
    invalidFeedback() {
      if (this.name.length > 4) {
        return "Ingrese al menos 4 caracteres.";
      }
      return "";
    },
    users_leaders() {
      let list = _.filter(this.getEmployeesAll, function(u) {
        return u.attributes.role_id == 2;
      });

      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name, role: d.role };
      });
    },
    users_employees() {
      var list = _.filter(this.getEmployeesAll, function(u) {
        return u.attributes.role_id == 3 || u.attributes.role_id == 5;
      });

      return _.map(list, d => {
        return { id: d.id, name: d.attributes.name, role: d.role };
      });
    },
    departments_all() {
      return _.map(this.getDepartments, d => {
        return {
          id: d.id,
          name: d.attributes.name,
          employees: _.map(d.relationships.employees, e => {
            return {
              id: e.id,
              name: e.attributes.name,
              role: e.attributes.role_id
            };
          })
        };
      });
    }
  },
  //cambiar en dc en bloglong
  methods: {
    getEmployeesDepartment(department) {
      this.leaders_assign = [];
      this.employees_assign = [];
      department.employees.forEach(e => {
        if (e.role === 2) {
          this.leaders_assign.push(e);
          this.leaders_old.push(e);
        } else if (e.role === 3 || e.role === 5) {
          this.employees_assign.push(e);
        }
      });
    },
    updateUsersToDepartment() {
      const AssignDepButton = this.$refs["btnUpdateUsersToDepartment"];
      AssignDepButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      this.formUpdateDepartment.id = this.department.id;
      this.formUpdateDepartment.name = this.department.name;

      this.formUpdateDepartment.employees = _.concat(
        this.mapId(this.employees_assign),
        this.mapId(this.leaders_assign)
      );
      this.formUpdateDepartment.leaders_old = _.difference(
        this.mapId(this.leaders_old),
        this.mapId(this.leaders_assign)
      );

      this.$store
        .dispatch(ASSIGN_EMPLOYEES_TO_DEPARTMENT, this.formUpdateDepartment)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$store.dispatch(LIST_DEPARTMENTS);
            this.department = {};
            this.employees_assign = [];
            this.leaders_assign = [];
            AssignDepButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Departamento!", "Actualizado exitosamente", "success");
            this.$emit("close-modal-update", false);
          }
        })
        .catch(e => {
          this.errors = e;
          AssignDepButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("Error!!", "Por favor! intente de nuevo", "error");
        });
    },
    closeModalAssing() {
      this.$emit("close-modal-update", false);
    },
    mapId(list) {
      return _.map(list, d => {
        return d.id;
      });
    }
  }
};
</script>
