<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>
<style lang="scss">
@import "@/assets/css/video-js";
</style>
<script>
import videojs from "video.js";

export default {
  name: "VIDEO",
  props: ["data"],
  data() {
    return {
      player: null,
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: this.data.relationships.file?.public_url,
            type: this.data.relationships.file?.type
          }
        ]
      }
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
