<template>
  <b-modal
    id="modal-attachment"
    :title="file.name"
    @hide="handleHide"
    size="xl"
  >
    <b-row v-if="this.file">
      <b-col align="center" v-if="this.file">
        <PDF :url="file.url" v-if="file.type === `application/pdf`" />
        <img
          :src="file.url"
          :alt="file.name"
          width="80%"
          height="400px"
          v-else-if="imageMIME"
        />
        <div v-else-if="videoMIME">
          <div>
            <VIDEO :data="{ attributes: { url: file.url, type: file.type } }" />
          </div>
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="handleHide"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import PDF from "@/components/formations-module/pdf/pdf";
import VIDEO from "@/components/formations-module/formations/widget-video";
import videojs from "video.js";
export default {
  components: {
    PDF,
    VIDEO
  },
  data() {
    return {
      file: ""
    };
  },
  computed: {
    imageMIME() {
      let arrayMIME = ["image/png", "image/jpg", "image/jpeg"];
      return arrayMIME.indexOf(this.file.type) != -1;
    },
    videoMIME() {
      let arrayMIME = [
        "video/x-msvideo",
        "video/mp4",
        "video/webm",
        "video/ogg",
        "video/x-matroska",
        "video/quicktime"
      ];
      return arrayMIME.indexOf(this.file.type) != -1;
    }
  },
  methods: {
    view(file) {
      this.$bvModal.show("modal-attachment");
      this.file = file;
    },
    handleHide() {
      this.$bvModal.hide("modal-attachment");
      this.file = {};
    }
  },
  mounted() {
    if (this.file.type === `video/mp4`) {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {}
      );
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
