<template>
  <div>
    <WIDWETREPORTDEPARTMENT />
  </div>
</template>

<script>
// import WIDWETREPORTDEPARTMENT from "";
import WIDWETREPORTDEPARTMENT from "@/components/departments-module/widget-report-department";

export default {
  components: {
    WIDWETREPORTDEPARTMENT
  }
};
</script>
