<template>
  <div class="col-12">
    <b-card
      title="lista de empleados"
      class="card card-custom card-stretch gutter-b"
      v-if="
        this.currentUser.attributes.rol_id === 1 ||
          this.currentUser.attributes.rol_id === 2
      "
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- ninguno --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <!-- Main table element -->
      <b-row>
        <b-col lg="12" class="my-1">
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(high_low)="row">
              <b-badge :variant="row.value ? 'success' : 'danger'">{{
                row.value ? "Alta" : "Baja"
              }}</b-badge>
            </template>

            <template
              #cell(actions)="row"
              v-if="this.currentUser.attributes.rol_id !== 3 && havePermission"
            >
              <b-badge
                href="#"
                @click="editAvailableDays(row.item)"
                variant="success"
                class="mb-2"
              >
                <b-icon
                  aria-hidden="true"
                  icon="pencil-fill"
                  title="Ver perfil de empleado"
                >
                </b-icon>
                Editar
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="showModalUser"
      id="modal-add-show"
      size="sm"
      hide-footer
      :title="`Editar Días de ${form.name}`"
    >
      <form ref="form">
        <b-form-group
          label="Días disponibles de vacación"
          label-for="name-input"
        >
          <b-form-input
            v-model="form.days_available"
            id="name-input"
          ></b-form-input>
        </b-form-group>
        <b-button
          variant="primary"
          size="sm"
          class="float-right ml-5"
          ref="btnSubmit"
          @click="editDayAvailable"
        >
          guardar
        </b-button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");

// ==========sweetalert2===============
const Swal = require("sweetalert2");
import {
  LIST_PROFILES
  //EMPLOYEE_PROFILE
} from "@/services/store/profile.module.js";
import { UPDATE_USER_DAYS } from "@/services/store/holiday.module";

export default {
  components: {},
  comments: {
    Swal
  },
  props: ["user", "isr"],
  data() {
    return {
      form: {
        id_profile: "",
        days_available: "",
        name: ""
      },
      // sortDirection:
      showModalUser: false,
      fields: [
        {
          key: "index",
          sortable: true,
          label: "#"
          // sortDirection: "desc"
        },

        {
          key: "name",
          sortable: true,
          label: "Nombre",
          sortDirection: "desc"
        },
        {
          key: "request",
          label: "Días disponibles de vacación",
          sortable: true,
          class: "text-center"
        },
        {
          key: "actions",
          label: this.user.attributes.rol_id == 2 && this.isr ? "Acción" : ""
        }
      ],
      //totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: "Ver todo" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getProfiles",
      "getEmployeesAll",
      "getListEmployees",
      "listAllEmployees",
      "getProfile"
    ]),
    havePermission() {
      return this.currentUser.attributes.permission_add ? true : false;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(this.getProfiles, ["id"], ["desc"]);
      for (var idx in employees) {
        items.push({
          index: parseInt(idx) + 1,
          id: employees[idx].id,
          name: employees[idx].attributes.name,
          request: employees[idx].attributes.available_days_vacations,
          jobs: employees[idx].relationships.jobs
        });
      }
      return items;
    },
    totalRows() {
      return this.items.length;
    }
  },
  mounted() {},
  beforeMount() {
    this.$store.dispatch(LIST_PROFILES);
  },
  methods: {
    editDayAvailable() {
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(UPDATE_USER_DAYS, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.$store.dispatch(LIST_PROFILES);

            Swal.fire(
              "Ok!",
              "La función fue registrada exitosamente",
              "success"
            );
            this.showModalUser = false;
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    },
    editAvailableDays(day_available_vacation) {
      this.form.id_profile = day_available_vacation.id;
      this.form.days_available = day_available_vacation.request;
      this.form.name = day_available_vacation.name;
      this.showModalUser = true;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
