// import ApiService from "@/services/api.service";
// var _ = require("lodash");

// action types
export const SHOW_MESSAGE = "showMessage";
export const STORE_MESSAGE = "storeMessage";

export const STORE_FORMATION_BY_MESSAGE = "storeMessageByMessage";
export const UPDATE_FORMATION_BY_MESSAGE = "updateMessageByMessage";

export const SELECT_MESSAGE = "selectMessage";

export const DELETE_MESSAGE_CHILDREN = "deleteMessageChildren";

// mutation types
export const SET_MESSAGES = "setMessages";
export const SET_MESSAGE = "setMessage";
export const SET_MESSAGES_CHILDREN = "setMessagesChildren";

export const SET_ERROR = "setError";
export const ADD_MESSAGE = "addMessage";
export const ADD_MESSAGE_CHILDREN = "addMessageChildren";

// getter types
export const GET_MESSAGES = "getMessages";
export const GET_MESSAGE = "getMessage";
export const GET_MESSAGES_CHILDREN = "getMessageChildren";

const state = {
  errors: null,
  messages: [],
  messages_children: [],

  message: {}
};

const getters = {
  [GET_MESSAGES](state) {
    return state.messages;
  },
  [GET_MESSAGE](state) {
    return state.message;
  },
  [GET_MESSAGES_CHILDREN](state) {
    return state.messages_children;
  }
};

const actions = {};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_MESSAGES](state, messages) {
    state.messages = messages;
  },
  [SET_MESSAGES_CHILDREN](state, contents) {
    state.messages = contents;
  },

  [SET_MESSAGE](state, message) {
    state.message = message;
  },

  [ADD_MESSAGE](state, message) {
    state.messages = [...state.messages, message];
  },
  [ADD_MESSAGE_CHILDREN](state, message) {
    state.messages_children = [...state.messages_children, message];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
