<template>
  <div>
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
          <div class="col-xl-12 col-xxl-12">
            <div
              class="pb-2"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h5 class="mb-4 font-weight-bold text-success">
                Información del Reporte
              </h5>
              <hr />
              <p><b>Nombre Excel:</b> {{ form.name }}</p>
              <p><b>llenado por:</b> {{ form.created_by }}</p>
              <b-row class="justify-content-md-center mb-4">
                <b-col cols lg="12" md="12" sm="12" xs="12">
                  <b-button
                    variant="success"
                    size="sm"
                    class="float-light ml-3"
                    @click="activeEditReport"
                    ><b-icon icon="pencil-fill" font-scale="1"></b-icon>
                    Editar
                  </b-button>
                  <b-button
                    class="float-right ml-3"
                    variant="danger"
                    size="sm"
                    ref="btnSubmit123"
                    @click="deleteReport"
                  >
                    <b-icon icon="trash-fill" font-scale="1"></b-icon>
                    Eliminar
                  </b-button>
                </b-col>
              </b-row>
              <VUEEXCELEDITOR
                :editReport="vue.status"
                :editTemplate="vue.status"
                :exportFile="true"
                :dataTable="{ rows: tableData, columns: tableHeader }"
                @change-data="changeData"
                :name="form.name"
                :key="componentVueExcelEditorKey"
              />
              <div class="text-right mt-3">
                <b-button
                  v-if="vue.status"
                  ref="btnSubmit"
                  variant="success"
                  size="sm"
                  @click="updateReport"
                >
                  Actualizar
                </b-button>
                <b-button
                  class="ml-3"
                  variant="secondary"
                  size="sm"
                  @click="closeModal()"
                >
                  Cerrar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VUEEXCELEDITOR from "@/components/departments-module/reports/vue-excel-editor";

import {
  UPDATE_INSERT_REPORT,
  DELETE_REPORT
} from "@/services/store/report.module";
import {
  SHOW_DEPARTMENT,
  REPORTS_DEPARTMENTS
} from "@/services/store/department.module";
import Swal from "sweetalert2";
export default {
  components: {
    VUEEXCELEDITOR
  },
  computed: {
    ...mapGetters([
      "getReportsDepartments",
      "getDepartments",
      "getShowDepartment"
    ])
  },
  props: ["report"],
  created() {
    this.tableHeader = JSON.parse(this.form.columns);
    this.tableData = JSON.parse(this.form.data);
  },
  data() {
    return {
      componentVueExcelEditorKey: 0,
      tableHeader: [],
      tableData: [],
      editTablaShow: true,
      editHeaderShow: true,
      readonly: true,
      vue: {
        status: false
      },

      form: {
        id: this.report.id,
        report: this.report.id,
        // department: this.report.attributes.pivot.department_id,
        name: this.report.attributes.name,
        // description: this.report.attributes.description,
        created_by: this.report.relationships.created_by.attributes.name,

        // name_info: this.report.attributes.pivot.name,
        data: this.report.attributes.data,
        columns: this.report.attributes.columns,
        url: ""
      },

      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {}
  },

  methods: {
    closeModal() {
      if (this.vue.status) {
        Swal.fire({
          title: "Estas seguro de cerrar el modal?",
          text: `No se guardara la informacion, guarde los cambios antes de cerrar`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, Cerrar",
          cancelButtonText: "Atras"
        }).then(result => {
          if (result.isConfirmed) {
            this.$emit("close-modal-show", false);
          }
        });
      } else {
        this.$emit("close-modal-show", false);
      }
    },
    changeData(data) {
      this.tableHeader = data.columns;
      this.tableData = data.rows;
      this.componentVueExcelEditorKey += 1;
    },

    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    updateReport() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let colName = this.tableHeader;
      // this.$refs.excelEditorShow.fields.forEach(element => {
      //   colName.push(element.label);
      // });
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.form.type = "xlsx";
      this.form.data = this.tableData;
      this.form.columns = colName;
      this.$store
        .dispatch(UPDATE_INSERT_REPORT, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se actualizo el reporte exitosamente", "success");
            this.$store.dispatch(SHOW_DEPARTMENT, this.getShowDepartment.id);
            this.$store.dispatch(REPORTS_DEPARTMENTS);
            this.$emit("close-modal-show", false);
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    },
    deleteReport() {
      var text;
      var msg;
      text = "¿Quieres eliminar el reporte?";
      msg = "El reporte fue eliminado";
      Swal.fire({
        title: "Estas seguro?",
        text: `${text}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_REPORT, this.form).then(response => {
            if (response.status == 200) {
              Swal.fire("Eliminado!", msg, "success");
              this.$store.dispatch(SHOW_DEPARTMENT, this.getShowDepartment.id);
              this.$store.dispatch(REPORTS_DEPARTMENTS);
              this.$emit("close-modal-show", false);
            }
          });
        }
      });
    },
    exportAsExcel() {
      const format = "xlsx";
      const exportSelectedOnly = false;
      const filename = `${this.form.name} `;
      this.$refs.grid.exportTable(format, exportSelectedOnly, filename);
    },
    activeEditReport() {
      this.editTablaShow = null;
      this.editHeaderShow = null;
      this.vue.status = !this.vue.status;
    }
  }
};
</script>
