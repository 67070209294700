<template>
  <b-row>
    <b-col cols class="ml-auto" md="10">
      <b-card
        class="card-custom card-stretch options"
        @click="showModalActivity"
        style='cursor:pointer;background-position: right top; background-size: 10%; background-image: url("media/svg/shapes/activities.svg");'
        v-show="permissionsActivities"
      >
        Actividades
      </b-card>
    </b-col>

    <b-col cols class="ml-auto" md="10">
      <b-card
        class="card-custom card-stretch options"
        @click="showTask"
        style='cursor:pointer;background-position: right top; background-size: 10%; background-image: url("media/svg/shapes/tasks.svg");'
        v-show="permissionsTasks"
      >
        Tareas
      </b-card>
    </b-col>

    <b-col cols class="ml-auto" md="10">
      <b-card
        class="card-custom card-stretch options"
        @click="showModalFormation"
        style='cursor:pointer;background-position: right top; background-size: 10%; background-image: url("media/svg/shapes/formations.svg");'
        v-show="permissionsFormations"
      >
        Formaciones
      </b-card>
    </b-col>
    <!--==========================MODAL DE ACTIVIDADES==============================================-->
    <b-modal
      id="modal-profile-activity"
      size="lg"
      title="Actividad de empleado"
      scrollable
    >
      <div class="d-block text-center">
        <WIDGETACTIVITY />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide(`modal-profile-activity`)"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- ============================FIN DE MODAL ACTIVIDADES ======================================= -->
    <!--=============================MODAL DE TAREAS ================================= --->
    <b-modal id="modal-profile-task" size="xl" title="Tareas" scrollable>
      <div class="d-block">
        <WIDGETTASK />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide(`modal-profile-task`)"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- ============================FIN DE MODAL TAREAS ======================================= -->
    <!--=============================PRIMER MODAL DE FORMACIONES ================================= --->
    <b-modal
      id="modal-profile-formation"
      :size="folders ? `xl` : `md`"
      title="Formaciones"
      ok-only
      scrollable
    >
      <div class="d-block text-center" v-if="folders">
        <b-row>
          <b-col sm="4" md="4" class="my-1">
            <b-col lg="12" class="my-1">
              <b-form-group
                label="Filtrar"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-4"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Borrar</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="mb-4"
              ></b-pagination>
            </b-col>

            <hr />
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              select-mode="single"
              responsive="sm"
              ref="selectableTable"
              :filter="filter"
              :filter-included-fields="filterOn"
              selectable
              @row-selected="onRowSelected"
            >
              <template #cell(name)="data">
                {{ data.item.name }}
              </template>
            </b-table>
          </b-col>
          <b-col cols md="8" v-if="showColumn">
            <b-overlay :show="overlay" rounded="md">
              <b-card no-body>
                <b-tabs card>
                  <b-tab title="Textos" active>
                    <b-card-text class="text-center">
                      <b-row v-if="textos.length == 0">
                        <b-col cols md="12">
                          <h5>
                            <img src="media/svg/misc/empty.svg" alt="" />
                            <p class="mt-6">
                              La formación no tiene textos
                            </p>
                          </h5>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col
                          cols
                          md="auto"
                          v-for="(texto, idx) in textos"
                          :key="idx"
                        >
                          <LISTFORMATION
                            :formation="texto"
                            @show-formation="showFormation"
                          />
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Videos">
                    <b-card-text class="text-center">
                      <b-row v-if="videos.length == 0">
                        <b-col cols md="12">
                          <h5>
                            <img src="media/svg/misc/empty.svg" alt="" />
                            <p class="mt-6">
                              La formación no tiene videos
                            </p>
                          </h5>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col
                          cols
                          md="auto"
                          v-for="(video, idx) in videos"
                          :key="idx"
                        >
                          <LISTFORMATION
                            :formation="video"
                            @show-formation="showFormation"
                          />
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Test">
                    <b-card-text class="text-center">
                      <b-row v-if="tests.length == 0">
                        <b-col cols md="12">
                          <h5>
                            <img src="media/svg/misc/empty.svg" alt="" />
                            <p class="mt-6">
                              La formación no tiene test
                            </p>
                          </h5>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col
                          cols
                          md="auto"
                          v-for="(test, idx) in tests"
                          :key="idx"
                        >
                          <LISTFORMATION
                            :formation="test"
                            @show-formation="showFormation"
                          />
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-overlay>
          </b-col>
          <b-col v-else>
            <b-card no-body>
              <b-card-text class="text-center">
                <h5 v-if="tests.length == 0">
                  <img src="media/svg/misc/empty.svg" />
                  <p class="mt-6">Seleccione una formación</p>
                </h5>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div v-else class="text-center">
        <img src="media/svg/misc/empty.svg" alt="" />
        <p class="mt-6">No tienes formaciones asignadas</p>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide(`modal-profile-formation`)"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- ============================FIN PRIMER MODAL DE FORMACIONES ======================================= -->
    <!--=============================MODAL DE FORMACIONES (VER LA FORMACION SELECCIONADA) ================================= --->
    <b-modal
      id="modal-formation-show"
      scrollable
      :title="formation.length ? formation.attributes.name : ''"
      size="xl"
      ok-only
    >
      <SHOWFORMATIONPROFILE :data="formation" />

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide(`modal-formation-show`)"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- ============================FIN MODAL DE FORMACIONES(VER LA FORMACION SELECCIONADA) ======================================= -->
  </b-row>
</template>
<style lang="scss">
.card-custom.card-stretch.options:hover {
  background: #d4d4d4;
  color: whitesmoke;
}
</style>

<script>
import WIDGETACTIVITY from "@/components/employees-module/profiles/widget-activity";
import SHOWFORMATIONPROFILE from "@/components/employees-module/profiles/formations/show-formation";
import LISTFORMATION from "@/components/employees-module/profiles/formations/list-formation";
import { EMPLOYEE_PROFILE_ACTIVITY } from "@/services/store/employee.module.js";
import WIDGETTASK from "@/components/tasks-module/widget-task";
import { mapGetters } from "vuex";
var _ = require("lodash");

export default {
  props: ["profile"],
  components: {
    WIDGETACTIVITY,
    SHOWFORMATIONPROFILE,
    WIDGETTASK,
    LISTFORMATION
  },
  data() {
    return {
      rolesActivities: [1, 2],
      rolesTasks: [1, 2, 3],
      rolesFormations: [1, 2],
      codesStatus: [200, 201],
      fields: [
        {
          key: "name",
          label: "Formación",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 6,
      filter: null,
      filterOn: [],
      overlay: false,
      showColumn: false,
      formations: [],
      formation: {}
    };
  },

  computed: {
    ...mapGetters(["currentUser", "getSegments", "getListFormationsProfile"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    folders() {
      let target = _(this.getListFormationsProfile)
        .groupBy("relationships.folder.attributes.name")
        .value();
      return target;
    },
    items() {
      let arr = [];
      Object.entries(this.folders).forEach(([key, value]) => {
        arr.push({
          name: key,
          value: value
        });
      });
      return arr;
    },
    textos() {
      let arr = [];
      if (this.formations) {
        arr = _.filter(this.formations, f => {
          return f.attributes.type === `doc`;
        });
      }
      return arr;
    },
    videos() {
      let arr = [];
      if (this.formations) {
        arr = _.filter(this.formations, f => {
          return f.attributes.type === `video`;
        });
      }
      return arr;
    },
    tests() {
      let arr = [];
      if (this.formations) {
        arr = _.filter(this.formations, f => {
          return f.attributes.type === `test`;
        });
      }
      return arr;
    },
    permissionsActivities() {
      return (
        this.rolesActivities.indexOf(this.currentUser.attributes.rol_id) != -1
      );
    },
    permissionsTasks() {
      return this.rolesTasks.indexOf(this.currentUser.attributes.rol_id) != -1;
    },
    permissionsFormations() {
      return (
        this.rolesFormations.indexOf(this.currentUser.attributes.rol_id) != -1
      );
    }
  },
  methods: {
    handleHide(modalId) {
      this.$bvModal.hide(modalId);
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.formations = items[0].value;
        this.showColumn = true;
      }
    },
    showModalActivity() {
      this.$store
        .dispatch(EMPLOYEE_PROFILE_ACTIVITY, this.profile.id)
        .then(response => {
          if (this.codesStatus.indexOf(response.status) != -1) {
            this.$bvModal.show("modal-profile-activity");
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    showModalTask() {
      this.$bvModal.show("modal-profile-task");
    },
    async showModalFormation() {
      await this.$store.dispatch("listFormationsProfile", {
        employee: this.profile.id,
        role_id: this.profile.attributes.role_id
      });
      this.$bvModal.show("modal-profile-formation");
    },
    showFormation(formation) {
      this.formation = formation;
      this.$bvModal.show("modal-formation-show");
    },
    showTask() {
      const payload = {
        userId: this.profile.id
      };
      this.$store
        .dispatch("listTasksUser", payload)
        .then(response => {
          if (this.codesStatus.indexOf(response.status) != -1) {
            this.showModalTask();
          }
        })
        .catch(e => console.error({ e }));
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  }
};
</script>
