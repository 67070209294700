var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.doctype.attributes.name === 'valoraciones' &&
      this.currentUser.attributes.rol_id === 1) ||
      (_vm.doctype.attributes.name === 'valoraciones' &&
        this.currentUser.attributes.rol_id === 2) ||
      (_vm.doctype.attributes.name === 'contratos' &&
        this.currentUser.attributes.rol_id === 1) ||
      (_vm.doctype.attributes.name === 'contratos' &&
        this.currentUser.id == this.getEmployeeProfile.id) ||
      (_vm.doctype.attributes.name === 'circulares' &&
        this.currentUser.attributes.rol_id === 1) ||
      (_vm.doctype.attributes.name === 'circulares' &&
        this.currentUser.attributes.rol_id === 2) ||
      (_vm.doctype.attributes.name === 'circulares' &&
        this.currentUser.attributes.rol_id === 3) ||
      (_vm.doctype.attributes.name === 'nominas' &&
        this.currentUser.attributes.rol_id === 3) ||
      (_vm.doctype.attributes.name === 'nominas' &&
        this.currentUser.attributes.rol_id === 2) ||
      (_vm.doctype.attributes.name === 'nominas' &&
        this.currentUser.attributes.rol_id === 1) ||
      (_vm.doctype.attributes.name === 'circulares' &&
        this.currentUser.attributes.rol_id === 2)
  )?_c('b-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"","md":"3","sm":"12"},on:{"click":_vm.show}},[_c('b-card',{staticClass:"card-custom card-stretch gutter-b",staticStyle:{"cursor":"pointer","background-position":"right","background-size":"15%","background-image":"url(\"media/svg/shapes/folder2.svg\")"},on:{"click":function($event){return _vm.show()}}},[_c('a',{staticClass:"font-weight-normal text-primary text-hover-primary font-size-lg mb-1",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.doctype.attributes.name)+" ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }