import ApiService from "@/services/api.service";

// action types
export const LIST_DOCTYPES = "listDoctypes";
export const SHOW_DOCTYPE = "showDoctype";
export const STORE_DOCTYPE = "storeDoctype";
export const UPDATE_DOCTYPE = "updateDoctype";
export const DELETE_DOCTYPE = "deleteDoctype";
export const ADD_DOCTYPE = "addDoctype";
export const SET_DOCTYPE = "setDoctype";
export const SELECT_DOCTYPE = "selectDoctype";

// mutation types
export const SET_DOCTYPES = "setDoctypes";
export const SET_ERROR = "setError";

// getter types
export const GET_DOCTYPES = "getDoctypes";
export const GET_DOCTYPE = "getDoctype";

const state = {
  errors: null,
  doctypes: [],
  doctype: {}
};

const getters = {
  [GET_DOCTYPES](state) {
    return state.doctypes;
  },
  [GET_DOCTYPE](state) {
    return state.doctype;
  }
};

const actions = {
  [LIST_DOCTYPES](context) {
    ApiService.setHeader();
    ApiService.get(`api/v1/doctypes`)
      .then(response => {
        context.commit(SET_DOCTYPES, response.data.data);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ERROR, error.response.data.errors);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  },
  [STORE_DOCTYPE](context, document) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/doctypes`, document)
        .then(response => {
          context.commit(ADD_DOCTYPE, response.data.data);
          resolve(true);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [SELECT_DOCTYPE](context, doctype) {
    context.commit(SET_DOCTYPE, doctype);
  }
};

const mutations = {
  [SET_DOCTYPE](state, doctype) {
    state.doctype = doctype;
    state.errors = {};
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_DOCTYPES](state, doctypes) {
    state.doctypes = doctypes;
    state.errors = {};
  },
  [ADD_DOCTYPE](state, doctype) {
    let doctypes = [...state.doctypes];
    doctypes.unshift(doctype);
    state.doctypes = doctypes;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
