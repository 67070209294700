<template>
  <b-container>
    <b-row>
      <WIDGETMODALCREATE />
    </b-row>

    <b-row v-if="getDocumentsFolderNull.length > 0">
      <b-col
        cols
        md="4"
        v-for="(element, idx) in getDocumentsFolderNull"
        :key="idx"
      >
        <WIDGETPAYROLL :document="element" @show-document="redirectDocument" />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <img src="media/svg/misc/empty.svg" alt="" />
        <p class="mt-6">No tiene ningún documento para mostrar</p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import WIDGETPAYROLL from "@/components/employees-module/profiles/documents/payrolls/widget-payroll";
import WIDGETMODALCREATE from "@/components/employees-module/profiles/documents/widget-modal-create";

var _ = require("lodash");
export default {
  data() {
    return {
      show: false
    };
  },
  components: {
    WIDGETMODALCREATE,
    WIDGETPAYROLL
  },
  computed: {
    ...mapGetters(["currentUser", "getDocuments", "getEmployeeProfile"]),

    getDocumentsFolderNull() {
      return _.filter(this.getDocuments, function(f) {
        return f.relationships.employees.length > 0;
      });
    }
  },
  methods: {
    redirectDocument() {
      this.$emit("show-document-two", "selecciono 2");
    }
  },
  mounted() {}
};
</script>
