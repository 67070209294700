<template>
  <div>
    <b-row>
      <b-col cols="auto">
        <ul class="menu-nav">
          <div class="container" v-if="this.$route.name === `showTasksUser`">
            <b-breadcrumb :items="crumbs" disabled="true" />
          </div>
          <div class="container" v-else>
            <b-breadcrumb :items="crumbs" disabled="true" />
          </div>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  computed: {
    routeNotViewBreadcrumbs() {
      return ["showTasksUser"];
    },
    crumbs() {
      if (this.routeNotViewBreadcrumbs.indexOf(this.$route.name) < 0) {
        let pathArray = this.$route.path.split("/");
        pathArray.shift();
        let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
          path = decodeURIComponent(path);
          breadcrumbArray.push({
            path: decodeURIComponent(path),
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: path.toUpperCase()
          });
          return breadcrumbArray;
        }, []);
        return breadcrumbs;
      } else {
        return ["TAREAS"];
      }
    }
  }
};
</script>
