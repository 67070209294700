<template>
  <div>
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
          <div class="col-xl-12 col-xxl-12">
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h4 class="mb-4 font-weight-bold text-dark">
                Información de la plantilla Reporte
              </h4>

              <b-row>
                <b-col lg="6" md="12" sm="12" xs="12">
                  <b-form-group
                    id="input-group-1"
                    label="Titulo del documento"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="$v.form.name.$model"
                      :state="validateState('name')"
                      type="text"
                      required
                      placeholder="Titulo del documento"
                    />
                    <b-form-invalid-feedback id="input-1-live-feedback">
                      El nombre es requerido
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Descripción del Documento"
                    label-for="input-2"
                  >
                    <b-form-textarea
                      id="textarea-no-resize"
                      placeholder="Descripción"
                      rows="3"
                      no-resize
                      v-model="form.description"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="5" md="12" sm="12" xs="12">
                  <label for="">Subir archivo Excel</label>
                  <UPLOADEXCEL
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                  />
                </b-col>
              </b-row>
              <VUEEXCELEDITOR
                v-if="this.tableHeader.length > 0"
                :editReport="editReport"
                :dataTable="{ rows: this.tableData, columns: this.tableHeader }"
                @change-data="changeData"
                :key="componentVueExcelEditorKey"
              />
              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>
              <div class="text-right mt-3">
                <b-button
                  ref="btnSubmit"
                  @click="saveReport"
                  variant="primary"
                  size="sm"
                >
                  Añadir
                </b-button>
                <b-button
                  class="ml-3"
                  variant="secondary"
                  size="sm"
                  @click="$emit('close-modal-add', false)"
                >
                  Cerrar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { STORE_REPORT } from "@/services/store/report.module";
import VUEEXCELEDITOR from "@/components/departments-module/reports/vue-excel-editor";

import {
  SHOW_DEPARTMENT,
  REPORTS_DEPARTMENTS
} from "@/services/store/department.module";

import UPLOADEXCEL from "@/components/departments-module/reports/excel";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  computed: {
    ...mapGetters(["getShowDepartment"]),
    colTable() {
      return this.tableHeader;
    }
  },
  mixins: [validationMixin],
  props: ["action", "department"],
  components: {
    UPLOADEXCEL,
    VUEEXCELEDITOR
  },
  data() {
    return {
      prueba: [],
      componentVueExcelEditorKey: 0,
      col_name: "",
      select_row: [],
      editReport: true,
      tableData: [],
      tableHeader: [],
      form: {
        id: this.action === `edit` ? "this.department.id" : "",
        department: this.action === `edit` ? "this.department.id" : "",
        name: this.action === `edit` ? "this.document.attributes.name" : "",
        description:
          this.action === `edit` ? "this.document.attributes.description " : "",
        data: this.action === `edit` ? "this.document.attributes.data " : "",
        type: this.action === `edit` ? "this.document.attributes.type" : "",
        columns: this.action === `edit` ? "this.document.attributes.type" : ""
      },
      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  methods: {
    changeData(data) {
      this.tableHeader = data.columns;
      this.tableData = data.rows;
      this.componentVueExcelEditorKey += 1;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning"
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.tableData = results;
      let index = 1;
      this.tableData.forEach(element => {
        element.indice = index;
        index++;
      });

      this.tableHeader = header;
      this.tableHeader.unshift("indice");
      this.editReport = true;
    },
    saveReport() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.form.name = this.$v.form.name.$model;
      this.form.type = "xlsx";
      this.form.department = this.department.id; //de alguna manera deberia de tener el id del departamento no pude conseguir
      this.form.data = this.tableData;
      console.log("Al guardar", this.tableData);

      this.form.columns = this.tableHeader;
      this.$store
        .dispatch(STORE_REPORT, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se creo el reporte exitosamente", "success");
            this.$store.dispatch(SHOW_DEPARTMENT, this.getShowDepartment.id);
            this.$store.dispatch(REPORTS_DEPARTMENTS);
            this.$emit("close-modal-add", false);
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    }
  }
};
</script>
