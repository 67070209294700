<template>
  <KTQuickUser v-if="!isAdmin"></KTQuickUser>
  <KTQuickUserAdmin v-else-if="isAdmin"></KTQuickUserAdmin>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTQuickUser from "@/components/header/quickUser.vue";
import KTQuickUserAdmin from "@/components/header/quickUserAdmin.vue";

export default {
  name: "KTTopbar",
  data() {
    return {};
  },
  components: {
    KTQuickUser,
    KTQuickUserAdmin
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser", "getVacation"]),
    isAdmin() {
      return this.currentUser.attributes.rol_id == 4;
    }
  },
  methods: {}
};
</script>
