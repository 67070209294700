<template>
  <b-col>
    <b-row>
      <b-col cols md="12" sm="12" xs="12">
        <b-row>
          <WIDGETDOCTYPE
            :doctype="element"
            v-for="(element, idx) in this.getDoctypes"
            :key="idx"
            @show-doctype="redirect"
          />
          <b-modal
            id="modal-doctype"
            scrollable
            hide-footer
            title="Documentos"
            size="lg"
            ok-only
          >
            <WIDGETDOCTYPESHOW @show-document-two="showModalDocument" />
          </b-modal>

          <b-modal
            id="modal-doc-show"
            scrollable
            hide-footer
            title="Documento"
            size="xl"
            ok-only
          >
            <SHOWDOCUMENT :data="getDocument" />
          </b-modal>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETDOCTYPE from "@/components/employees-module/profiles/documents/widget-doctype";
import WIDGETDOCTYPESHOW from "@/components/employees-module/profiles/widget-doctype-show";
import SHOWDOCUMENT from "@/components/employees-module/profiles/documents/show";

import { LIST_DOCTYPES } from "@/services/store/doctype.module.js";
export default {
  components: {
    WIDGETDOCTYPE,
    WIDGETDOCTYPESHOW,
    SHOWDOCUMENT
  },
  data() {
    return {
      doctype: ""
    };
  },
  computed: {
    ...mapGetters([
      "getDoctypes",
      "getDoctype",
      "getDocument",
      "getEmployeeProfile",
      "currentUser"
    ])
  },
  beforeMount() {
    this.$store.dispatch(LIST_DOCTYPES);
  },
  methods: {
    redirect(doctype) {
      this.doctype = doctype;
      this.$bvModal.show("modal-doctype");
    },
    showModalDocument() {
      this.$socket.emit("VIEW_DOCUMENT_USER", {
        user_id: this.currentUser.id,
        document_id: this.getDocument.id
      });
      this.$bvModal.show("modal-doc-show");
    }
  }
};
</script>
