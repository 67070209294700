<template>
  <b-modal
    id="modal-add-column"
    title="Añadir nueva columna"
    v-model="modalShow"
  >
    <b-form-group
      id="input-group-1"
      label="Seleccione donde quiere que se agregue la nueva columna"
      label-for="input-1"
    >
      <b-form-select
        name="field"
        id="field"
        v-model="column"
        size="sm"
        class="mt-3"
      >
        <b-form-select-option value="first">
          Primera columna
        </b-form-select-option>
        <b-form-select-option
          :value="field"
          v-for="(field, index) in tableHeader"
          :key="index"
          >Despues de <strong> {{ field }} </strong>
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      id="input-group-2"
      label="Nombre de la columna"
      label-for="input-2"
    >
      <b-form-input
        id="input-2"
        :state="!error.name && !error.duplicate"
        v-model="name"
        type="text"
        required
        placeholder="Nombre de la columna"
      />
    </b-form-group>
    <div v-if="error.name" class="text-danger">
      El nombre de la columna es requerido
    </div>
    <div v-if="error.duplicate" class="text-danger">
      Ya existe una columna con el mismo nombre
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          size="sm"
          class="float-right"
          @click="close"
        >
          Cerrar
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right ml-1 mr-1"
          @click="saveColumn"
        >
          Agregar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    tableHeader: {
      type: Array
    }
  },
  computed: {},
  data() {
    return {
      column: "first",
      name: "",
      modalShow: false,
      error: {
        name: false,
        duplicate: false
      }
    };
  },
  methods: {
    saveColumn() {
      if (this.name === "") {
        this.error.name = true;
        this.error.duplicate = false;
        return;
      }
      if (this.tableHeader.indexOf(this.name.trim()) != -1) {
        this.error.duplicate = true;
        this.error.name = false;
        return;
      }
      this.modalShow = false;
      this.$emit("save-name-modal", { name: this.name, column: this.column });
    },
    show() {
      this.name = "";
      this.error = {
        name: false,
        duplicate: false
      };
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    }
  }
};
</script>

<style></style>vue
