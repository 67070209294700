<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-ver"></i>
      </template>
      <!--begin::Navigation-->
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text tag="div" class="navi-item">
          <!-- <a class="navi-link" style="cursor:pointer" @click="commentUpdate">
            <span class="navi-icon">
              <b-icon icon="pencil-fill" variant="primary"></b-icon>
            </span>
            <span class="navi-text">
              Editar
            </span>
          </a> -->
          <a href="#" class="navi-link" @click="commentDelete">
            <span class="navi-icon">
              <b-icon icon="dash-circle" variant="danger"></b-icon>
            </span>
            <span class="navi-text">Eliminar</span>
          </a>
        </b-dropdown-text>
      </div>
      <!--end::Navigation-->
    </b-dropdown>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
// import FOLDERFORM from "@/components/formations-module/forms/folder";
// import FORMATIONFORM from "@/components/formations-module/forms/formation";

import Swal from "sweetalert2";
import {
  DELETE_COMMENT_PROFILE,
  COMMENT_PROFILE
} from "@/services/store/comment.module";
import { EMPLOYEE_PROFILE } from "@/services/store/employee.module";

export default {
  props: ["data"],
  computed: {
    ...mapGetters(["currentUser", "getEmployeeProfile"])
  },
  methods: {
    commentUpdate() {
      this.$store.dispatch(COMMENT_PROFILE, this.data);
      this.$emit("comment-update-s", this.data);
    },
    commentDelete() {
      Swal.fire({
        title: "Estas seguro?",
        text: "¿Quieres eliminar el comentario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_COMMENT_PROFILE, this.data)
            .then(response => {
              if (response.status == 200) {
                this.$store.dispatch(
                  EMPLOYEE_PROFILE,
                  this.getEmployeeProfile.id
                );

                Swal.fire(
                  "Eliminada!",
                  "La comentario fue eliminada",
                  "success"
                );
              }
            });
        }
      });
    }
  },
  mounted() {}
};
</script>
