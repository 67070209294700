import ApiService from "@/services/api.service";
var _ = require("lodash");

//action
export const LIST_PROFILES = "listProfiles";
export const SHOW_PROFILE = "showProfile";
export const STORE_PROFILE = "storeProfile";
export const CHANGE_DATES_PROFILE = "changeDatesProfile";
export const PURGE_PROFILE = "purgeProfile";
//mutation
export const SET_PROFILES = "setProfiles";
export const SET_PROFILE = "setProfile";
export const SET_ERROR = "setError";
export const SET_DATES_PROFILE = "setDatesProfile";
export const ADD_PROFILE = "addProfile";
//getter
export const GET_PROFILES = "getProfiles";
export const GET_PROFILE = "getProfile";
//other

const state = {
  errors: null,
  profiles: [],
  profile: "",
  employees: []
};
const getters = {
  [GET_PROFILES](state) {
    return state.profiles;
  },
  [GET_PROFILE](state) {
    return state.profile;
  }
};

const actions = {
  [STORE_PROFILE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/profiles`, payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(ADD_PROFILE, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_PROFILES](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/profiles`, { params: payload })
        .then(response => {
          if (response.status == 200) {
            context.commit(SET_PROFILES, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_PROFILE);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_PROFILE](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/profiles/${id}`)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_PROFILE, response.data.data);
            resolve(response.data.data);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [CHANGE_DATES_PROFILE](context, profile) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/profiles/${profile.id}`, profile)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_DATES_PROFILE, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [PURGE_PROFILE](context) {
    context.commit(PURGE_PROFILE);
  }
};

const mutations = {
  [ADD_PROFILE](state, profile) {
    state.profiles.unshift(profile);
  },
  [PURGE_PROFILE](state, profile) {
    state.profile = profile.data;
  },
  [SET_DATES_PROFILE](state, profile) {
    state.profiles = _.map(state.profiles, function(f) {
      if (f.id == profile.id) {
        return (f = profile);
      } else {
        return f;
      }
    });
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PROFILES](state, profiles) {
    state.profiles = profiles;
    state.errors = {};
  },
  [SET_PROFILE](state, profile) {
    state.profile = profile;
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
