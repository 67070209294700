<template>
  <b-container>
    <b-row v-if="this.$route.name === `profileEmployee`">
      <DATOEMPLOYEE />
      <PROFILE />
      <WIDGETCOMMENT :profile="this.getEmployeeProfile" />
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DATOEMPLOYEE from "@/components/employees-module/profiles/widget-dato";
import PROFILE from "@/components/employees-module/profiles/widget-profile";
import WIDGETCOMMENT from "@/components/employees-module/profiles/comments/widget-comment";
export default {
  components: {
    DATOEMPLOYEE,
    PROFILE,
    WIDGETCOMMENT
  },

  data() {
    return {
      id_profile: this.$route.params.profile
    };
  },

  computed: {
    ...mapGetters(["getEmployeeProfile"])
  }
};
</script>
