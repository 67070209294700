<template>
  <div>
    <b-card
      v-for="(ds, i) in getReportsDepartments"
      :key="i"
      :title="ds.attributes.name"
      class="mb-4"
    >
      <b-row>
        <b-col @click="showModal(ds)" cols md="2">
          <div
            class="d-flex align-items-center rounded p-3 gutter-b bg-dark newFormation"
            style="cursor: pointer"
          >
            <span
              class="svg-icon mr-6 svg-icon-light iconFolder"
              style="background-size: 25%"
            >
              <inline-svg
                style="width: 25px; height: 25px"
                :src="`media/svg/icons/Files/excel-svgrepo-com.svg`"
              />
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <a
                class="font-weight-bold text-primary-75 text-light font-size-lg mb-1"
              >
                Nuevo
              </a>
            </div>
          </div>
        </b-col>
        <b-col
          @click="showModalInfo(d, ds)"
          v-for="(d, i) in ds.relationships.reportsOf"
          :key="i"
          cols
          md="2"
        >
          <div
            class="d-flex align-items-center rounded p-3 gutter-b bg-primary newFormation"
            style="cursor: pointer"
          >
            <span class="svg-icon mr-3 svg-icon-dark iconFolder">
              <inline-svg
                style="width: 25px; height: 25px"
                :src="`media/svg/icons/Files/excel-logo.svg`"
              />
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <a
                class="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1"
              >
                {{ getName(d.attributes.name) }}
                <!-- {{ d.attributes.name }} -->
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <div class="d-flex flex-column flex-grow-5 mr-2 mb-4">
          <a
            class="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-2"
          >
            <p>Reportes realizados</p>
          </a>
        </div>
      </b-row>
      <b-row>
        <b-col
          v-for="(d, i) in ds.relationships.reports"
          :key="i"
          cols
          md="3"
          @click="showModalReportShow(d, ds)"
        >
          <div
            class="d-flex align-items-center rounded p-3 gutter-b bg-success newFormation"
            style="cursor: pointer"
          >
            <span class="svg-icon mr-8 svg-icon-dark iconFolder">
              <inline-svg
                style="width: 30px; height: 30px"
                :src="`media/svg/icons/Files/excel-logo.svg`"
              />
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <a
                class="font-weight-bold text-dark-75 text-hover-dark font-size-lg mb-1"
              >
                <strong> {{ getName(d.attributes.name) }}</strong>
                <br />
                <small>{{ d.attributes.created }}</small>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!--=============================MODAL DE DEPARTAMENTOS DE EMPLEADO================================= --->

    <b-modal
      v-model="showModalReport"
      id="modal-add-report"
      size="xl"
      hide-footer
      title="Añadir plantilla de un Reporte"
    >
      <div class="d-block">
        <WIDGETADDREPORT
          @close-modal-add="hideModalAddReport"
          :department="department"
          :action="'create'"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="showModalInfo2"
      id="modal-add-info"
      size="xl"
      hide-footer
      title="Llenar Reporte"
    >
      <div class="d-block">
        <WIDGETINFOREPORT
          @close-modal-info="hideModalAddReport"
          :department="department"
          :dep="aux"
          :action="'edit'"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="showModalInfo3"
      id="modal-add-show"
      size="xl"
      hide-footer
      title="Mostrar Reporte"
    >
      <div class="d-block">
        <WIDGETSHOWREPORT
          @close-modal-show="hideModalAddReport"
          :report="report_show"
        />
      </div>
    </b-modal>

    <!-- ============================FIN DE MODAL DEPARTAMENTOS DE EMPLEADO======================================= -->
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { mapGetters } from "vuex";
import {
  REPORTS_DEPARTMENTS,
  SHOW_DEPARTMENT
} from "@/services/store/department.module";
import WIDGETADDREPORT from "@/components/departments-module/reports/widget-add-report";
import WIDGETINFOREPORT from "@/components/departments-module/reports/widget-info-report";
import WIDGETSHOWREPORT from "@/components/departments-module/reports/widget-show-report";

export default {
  computed: {
    ...mapGetters([
      "getReportsDepartments",
      "getDepartments",
      "getShowDepartment",
      "currentUser"
    ])
  },
  components: {
    WIDGETADDREPORT,
    WIDGETINFOREPORT,
    WIDGETSHOWREPORT
    //WIDGETDROPDOWN
  },
  beforeMount() {
    this.$store.dispatch(REPORTS_DEPARTMENTS);
  },
  data() {
    return {
      showModalReport: false,
      showModalInfo2: false,
      showModalInfo3: false,

      department: {},
      aux: {},

      report_add: {},
      report_info: {},
      report_show: {}
    };
  },
  methods: {
    getName(name) {
      return name.slice(0, 15);
    },
    showModal(ds) {
      this.$store.dispatch(SHOW_DEPARTMENT, ds.id);

      this.department = ds;
      this.showModalReport = true;
    },
    showModalInfo(d, ds) {
      this.$store.dispatch(SHOW_DEPARTMENT, ds.id);
      this.aux = ds;
      this.department = d;
      this.showModalInfo2 = true;
    },
    showModalReportShow(d, ds) {
      this.$store.dispatch(SHOW_DEPARTMENT, ds.id);

      this.report_show = d;
      this.showModalInfo3 = true;
    },
    hideModalAddReport() {
      // this.$store.dispatch(SHOW_DEPARTMENT, ds.id);

      this.showModalReport = false;
      this.showModalInfo3 = false;
      this.showModalInfo2 = false;
    }
  }
};
</script>
