import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes/router";
import store from "@/services/store";
import ApiService from "@/services/api.service";
import { VERIFY_AUTH } from "@/services/store/auth.module";
import Vuelidate from "vuelidate";
import Multiselect from "vue-multiselect";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VCalendar from "v-calendar";

import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import EventBus from "@/utils/event-bus";
import IdleVue from "idle-vue";
Vue.use(IdleVue, {
  eventEmitter: EventBus,
  store,
  idleTime: 3 * 60 * 60000, // 3 horas
  startAtIdle: false
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import VueExcelEditor from "vue-excel-editor";
Vue.use(VueExcelEditor);

import VueDraggable from "vue-draggable";
Vue.use(VueDraggable);
Vue.use(VCalendar, {
  componentPrefix: "vc"
});

Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

Vue.use(Vuelidate);
Vue.component("multiselect", Multiselect);
import moment from "moment";
import "moment/locale/es";

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && store.isAuthenticated === false) {
    next({
      name: "login"
    });
  } else {
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);
    if (to.name !== "login") {
      store.dispatch(VERIFY_AUTH).then(response => {
        if (typeof response !== "undefined") {
          if (response.status == 200 || response.status == 201) {
            next();
          } else {
            next({
              name: "login"
            });
          }
        }
      });
    }
    next();
  }
});
import VueSocketIO from "vue-socket.io";
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: `${process.env.VUE_APP_SOCKET_URL}`,
    transports: ["websocket"],
    upgrade: false
  })
);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
