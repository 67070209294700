var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"wizard wizard-3",attrs:{"id":"kt_wizard_v3","data-wizard-state":"step-first","data-wizard-clickable":"true"}},[_c('div',{staticClass:"wizard-nav border-bottom mb-1 mb-lg-5"},[_c('div',{staticClass:"wizard-steps px-8 py-8 px-lg-15 py-lg-3"},[_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step","data-wizard-state":"current"}},[_c('div',{staticClass:"wizard-label"},[_c('h3',{staticClass:"wizard-title"},[_vm._v("Formación")]),_c('div',{staticClass:"wizard-bar"})])]),(
                _vm.data.attributes.owner == this.currentUser.id ||
                  this.currentUser.attributes.rol_id === 1 ||
                  this.currentUser.attributes.rol_id === 2
              )?_c('div',{staticClass:"wizard-step",attrs:{"data-wizard-type":"step"}},[_c('div',{staticClass:"wizard-label"},[_c('h3',{staticClass:"wizard-title"},[_vm._v("Log")]),_c('div',{staticClass:"wizard-bar"})])]):_vm._e()])]),_c('div',{staticClass:"row justify-content-center py-12 px-8 py-lg-1 px-lg-10"},[_c('div',{staticClass:"col-xl-12 col-xxl-12"},[_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content","data-wizard-state":"current"}},[_c('b-row',[_c('b-col',{attrs:{"cols":""}},[_c('b-card',{staticClass:"card card-custom card-stretch gutter-b",attrs:{"title":_vm.data.attributes.name,"sub-title":("Creado por el usuario: " + (_vm.data.attributes.owner_name)),"footer":_vm.data.attributes.created,"footer-class":"text-muted font-weight-bold font-size-lg flex-grow-1"}},[(
                        !_vm.answeredTest && this.data.attributes.type === "test"
                      )?_c('p',{staticClass:"text-danger"},[_c('strong',[_vm._v("El empleado no ha respondido el test")])]):_vm._e(),_c('b-img',{staticClass:"rounded-0 m-4",staticStyle:{"width":"5%"},attrs:{"src":_vm.data.relationships.segment.attributes.logo}}),(
                        _vm.data.attributes.owner == this.currentUser.id ||
                          this.currentUser.attributes.rol_id === 1
                      )?_c('div',[_c('b',{staticClass:"mt-6"},[_vm._v(" Departamentos que pueden visualizar la Formación: ")]),_c('br'),_vm._l((_vm.data.relationships.departments),function(department){return _c('b-badge',{key:department.id,staticClass:"m-2",attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(department.attributes.name)+" ")])})],2):_vm._e(),_c('div',{staticClass:"card-body d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1 pb-5"},[_c('h4',[_vm._v("Descripción")]),_c('p',{staticClass:"text-dark-50 font-weight-normal font-size-lg mt-1"},[_vm._v(" "+_vm._s(_vm.data.attributes.description)+" ")]),_c('hr'),(_vm.data.attributes.type === 'application/pdf')?_c('PDF',{attrs:{"url":_vm.data.attributes.url}}):(_vm.data.attributes.type === "test")?_c('TEST',{attrs:{"test":_vm.data.relationships.test},on:{"listener":_vm.handler,"setAnswers":_vm.setAnswers,"listener-resolved":_vm.setResolved}}):_c('VIDEO',{attrs:{"data":_vm.data}})],1)])],1)],1)],1)],1),_c('div',{staticClass:"pb-5",attrs:{"data-wizard-type":"step-content"}},[(
                  _vm.data.attributes.owner == this.currentUser.id ||
                    this.currentUser.attributes.rol_id === 1 ||
                    this.currentUser.attributes.rol_id === 2
                )?_c('WIDGETLOG',{attrs:{"getFormation":_vm.data}}):_vm._e()],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }