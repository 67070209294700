<template>
  <b-container>
    <WIDGETMODALCREATE
      v-if="
        this.currentUser.attributes.rol === 'socio' ||
          this.currentUser.attributes.rol === 'lider'
      "
    />
    <b-row
      v-if="
        this.currentUser.attributes.rol === 'socio' ||
          this.currentUser.attributes.rol === 'lider'
      "
    >
      <WIDGETFOLDER
        :folder="element"
        v-for="(element, idx) in getFoldersParentNull"
        :key="idx"
        @show-folder="redirectFolder"
      />
    </b-row>

    <b-row>
      <b-col
        cols
        md="3"
        v-for="(element, idx) in getFormationsFolderNull"
        :key="idx"
      >
        <WIDGETFORMATION
          v-if="
            element.attributes.active == true ||
              element.attributes.owner == currentUser.id
          "
          :formation="element"
          @show-formation="redirectFormation"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETFOLDER from "@/components/formations-module/folders/widget-folder";
import WIDGETFORMATION from "@/components/formations-module/formations/widget-formation";
import WIDGETMODALCREATE from "@/components/formations-module/widget-modal-create";
import {
  SET_BREADCRUMB_FOLDER,
  SET_BREADCRUMB_FORMATION
} from "@/services/store/breadcrumbs.module";

import { CONTENTS_FOLDER } from "@/services/store/folder.module";
var _ = require("lodash");
export default {
  components: {
    WIDGETFOLDER,
    WIDGETFORMATION,
    WIDGETMODALCREATE
  },
  computed: {
    ...mapGetters(["getFolders", "currentUser", "getFormations"]),
    getFoldersParentNull() {
      return _.filter(this.getFolders, function(f) {
        return !f.attributes.parent_id;
      });
    },
    getFormationsFolderNull() {
      if (this.currentUser.attributes.rol === `socio`) {
        return _.filter(this.getFormations, function(f) {
          return !f.attributes.folder_id;
        });
      } else {
        return _.filter(this.getFormations, function(f) {
          return f.attributes.active == true;
        });

        // return this.getFormations;
      }
    }
  },
  methods: {
    redirectFolder(folder) {
      this.$store.dispatch(SET_BREADCRUMB_FOLDER, {
        id: folder.id,
        name: folder.attributes.name,
        path: folder.attributes.path
      });

      this.$store.dispatch(CONTENTS_FOLDER, folder.id).then(response => {
        if (response.status == 200 || response.status == 201) {
          let folder = response.data.data;
          this.$router.push({
            name: `showFolder`,
            params: { path: folder.name }
          });
        }
      });
    },
    redirectFormation(formation) {
      this.$store.dispatch(SET_BREADCRUMB_FORMATION, {
        id: formation.id,
        name: formation.attributes.name,
        path: formation.attributes.path
      });

      this.$router.push({
        name: `showFormation`,
        params: { path: formation.attributes.path }
      });
    }
  },
  mounted() {
    this.$store.commit("purgeFolder");
  }
};
</script>
