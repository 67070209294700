<template>
  <div>
    <div align-self="stretch">
      <b-card
        bg-variant="light"
        no-body
        no-footer
        style="height: calc(80vh - 145px)"
      >
        <template #header>
          <b-row>
            <b-col v-if="showBotton || widhtScreen" class="text-left">
              <b-button :block="screen" @click="showContacts()" size="sm">
                <b-icon icon="arrow-left" left></b-icon>
              </b-button>
            </b-col>
            <b-col
              @click="$bvModal.show('modal-show-employees-chat')"
              style="cursor: pointer"
              md="9"
              lg="11"
              class="text-left"
            >
              <h4 class="mb-0">{{ getNameChat(getChat) }}</h4>
              <p
                :class="!screen ? 'mb-n5' : ''"
                v-if="getChat.attributes.type !== 'direct'"
              >
                <b-badge
                  variant="secondary"
                  class="mr-1"
                  v-for="(employee, index) in all_employees"
                  :key="employee.id"
                >
                  <template v-if="index < 5">
                    {{ employee.name }}
                  </template>
                  <template v-else-if="index == 5">
                    <strong
                      >+ {{ all_employees.length - index }} contactos
                    </strong>
                  </template>
                </b-badge>
              </p>
            </b-col>
            <b-col md="3" lg="1" v-if="isGroupChat" class="text-right">
              <b-button
                :block="screen"
                v-b-modal.modal-add-employees-chat
                @click="$bvModal.show('modal-add-employees-chat')"
                size="sm"
              >
                <b-icon icon="person-plus" left></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template v-if="getChat" style="max-height:100%">
          <div v-if="spinner.messages" class="text-center">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
            Cargando mensajes...
          </div>

          <div
            class="message-content"
            id="chat-scroller"
            ref="content"
            v-on:scroll.passive="onScroll"
            style="position: relative; overflow-y: auto;"
          >
            <div class="text-center" v-if="allMessages">
              <b-badge>No existe mas mensajes</b-badge>
            </div>
            <div v-for="(msg, index) in messages" :key="`${index}-${msg.id}`">
              <b-alert
                show
                :variant="msg.attributes.parent_id ? 'secondary' : 'white'"
              >
                <WIDGETMESSAGE
                  @delete-message="deleteMessage"
                  :msg="msg"
                  :index="index"
                  ref="witgetMessage"
                />
              </b-alert>
            </div>
          </div>
        </template>
      </b-card>

      <template v-if="getChat">
        <FORMADDMESSAGE :msg="{}" :action="'create'" />
      </template>
      <b-modal
        id="modal-add-employees-chat"
        size="lg"
        hide-footer
        title="Añadir empleados al chat"
      >
        <div class="d-block">
          <FORMEMPLOYEESCHAT
            :chat="getChat"
            :action="'edit'"
            :type="chat.attributes.type"
            @close-modal-add="closeModalAddChat"
          />
        </div>
      </b-modal>

      <b-modal
        v-if="getChat.attributes.type !== 'direct'"
        id="modal-show-employees-chat"
        size="md"
        scrollable
        :title="`Chat ${getChat.attributes.name}`"
      >
        <div class="d-block">
          <SHOWLISTEMPLOYEESCHAT :employees="getChat.relationships.employees" />
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="closeModalShowChat"
            >
              Cerrar
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
import moment from "moment";
import {
  LIST_MESSAGES,
  GET_ATTACHMENTS_MESSAGE,
  GET_ATTACHMENTS_MESSAGE_UPDATE
} from "@/services/store/chat.module";

import FORMEMPLOYEESCHAT from "@/components/chats-module/form/form-employees-chat";
import FORMADDMESSAGE from "@/components/chats-module/form/form-add-message";
import WIDGETMESSAGE from "@/components/chats-module/message/widget-message";
import SHOWLISTEMPLOYEESCHAT from "@/components/chats-module/show-list-employees-chat";

import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module";

export default {
  props: ["chat", "me"],
  components: {
    FORMEMPLOYEESCHAT,
    FORMADDMESSAGE,
    WIDGETMESSAGE,
    SHOWLISTEMPLOYEESCHAT
  },
  computed: {
    ...mapGetters([
      "getMessages",
      "getChat",
      "currentUser",
      "getListChats",
      "getEmployeesAll"
    ]),
    screen() {
      return this.tamWindows < 576 ? true : false;
    },
    widhtScreen() {
      return this.tamWindows < 576 ? true : false;
    },
    messages() {
      let messages = [];
      if (this.getChat?.relationships?.messages.length > 0) {
        messages = this.getChat.relationships.messages;
      }
      return messages;
    },
    isGroupChat() {
      return this.getChat?.attributes?.type !== "direct";
    },
    all_employees() {
      return _.map(this.getChat.relationships.employees, d => {
        return { id: d.id, name: d.attributes.name };
      });
    }
  },
  data() {
    return {
      chatPrueba: this.chat,
      hoy: moment().format("DD MM YYYY"),
      action: "create",
      pageMessage: 1,
      loadMessage: false,
      allMessages: false,
      newMessage: false,
      spinner: {
        messages: false
      },
      tamWindows: window.innerWidth,
      showBotton: window.innerWidth < 576 ? true : false
    };
  },

  methods: {
    showUserChat() {},
    getNameChat(data) {
      let name = "";
      if (data?.attributes) {
        if (data?.attributes?.type === "direct") {
          let names = data?.attributes?.name.split("-");
          this.currentUser.id === data?.attributes?.created_by
            ? (name = names[0])
            : (name = names[1]);
        } else {
          name = data.attributes.name;
        }
      }
      return name;
    },
    deleteMessage() {
      let el = document.querySelector(".message-content");
      if (!this.loadMessage) {
        el.scrollTop = el.scrollHeight;
      } else {
        el.scrollTop = 150;
      }
    },
    setPageMessage: function(data) {
      this.pageMessage = data.page;
      this.loadMessage = false;
      this.allMessages = false;
      this.spinner.messages = false;
      this.showBotton = data.show;
    },

    showDate(fecha) {
      if (fecha != this.hoy) {
        this.hoy = fecha;
        return true;
      } else {
        return false;
      }
    },
    orderMessages(msgs) {
      return _.orderBy(msgs, ["created_at"], ["Desc"]);
    },

    getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    closeModalAddChat() {
      this.$bvModal.hide("modal-add-employees-chat");
    },
    closeModalShowChat() {
      this.$bvModal.hide("modal-show-employees-chat");
    },
    openModalAddEmployesChat() {},
    sendMessage(e) {
      e.preventDefault();
      this.message = {};
    },
    onScroll() {
      if (this.pageMessage == 1) {
        this.endPositionScroll();
        if (this.getChat.relationships.messages.length > 0) {
          this.pageMessage++;
        }
      } else {
        if (!this.allMessages) {
          let el = document.querySelector(".message-content");
          if (el.scrollTop == 0) {
            this.spinner.messages = true;
            this.$store
              .dispatch(LIST_MESSAGES, {
                chat: this.getChat.id,
                page: this.pageMessage
              })
              .then(response => {
                if (response.data.data.length == 0) {
                  this.allMessages = true;
                } else {
                  let el2 = document.querySelector(".message-content");
                  el2.scrollTop = 250;
                  this.pageMessage = this.pageMessage + 1;
                }
                this.spinner.messages = false;
              });
          }
        }
      }
    },
    endPositionScroll() {
      let container = document.querySelector(".message-content");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    endPositionScrollChildren(data) {
      this.$refs.witgetMessage.scrollMessage(data);
    },

    showContacts() {
      this.showBotton = false;
      this.$emit("atras");
    },
    myEventHandler() {
      this.tamWindows = window.innerWidth;
      if (this.tamWindows > 576) {
        this.showContacts();
        this.showBotton = false;
      }
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
  },
  mounted() {
    this.endPositionScroll();
    this.sockets.subscribe("ATTACHMENT_MESSAGE_DELETE", message => {
      this.$store.commit("updateMessage", message);
    });
    this.sockets.subscribe("MESSAGE_DELETE", message => {
      this.$store.commit("deleteMessage", message);
    });
    this.$socket.emit("VIEW_CHAT_USER", {
      user_id: this.currentUser.id,
      chat_id: 1
    });
    this.sockets.subscribe("MESSAGE_SEND", data => {
      this.loadMessage = false;
      this.newMessage = true;
      if (data.relationships.attachments.length > 0) {
        this.$store.dispatch(GET_ATTACHMENTS_MESSAGE, data.id);
      } else {
        this.$store.commit("addMessage", data);
      }
      if (data.attributes.parent_id == 0) {
        this.endPositionScroll();
      }
    });

    this.sockets.subscribe("MESSAGE_UPDATE", data => {
      if (data.relationships.attachments.length > 0) {
        this.$store.dispatch(GET_ATTACHMENTS_MESSAGE_UPDATE, data.id);
      } else {
        this.$store.commit("updateMessage", data);
      }
    });
  },
  updated() {
    if (this.newMessage || !this.loadMessage) {
      this.endPositionScroll();
      this.newMessage = false;
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  }
};
</script>

<style></style>
