var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"md"}},[_c('b-row',[_c('b-col',{attrs:{"cols":""}},[(
            !this.resolved &&
              this.test.attributes.owner != this.currentUser.id &&
              this.$route.name != "profileEmployee"
          )?_c('ul',_vm._l((this.questions),function(element,idx){return _c('li',{key:idx,staticClass:"list-group-item"},[_c('p',[_vm._v(" Pregunta ")]),_c('input',{staticClass:"form-control mb-4",attrs:{"type":"text","disabled":""},domProps:{"value":element.text}}),_c('br'),_c('div',[_c('p',[_vm._v("Seleccione la respuesta correcta")]),_vm._l((element.answers),function(answer,i){return _c('b-list-group-item',{key:i,attrs:{"variant":answer.correct ? "primary" : "light","button":""},on:{"click":function($event){return _vm.selectAnswer(idx, i)}}},[_vm._v(" "+_vm._s(answer.text)+" "),(answer.correct)?_c('b-icon',{staticClass:"ml-6",attrs:{"icon":"check2-circle","scale":"2","variant":"primary"}}):_vm._e()],1)})],2)])}),0):(
            (this.resolved &&
              this.test.attributes.owner === this.currentUser.id &&
              this.$route.name === "profileEmployee") ||
              (this.resolved &&
                this.test.attributes.owner != this.currentUser.id)
          )?_c('ul',_vm._l((this.answered),function(element,idx){return _c('li',{key:idx,staticClass:"list-group-item"},[_c('b-card',{attrs:{"bg-variant":element.correct ? "primary" : "danger"}},[_c('p',[_vm._v("Pregunta")]),_c('div',[_c('strong',[_vm._v(_vm._s(element.question.text))])]),_c('br'),_c('div',[_c('p',[_vm._v("Respuestas")]),_vm._l((element.question.answers),function(answer,i){return _c('b-list-group-item',{key:i},[_vm._v(" "+_vm._s(answer.text)+" "),(answer.correct && element.correct)?_c('b-icon',{staticClass:"ml-6",attrs:{"icon":"check2-circle","scale":"2","variant":"primary"}}):_vm._e()],1)})],2)])],1)}),0):(
            this.currentUser.attributes.rol === "socio" ||
              (this.currentUser.attributes.rol === "lider" &&
                this.$route.name === "profileEmployee") //cuando el lider ingresa al test
          )?_c('ul',_vm._l((this.test.attributes.questions),function(element,idx){return _c('li',{key:idx,staticClass:"list-group-item"},[_c('b-card',[_c('p',[_vm._v("Pregunta")]),_c('div',[_c('strong',[_vm._v(_vm._s(element.text))])]),_c('br'),_c('div',[_c('p',[_vm._v("Respuestas")]),_vm._l((element.answers),function(answer,i){return _c('b-list-group-item',{key:i},[_vm._v(" "+_vm._s(answer.text)+" "),(answer.correct)?_c('b-icon',{staticClass:"ml-6",attrs:{"icon":"check2-circle","scale":"2","variant":"primary"}}):_vm._e()],1)})],2)])],1)}),0):_vm._e()])],1),_c('b-row',[(
          !_vm.resolved &&
            this.$route.name != "profileEmployee" &&
            this.test.attributes.owner != this.currentUser.id
        )?_c('b-col',{attrs:{"align-self":"end"}},[_c('button',{ref:"btnSubmit",staticClass:"btn btn-success font-weight-bold text-uppercase px-9 py-4",on:{"click":_vm.onSubmit}},[_vm._v(" Enviar ")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }