<template>
  <b-container>
    <b-row v-if="this.$route.name === `employees`">
      <LISTEMPLOYEES />
    </b-row>
  </b-container>
</template>

<script>
import LISTEMPLOYEES from "@/components/employees-module/list";
export default {
  components: {
    LISTEMPLOYEES
  }
};
</script>
