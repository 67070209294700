<template>
  <b-container>
    <b-row v-if="this.$route.name === `activities`">
      <b-col cols md="12">
        <b-card
          title="Actividad de los empleados"
          class="card card-custom card-stretch gutter-b"
          v-if="permisions"
        >
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filtrar"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Borrar</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="6" class="my-1">
              <b-form-group
                label="Por pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>

          <b-row>
            <!-- Main table element -->
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
            >
              <template #cell(name)="row">
                {{ row.value }}
              </template>
              <template #cell(info)="row">
                <b-button size="sm" @click="info(row.item)" class="mr-1">
                  Ver
                </b-button>
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { LIST_EMPLOYEES_ALL } from "@/services/store/employee.module.js";
import {
  LIST_ACTIVITIES_BY_USER,
  TIME_WORKDAY
} from "@/services/store/activity.module.js";

export default {
  components: {},
  data() {
    return {
      fields: [
        {
          key: "user",
          label: "Usuario",
          sortable: true,
          class: "text-center"
        },
        {
          key: "info",
          label: "",
          sortable: false,
          class: "text-center"
        }
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 20, 50, 100, 500, 1000],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      allowRoleIds: [1, 2],
      codesStatus: [200, 201]
    };
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "currentUser"]),
    permisions() {
      return (
        this.allowRoleIds.indexOf(this.currentUser.attributes.rol_id) != -1
      );
    },
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let employees = this.getEmployeesAll;
      for (var idx in employees) {
        items.push({
          info: {
            id: employees[idx].id,
            type_work: employees[idx].attributes.type_work
          },
          user: employees[idx].attributes.name
        });
      }
      return items;
    },
    totalRows() {
      return this.items.length;
    }
  },
  beforeCreate() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    info(user) {
      const payload = {
        type_work: user.info.type_work
      };
      this.$store.dispatch(TIME_WORKDAY, payload);
      this.$store
        .dispatch(LIST_ACTIVITIES_BY_USER, user.info.id)
        .then(response => {
          if (this.codesStatus.indexOf(response.status) != -1) {
            this.$router.push({
              name: "showActivitiesUser",
              params: {
                username: user.user
              }
            });
          }
        });
    }
  },
  mounted() {}
};
</script>
