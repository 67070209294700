import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_FOLDERS = "listFolders";
export const SHOW_FOLDER = "showFolder";
export const STORE_FOLDER = "storeFolder";
export const UPDATE_FOLDER = "updateFolder";
export const DELETE_FOLDER = "deleteFolder";
export const CONTENTS_FOLDER = "contentsFolder";
export const PUT_FOLDER = "putFolder";
export const STORE_FORMATION_BY_FOLDER = "storeFormationByFolder";
export const UPDATE_FORMATION_BY_FOLDER = "updateFormationByFolder";
export const PURGE_FOLDER = "purgeFolder";
export const DELETE_FOLDER_CHILDREN = "deleteFolderChildren";
export const DELETE_FORMATION_CHILDREN = "deleteFormationChildren";
// mutation types
export const SET_FOLDERS = "setFolders";
export const SET_ERROR = "setError";
export const ADD_FOLDER = "addFolder";
export const ADD_FORMATION = "addFormation";
// getter types
export const GET_FOLDERS = "getFolders";

const state = {
  errors: null,
  folders: [],
  contentsFolder: []
};

const getters = {
  getFolders(state) {
    return state.folders;
  },
  getContentFolder(state) {
    return state.contentsFolder;
  }
};

const actions = {
  [LIST_FOLDERS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/folders`, { params: payload })
        .then(response => {
          context.commit(SET_FOLDERS, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [CONTENTS_FOLDER](context, folder) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/folders/${folder}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(CONTENTS_FOLDER, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_FOLDER](context, folder) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/folders`, folder)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_FOLDER, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_FORMATION_BY_FOLDER](context, params) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/formations`, params)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_FORMATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_FOLDER](context, folder) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/folders/${folder.id}`, folder)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_FOLDER, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_FORMATION_BY_FOLDER](context, formation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/formations/${formation.id}`, formation)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_FORMATION_BY_FOLDER, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_FOLDER](context, folder) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/folders/${folder.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_FOLDER, folder);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_FOLDER_CHILDREN](context, folder) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/folders/${folder.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_FOLDER_CHILDREN, folder);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_FORMATION_CHILDREN](context, formation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/formations/${formation.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_FORMATION_CHILDREN, formation);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [PURGE_FOLDER](context) {
    context.commit(PURGE_FOLDER);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_FOLDERS](state, folders) {
    state.folders = folders;
  },
  [CONTENTS_FOLDER](state, contents) {
    state.contentsFolder = contents;
  },
  [UPDATE_FOLDER](state, folder) {
    if (folder.attributes.parent_id) {
      state.contentsFolder.relationships.children = _.map(
        state.contentsFolder.relationships.children,
        function(f) {
          if (f.id == folder.id) {
            return (f = folder);
          } else {
            return f;
          }
        }
      );
    } else {
      state.folders = _.map(state.folders, function(f) {
        if (f.id == folder.id) {
          return (f = folder);
        } else {
          return f;
        }
      });
    }
  },
  [UPDATE_FORMATION_BY_FOLDER](state, formation) {
    for (let i = 0; i < state.folders.length; i++) {
      if (state.folders[i].id == formation.attributes.folder_id) {
        if (state.folders[i].relationships.formations) {
          for (
            let j = 0;
            j < state.folders[i].relationships.formations.length;
            j++
          ) {
            if (
              state.folders[i].relationships.formations[j].id == formation.id
            ) {
              state.folders[i].relationships.formations[j] = formation;
            }
          }
          state.folders[i].relationships.formations.push(formation);
        }
      }
    }
  },
  [DELETE_FOLDER](state, folder) {
    state.folders = _.dropWhile(state.folders, function(f) {
      return f.id == folder.id;
    });
  },
  [DELETE_FOLDER_CHILDREN](state, folder) {
    state.contentsFolder.relationships.children = _.dropWhile(
      state.contentsFolder.relationships.children,
      function(f) {
        return f.id == folder.id;
      }
    );
  },
  [DELETE_FORMATION_CHILDREN](state, formation) {
    state.contentsFolder.relationships.formations = _.dropWhile(
      state.contentsFolder.relationships.formations,
      function(f) {
        return f.id == formation.id;
      }
    );
  },
  [ADD_FOLDER](state, folder) {
    if (folder.attributes.parent_id) {
      let source = state.contentsFolder;
      source.relationships.children.unshift(folder);
    } else {
      state.folders.unshift(folder);
    }
  },
  [ADD_FORMATION](state, formation) {
    let source = state.contentsFolder;
    source.relationships.formations.unshift(formation);
  },
  [PURGE_FOLDER](state) {
    state.contentsFolder = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
