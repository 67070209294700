<template>
  <b-col cols sm="6" md="3" xs="12" lg="3" xl="3">
    <b-card
      class="card-custom card-stretch gutter-b text-center"
      style='cursor:pointer; background-position: left; background-size: 22%; background-image: url("media/svg/shapes/folder2.svg");'
    >
      <b-row class="justify-content-md-center">
        <b-col class="d-flex align-items-center " @click="show">
          <a
            class="font-weight-normal text-dark-75 text-hover-dark font-size-lg mb-1 ml-14"
          >
            <strong> {{ folder.attributes.name }}</strong>
            <br />
            <b
              class="text-muted"
              v-if="folder.attributes.owner !== this.currentUser.id"
            >
              Creada por:
              {{ folder.attributes.owner_name }}
            </b>
          </a>
        </b-col>
        <b-col cols="auto" class="mr-auto">
          <WIDGETDROPDOWN
            :data="folder"
            v-if="folder.attributes.owner == this.currentUser.id"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETDROPDOWN from "@/components/formations-module/widget-dropdown";
import { CONTENTS_FOLDER, PURGE_FOLDER } from "@/services/store/folder.module";
import {
  SET_BREADCRUMB_FOLDER,
  SET_BREADCRUMB_PATH
} from "@/services/store/breadcrumbs.module";
export default {
  props: ["folder"],
  computed: {
    ...mapGetters(["currentUser"])
  },
  components: {
    WIDGETDROPDOWN
  },
  methods: {
    show() {
      this.$store.dispatch(PURGE_FOLDER);
      this.$store
        .dispatch(CONTENTS_FOLDER, this.folder.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$store.dispatch(
              SET_BREADCRUMB_PATH,
              response.data.data.attributes.path
            );
            this.$store.dispatch(SET_BREADCRUMB_FOLDER, {
              id: response.data.data.id,
              name: response.data.data.attributes.name,
              path: response.data.data.attributes.path
            });
            let stringPath = response.data.data.attributes.path;
            this.$router.push({
              name: `folderChild`,
              params: {
                path: stringPath,
                id: response.data.data.id,
                segment:
                  response.data.data.relationships.segment.attributes.name,
                parent: response.data.data.attributes.parent_id ?? undefined
              }
            });
          }
        })
        .catch(e => console.error(e));
    }
  }
};
</script>
