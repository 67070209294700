<template>
  <div class="mt-2 mb-n4">
    <b-row :class="myMessage ? 'justify-content-end' : 'justify-content-start'">
      <b-col
        class=""
        v-for="(element, idx) in msg.relationships.attachments"
        :key="idx"
        cols
        sm="6"
        :md="msg.attributes.parent_id == null ? '4' : '4'"
        lg="3"
      >
        <b-card
          :bg-variant="msg.attributes.parent_id == 0 ? 'secondary' : 'white'"
          no-body
          class="card-custom card-stretch gutter-b"
        >
          <b-row class="mt-2 ml-2 mr-2">
            <b-col
              v-b-tooltip.hover="{ variant: 'info' }"
              :title="element.name"
              @click.prevent="showAttachment(element)"
              cols="9"
              style="cursor: pointer"
              class="pt-2"
            >
              <div>
                <template v-if="isType(element.type) === 'image'">
                  <b-img
                    style="height: 100px; object-fit: cover"
                    :src="element.public_url"
                    fluid
                    alt="Imagen"
                  ></b-img>
                </template>
                <template v-else-if="isType(element.type) == 'video'">
                  <b-embed
                    type="video"
                    aspect="16by9"
                    controls
                    poster="poster.png"
                  >
                    <source :src="element.public_url" type="video/mp4" />
                  </b-embed>
                </template>
                <template v-else-if="isType(element.type) === 'application'">
                  <span class="svg-icon svg-icon-sm">
                    <b-img
                      style="height: 90px; object-fit: cover"
                      src="media/svg/files/doc.svg"
                      fluid
                      :alt="element.name"
                    ></b-img>
                  </span>
                </template>
              </div>
            </b-col>
            <b-col align="right" cols="3" class="mb-4">
              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                no-caret
                right
                no-flip
              >
                <template v-slot:button-content>
                  <i class="ki ki-bold-more-ver"></i>
                </template>
                <!--begin::Navigation-->
                <div class="navi navi-hover">
                  <b-dropdown-text
                    v-if="element.created_by == currentUser.id"
                    tag="div"
                    class="navi-item"
                  >
                    <div
                      class="navi-link"
                      style="cursor: pointer"
                      @click="deleteAttachment(element)"
                    >
                      <span class="navi-icon">
                        <b-icon icon="dash-circle" variant="danger"></b-icon>
                      </span>
                      <span class="mr-2 navi-text">Eliminar</span>
                    </div>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item">
                    <b-button
                      variant="outline-white"
                      :href="element.public_url"
                      class="navi-link"
                      style="cursor: pointer"
                    >
                      <b-icon icon="file-earmark-arrow-down"></b-icon>
                      <span class="mr-2 navi-text">Descargar</span>
                    </b-button>
                  </b-dropdown-text>
                </div>

                <!--end::Navigation-->
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-message-attachment"
      :title="fileAttachment.name"
      v-model="handleHide"
      size="xl"
    >
      <WIDGETSHOWATTACHMENT :fileAttachment="fileAttachment" />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleHide = false"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import WIDGETSHOWATTACHMENT from "@/components/chats-module/attachment/widget-show-attachment";
import { DELETE_ATTACHMENT_MESSAGE } from "@/services/store/chat.module";
import { mapGetters } from "vuex";

const Swal = require("sweetalert2");

export default {
  props: ["msg", "myMessage"],
  components: {
    WIDGETSHOWATTACHMENT
  },
  comments: {
    Swal
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      handleHide: false,
      fileAttachment: {}
    };
  },
  methods: {
    isType(name) {
      var n = name.split("/");

      return n[0];
    },
    subName(name) {
      var n = name.split(".");

      return n[0].substr(0, 4).concat(".", n[1]);
    },
    showAttachment(file) {
      let types = file.type.split("/");
      if (
        types[0] == "image" ||
        types[0] == "video" ||
        (types[0] === "application" && types[1] === "pdf")
      ) {
        this.fileAttachment = file;
        this.handleHide = true;
      } else {
        window.open(file.public_url);
      }
    },
    deleteAttachment(file) {
      Swal.fire({
        title: "¿Estas seguro de eliminar el archivo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, eliminar archivo"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_ATTACHMENT_MESSAGE, file.id)
            .then(response => {
              Swal.fire("Exito!", "El archivo fue eliminado", "success");
              this.$socket.emit(
                "DELETE_ATTACHMENT_MESSAGE",
                response.data.data
              );
            })
            .catch(e => {
              console.error(e);
              Swal.fire("Error!", "El archivo no fue eliminado", "danger");
            });
        }
      });
    }
  }
};
</script>
