<template>
  <div class="col-12">
    <b-card
      title="Mis solicitudes realizadas"
      class="card card-custom card-stretch gutter-b"
    >
      <!-- <pre>1{{ holidays }}</pre> -->
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- ninguno --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <!-- Main table element -->
      <b-row>
        <b-col lg="12" class="my-1">
          <b-table
            striped
            hover
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(date_approved)="row">
              {{
                row.item.status === "aprobado" ? row.item.date_approved : "/"
              }}
            </template>
            <template #cell(days_request)="row">
              {{ dayRequest(row.item) }}
            </template>
            <template #cell(days_approved)="row">
              {{ dayApproved(row.item) }}
            </template>

            <template #cell(action)="row">
              <b-badge
                href="#"
                @click="showModalVacation(row.item)"
                variant="dark"
                class="mb-2"
              >
                <b-icon
                  aria-hidden="true"
                  icon="eye"
                  title="Ver perfil de empleado"
                >
                </b-icon>
                Ver
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="showModalVacationUser"
      id="modal-add-show"
      size="xl"
      hide-footer
      title="Mostrar Vacación"
    >
      <div class="d-block">
        <WIDGETSHOWVACATION
          @close-modal-show="hideModalShowVacation"
          :vacation="vacation_show"
          :holiday="holidays"
          :dateHoly="dateHoliday"
          :dayHoly="dayHolyday"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WIDGETSHOWVACATION from "@/components/holidays-module/vacation/widget-show-vacation";
var _ = require("lodash");
var moment = require("moment");
const Swal = require("sweetalert2");
import {
  //LIST_PROFILES,
  SHOW_PROFILE
} from "@/services/store/profile.module.js";
export default {
  comments: {
    Swal
  },
  props: ["holidays"],
  components: {
    WIDGETSHOWVACATION
  },
  data() {
    return {
      showModalVacationUser: false,
      vacation_show: {},
      dateHoliday: [],
      dayHolyday: [],
      // sortDirection:
      fields: [
        {
          key: "index",
          sortable: true,
          label: "#"
          // sortDirection: "desc"
        },
        {
          key: "date_request",
          label: "Fecha solicitado",
          class: "text-center"
        },
        {
          key: "days_request",
          label: "Días solicitados",
          class: "text-center"
        },
        {
          key: "date_approved",
          label: "Fecha aprobados",
          class: "text-center"
        },
        {
          key: "days_approved",
          label: "Días aprobados",
          class: "text-center"
        },

        {
          key: "status",
          label: "Estado",
          class: "text-center",
          sortable: true
        },
        {
          key: "action",
          label: "Accion",
          class: "text-center",
          sortable: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: "Ver todo" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getProfile"]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(
        this.getProfile.relationships.vacations,
        ["id"],
        ["desc"]
      );
      for (var idx in employees) {
        let color;
        if (
          employees[idx].relationships.status.attributes.name === `aprobado`
        ) {
          color = `success`;
        } else if (
          employees[idx].relationships.status.attributes.name === `rechazado`
        ) {
          color = `danger`;
        }

        items.push({
          index: parseInt(idx) + 1,
          id: employees[idx].id,
          date_request:
            moment(employees[idx].attributes.date_init).format("YYYY-MM-DD ") +
            " / " +
            moment(employees[idx].attributes.date_end).format("YYYY-MM-DD "),
          date_approved:
            moment(employees[idx].attributes.date_start).format("YYYY-MM-DD ") +
            " / " +
            moment(employees[idx].attributes.date_over).format("YYYY-MM-DD "),
          status: employees[idx].relationships.status.attributes.name,
          _cellVariants: { status: color },
          date_init: employees[idx].attributes.date_init,
          date_end: employees[idx].attributes.date_end,
          date_start: employees[idx].attributes.date_start,
          date_over: employees[idx].attributes.date_over,
          jobs: employees[idx].relationships.jobs
        });
      }
      return items;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  beforeMount() {
    //this.$store.dispatch(LIST_PROFILES);
    this.$store.dispatch(SHOW_PROFILE, this.currentUser.id);
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dayRequest(date) {
      let fechas = [];
      this.holidays.forEach(value => {
        fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
      });
      var from = moment(date.date_init),
        to = moment(date.date_end),
        days = 0;

      while (!from.isAfter(to)) {
        var aux = _.find(fechas, function(e) {
          return e == moment(from).format("YYYY, M, DD");
        });
        // Si no es sabado ni domingo
        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
          days++;
        }
        from.add(1, "days");
      }
      return days;
    },
    dayApproved(date) {
      let fechas = [];
      this.holidays.forEach(value => {
        fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
      });
      if (date.date_start != null) {
        var from = moment(date.date_start),
          to = moment(date.date_over),
          days = 0;
        while (!from.isAfter(to)) {
          var aux = _.find(fechas, function(e) {
            return e == moment(from).format("YYYY, M, DD");
          });
          // Si no es sabado ni domingo
          if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7 && !aux) {
            days++;
          }
          from.add(1, "days");
        }
        return days;
      } else {
        return 0;
      }
    },
    showModalVacation(vacation) {
      this.vacation_show = _.find(
        this.getProfile.relationships.vacations,
        function(v) {
          return v.id === vacation.id;
        }
      );
      this.showModalVacationUser = true;
      //let dateHoliday = [];
      this.holidays.forEach(value => {
        this.dateHoliday.push(
          moment(value.date_calendar).format("YYYY, M, DD")
        );
      });

      this.holidays.forEach(value => {
        this.dayHolyday.push(value.holiday);
      });
    },
    hideModalShowVacation() {
      this.showModalVacationUser = false;
    }
  }
};
</script>
