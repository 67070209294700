<template>
  <table class="table table-borderless table-vertical-center">
    <thead>
      <tr>
        <th class="p-0" style="width: 20px"></th>
        <th class="p-0" style="min-width: 40px"></th>
        <th class="p-0" style="min-width: 20px"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="py-5 pl-0">
          <div class="symbol symbol-50 symbol-light mr-2">
            <span class="symbol-label">
              <img
                :src="
                  this.type == 'doc'
                    ? 'media/svg/shapes/pdf.svg'
                    : this.type == 'test'
                    ? 'media/svg/shapes/test.svg'
                    : 'media/svg/shapes/video.svg'
                "
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </td>
        <td class="pl-0">
          <a
            @click="show()"
            class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
            style="cursor:pointer;"
            >{{ this.formation.attributes.name }}</a
          >
          <span class="text-muted font-weight-bold d-block">
            {{ this.formation.attributes.created_at }}
          </span>
        </td>
        <td
          class="text-right"
          v-if="this.formation.attributes.owner == currentUser.id"
        >
          <WIDGETDROPDOWN :data="this.formation" @listener-active="active" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import WIDGETDROPDOWN from "@/components/formations-module/widget-dropdown";
var _ = require("lodash");
import {
  SHOW_FORMATION,
  CHANGE_STATUS_FORMATION,
  PURGE_FORMATION
} from "@/services/store/formation.module";
export default {
  props: {
    formation: Object,
    type: String
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      background_color: `${
        !this.formation.attributes.active ? "#70b7ff" : "#ffffff"
      }`,
      class: [
        { type: "doc", class: "success" },
        { type: "video", class: "warning" },
        { type: "test", class: "info" }
      ]
    };
  },
  components: {
    WIDGETDROPDOWN
  },
  methods: {
    getClass(item) {
      let className = "default";
      const found = _.find(this.class, c => {
        return c.type === item.attributes.type;
      });
      if (found) {
        className = found.class;
      }

      return className;
    },
    show() {
      this.$store.dispatch(PURGE_FORMATION);
      this.$store
        .dispatch(SHOW_FORMATION, this.formation.id)
        .then(response => {
          this.$router.push({
            name: `showFormation`,
            params: { path: response.attributes.name }
          });
        })
        .catch(e => console.error(e));
    },
    active() {
      this.$store
        .dispatch(CHANGE_STATUS_FORMATION, this.formation.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.background_color = `${
              !response.data.data.attributes.active ? "#70b7ff" : "#ffffff"
            }`;
          }
        })
        .catch(e => console.error(e));
    }
  },
  mounted() {}
};
</script>
