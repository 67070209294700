var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":""}},[(
            _vm.currentUser.attributes.rol_id != 3 &&
              _vm.currentUser.attributes.rol_id != 5
          )?_c('b-tab',{attrs:{"title":"Solicitudes en Calendario","active":_vm.example == 'listVacationsCalendar' ? true : false}},[_c('div',[_c('LISTVACATIONCALENDAR',{attrs:{"holidays":_vm.getHolidays}})],1)]):_vm._e(),(
            _vm.currentUser.attributes.rol_id != 3 &&
              _vm.currentUser.attributes.rol_id != 5
          )?_c('b-tab',{attrs:{"title":"Solicitudes Realizadas"}},[_c('div',[_c('LISTVACATION',{attrs:{"holidays":_vm.getHolidays}})],1)]):_vm._e(),(
            _vm.currentUser.attributes.rol_id != 3 &&
              _vm.currentUser.attributes.rol_id != 5 &&
              _vm.havePermission
          )?_c('b-tab',{attrs:{"title":"Solicitudes Pendientes"}},[_c('div',[_c('LISTREQUEST',{attrs:{"holidays":_vm.getHolidays}})],1)]):_vm._e(),(
            _vm.currentUser.attributes.rol_id != 3 &&
              _vm.currentUser.attributes.rol_id != 5
          )?_c('b-tab',{attrs:{"title":"Lista Empleados"}},[_c('div',[_c('LISTPROFILE',{attrs:{"user":_vm.currentUser,"isr":_vm.havePermission}})],1)]):_vm._e(),_c('b-tab',{attrs:{"title":"Mis Vacaciones","active":this.currentUser.attributes.rol_id == 3 ||
            _vm.example == 'myVacations'
              ? true
              : false}},[_c('div',[_c('b-row',{staticClass:"mb-6 ml-6"},[_c('b-col',{attrs:{"cols":"","md":"12"}},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.showHoliday}},[_vm._v("Solicitar vacaciones")])],1)],1),_c('LISTUSERVACATION',{attrs:{"holidays":_vm.getHolidays}})],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }