<template>
  <b-row>
    <FORMATION ref="formation" :data="getFormation" />
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import FORMATION from "@/components/formations-module/formations/show";
import { PURGE_TEST, PURGE_FORMATION } from "@/services/store/formation.module";
import { VIEW_FORMATION_BY_USER } from "@/services/store/formation.module";
export default {
  components: {
    FORMATION
  },
  computed: {
    ...mapGetters(["getFormation", "currentUser"])
  },
  methods: {
    inside() {
      const payload = {
        formation_id: this.getFormation.id,
        user_id: this.currentUser.id,
        action: 1
      };
      this.$store.dispatch(VIEW_FORMATION_BY_USER, payload);
    }
  },
  beforeRouteLeave: function(to, from, next) {
    // Se le indica al subcomponente que se esta cambiando de ruta
    this.$refs.formation
      .cameout()
      .then(response => {
        if (response) {
          this.$store.dispatch(VIEW_FORMATION_BY_USER, response);
          this.$store.dispatch(PURGE_FORMATION);
          this.$store.dispatch(PURGE_TEST);
          next();
        } else {
          next(from);
        }
      })
      .catch(e => console.error(e));
  },
  mounted() {
    this.inside();
  }
};
</script>
