<template>
  <WIDGETTASK />
</template>
<script>
import WIDGETTASK from "@/components/tasks-module/widget-task";
import { LIST_CUSTOMERS } from "@/services/store/task.module";

export default {
  components: {
    WIDGETTASK
  },
  beforeCreate() {
    this.$store.dispatch(LIST_CUSTOMERS);
  }
};
</script>
