<template>
  <b-col lg="12" xl="12" class="my-1">
    <b-card>
      <b-row>
        <b-col lg="6"
          ><b-card-title
            >Comentario del usuario:
            {{ comment.relationships.owner }}
          </b-card-title></b-col
        >
        <b-col lg="5"
          ><b-card-text class="text-right small text-muted">
            {{ comment.attributes.created_at }}
          </b-card-text>
        </b-col>
        <b-col>
          <DROPDOWNCOMMENT
            @comment-update-s="commentUpdateS"
            class="text-right mt-n2"
            :data="comment"
            v-if="comment.attributes.created_by == this.currentUser.id"
          />
        </b-col>
      </b-row>

      <b-card-text>
        {{ comment.attributes.comment }}
      </b-card-text>
    </b-card>
  </b-col>
</template>
<script>
import { mapGetters } from "vuex";
import DROPDOWNCOMMENT from "@/components/employees-module/profiles/comments/dropdown-comment";
export default {
  props: ["comment"],
  components: {
    DROPDOWNCOMMENT
  },
  data() {
    return {
      showForm: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    commentUpdateS() {
      this.showForm = true;
      this.$emit("comment-update-w", this.comment);
    }
  }
};
</script>
