<template>
  <div>
    <b-button
      variant="primary"
      :href="url"
      v-if="hideDownload == false || hideDownload == null"
    >
      Descargar
    </b-button>
    <b-spinner
      style="width: 6rem; height: 6rem;"
      label="Large Spinner"
      type="grow"
      v-if="load"
    />
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="url"
      :page="i"
      style="display: inline-block; width: 100%"
    ></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: ["url", "hideDownload"],
  components: {
    pdf
  },
  data() {
    return {
      src: pdf.createLoadingTask(this.url),
      numPages: undefined,
      load: false
    };
  },
  methods: {
    view() {
      this.load = true;
      this.src.promise
        .then(pdf => {
          this.load = false;
          this.numPages = pdf.numPages;
        })
        .catch(e => console.error(e));
    }
  },
  beforeMount() {
    this.view();
  },
  mounted() {}
};
</script>
