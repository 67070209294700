var _ = require("lodash");
import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import router from "@/routes/router";
import Vue from "vue";

const httpStatus = [200, 201];

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const ACTIVITY = "activity";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";

export const SET_USER_PICTURE = "setUserPicture";

export const SET_ERROR = "setError";
export const SET_STATUS = "setStatus";
export const ADD_CHAT_USER = "addChatUser";
export const DELETE_CHAT_USER = "deleteChatUser";
// getter types
export const GET_ERROR = "getError";

const state = {
  errors: "",
  user: JSON.parse(window.localStorage.getItem("user")) ?? {},
  status: false,
  isAuthenticated: !!JwtService.getToken(),
  isFirstLogin: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isFirstLogin(state) {
    return state.isFirstLogin;
  },
  getError(state) {
    return state.errors;
  },
  getStatus(state) {
    return state.status;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/auth/login`, credentials)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            if (response.data.access_token != null) {
              context.commit(SET_AUTH, response.data);
              JwtService.saveToken(response.data.access_token);
              resolve(response.data);
            }
          } else {
            router.push({
              name: "login"
            });
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [LOGOUT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/auth/logout", payload)
        .then(() => {
          context.commit(PURGE_AUTH);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
        });
    }
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise((resolve, reject) => {
        ApiService.post(`api/auth/me`)
          .then(response => {
            if (httpStatus.indexOf(response.status) != -1) {
              resolve(response);
            } else {
              router.push({
                name: "login"
              });
            }
          })
          .catch(error => {
            if (error.response) {
              context.commit(SET_ERROR, error.response);
            }
            context.commit(PURGE_AUTH);
            router.push({
              name: "login"
            });
            reject(error);
          });
      });
    }
  },
  [ACTIVITY](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise((resolve, reject) => {
        ApiService.post(`api/auth/activity`, payload)
          .then(response => {
            if (response.data.status === true) {
              context.commit(SET_STATUS);
              resolve(true);
            }
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.isFirstLogin = data.user.attributes.isFirstLogin;
    state.errors = {};
  },
  [SET_USER_PICTURE](state, data) {
    state.user.relationships.image = data;
    console.log("ola", data);
    Vue.set(state.user.relationships, "image", data);
    state.errors = {};
  },
  [SET_STATUS](state) {
    state.status = true;
  },
  [PURGE_AUTH](state) {
    JwtService.destroyToken();
    state.isAuthenticated = false;
    state.status = false;
    state.user = {};
    state.isFirstLogin = false;
    state.errors = {};
  },
  [ADD_CHAT_USER](state, data) {
    let chat_user = _.find(data.relationships.chat_user, function(cu) {
      return cu.pivot.user_id == state.user.id;
    });
    if (chat_user) {
      state.user.relationships.chat_user = [
        ...state.user.relationships.chat_user,
        chat_user
      ];
    }
  },
  [DELETE_CHAT_USER](state, data) {
    if (data.attributes.type == "direct") {
      _.find(state.user.relationships.chat_user, function(cu) {
        if (data.id == cu.pivot.chat_id) {
          cu.pivot.state = 0;
          return cu;
        }
      });
    } else {
      state.user.relationships.chat_user = _.filter(
        state.user.relationships.chat_user,
        function(cu) {
          return data.id != cu.pivot.chat_id;
        }
      );
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
