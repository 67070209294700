<template>
  <b-card
    class="card card-custom card-stretch gutter-b"
    v-if="
      this.currentUser.attributes.rol_id === 1 ||
        this.currentUser.attributes.rol_id === 2
    "
  >
    <b-row>
      <b-col cols>
        <div class="col-xl-12 col-xxl-12">
          <b-row>
            <b-col lg="12" class="my-1">
              <b-form-group
                label="Filtrar"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Borrar</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" class="my-1">
              <b-form-group
                label="Por pagina"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-4"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="mb-4"
              ></b-pagination>
            </b-col>
          </b-row>

          <!-- Main table element -->
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            small
            @filtered="onFiltered"
          >
            <template #cell(name)="row">
              {{ row.value }}
            </template>

            <template #cell(id)="row">
              <b-button size="sm" @click="info(row.item)" class="mr-1">
                Ver
              </b-button>
            </template>
          </b-table>
        </div>
      </b-col>

      <!-- Info modal -->
      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
        size="lg"
      >
        <h4>Numeros de desconexiones en el dia: {{ this.countInactividad }}</h4>
        <b-table
          :items="itemsActivities"
          :fields="fieldsActivities"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          show-empty
          small
        >
          <template #cell(name)="row">
            {{ row.value }}
          </template>
        </b-table>
      </b-modal>
    </b-row>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
export default {
  data() {
    return {
      fields: [
        {
          key: "day",
          label: "Fecha",
          sortable: true,
          class: "text-center"
        },
        {
          key: "worked",
          label: "Trabajó (hrs)",
          sortable: true,
          class: "text-center"
        },
        {
          key: "inactivity",
          label: "Inactividad (hrs)",
          sortable: true,
          class: "text-center"
        },
        {
          key: "extra_hours",
          label: "Horas Extras (hrs)",
          sortable: true,
          class: "text-center"
        },
        {
          key: "id",
          label: "",
          sortable: true,
          class: "text-center"
        }
      ],
      fieldsActivities: [
        {
          key: "start",
          label: "Inicio",
          sortable: true,
          class: "text-center"
        },
        {
          key: "end",
          label: "Fin",
          sortable: true,
          class: "text-center"
        },
        {
          key: "min_of_activity",
          label: "Minutos de actividad",
          sortable: true,
          class: "text-center"
        },
        {
          key: "action",
          label: "Tipo",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [15, 20, 50, { value: 1000, text: "Ver todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      itemsActivities: [],
      countInactividad: 0
    };
  },
  computed: {
    ...mapGetters(["currentUser", "getEmployeeProfileActivity"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let activities = this.getEmployeeProfileActivity;
      const activitiesGroupedByDays = _.groupBy(activities, "attributes.day");
      const workday = this.getTimeWorkday;

      for (const [key, value] of Object.entries(activitiesGroupedByDays)) {
        const worked = _.sumBy(value, function(a) {
          return a.attributes.min_of_activity;
        });

        const hours = workday - worked;
        let inactivity = 0;
        let extra_hours = 0;

        if (Math.sign(hours) == -1) {
          extra_hours = Math.abs(hours);
        } else if (Math.sign(hours) == 1) {
          inactivity = hours;
        }

        items.push({
          day: key,
          worked: Math.round(worked / 60),
          inactivity: Math.round(inactivity / 60),
          extra_hours: Math.round(extra_hours / 60),
          activity: value
        });
      }

      return items;
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    info(item) {
      let activities = item.activity;
      activities = _.orderBy(activities, ["id"], ["asc"]);
      const countInactivity = _.countBy(activities, act => {
        return act.attributes.end_activity_type == 3;
      });

      this.countInactividad = countInactivity.true;

      for (let idx in activities) {
        let color;
        if (activities[idx].attributes.end_activity_type == 1) {
          color = `primary`;
        } else if (activities[idx].attributes.end_activity_type == 2) {
          color = `success`;
        } else if (activities[idx].attributes.end_activity_type == 3) {
          color = `danger`;
        }

        this.itemsActivities.push({
          start: activities[idx].attributes.start_time,
          end: activities[idx].attributes.end_time,
          min_of_activity: `${activities[idx].attributes.min_of_activity} min(s)`,
          action: activities[idx].relationships.end_activity.attributes.name,
          _cellVariants: { action: color }
        });
      }
      this.$root.$emit("bv::show::modal", this.infoModal.id);
    },
    resetInfoModal() {
      this.countInactividad = 0;
      this.itemsActivities = [];
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>
