<template>
  <div class="header flex-column header-fixed">
    <div class="header-top" style="margin-top: 0px !important">
      <div class="container">
        <b-navbar toggleable type="dark" variant="primary">
          <b-navbar-brand class="ml-4">
            <router-link :to="{ name: 'admin' }">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="max-h-50px"
              />
            </router-link>
          </b-navbar-brand>
          <b-navbar-toggle target="navbar-toggle-collapse">
            <TOPBAR />
          </b-navbar-toggle>
        </b-navbar>
      </div>
    </div>
    <div class="header-bottom">
      <div class="container mt-6">
        <BREADCRUMB />
      </div>
    </div>
  </div>
</template>
<style>
.customNavbar {
  background-color: red !important;
  display: flex;
}
</style>
<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import TOPBAR from "@/components/header/topbar.vue";
import BREADCRUMB from "@/components/menus/breadcrumb";
export default {
  name: "Header",
  components: {
    TOPBAR,
    BREADCRUMB
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser"]),
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  mounted() {
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );
  }
};
</script>
