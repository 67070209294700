<template>
  <b-row>
    <b-col>
      <p>Empleados</p>
      <p />
      <div class="mb-4">
        <b-avatar-group size="3rem">
          <b-avatar
            :id="element.id"
            :text="_getName(element.name)"
            :src="element.image"
            v-for="(element, idx) in getEmployees"
            :key="idx"
            button
            :title="element.name"
            @click="showTaskByUser(element.id)"
          />
        </b-avatar-group>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getEmployees"])
  },
  methods: {
    _getName(name) {
      if (name) {
        return name
          .split(" ")
          .map(item => item.charAt(0))
          .slice(0, 2)
          .join("");
      }
    },
    showTaskByUser(id) {
      var element = document.getElementById(`${id}`);
      if (element.classList.contains("btn-primary")) {
        element.classList.remove("btn-primary");
        element.classList.add("btn-secondary");
        this.selected = "";
      } else {
        this.selected = id;
        element.classList.remove("btn-secondary");
        element.classList.add("btn-primary");
      }
      this.$emit("show-task-by-user", id);
    }
  }
};
</script>
