import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_CHATS = "listChats";
export const SHOW_CHAT = "showChat";
export const STORE_CHAT = "storeChat";
export const UPDATE_CHAT = "updateChat";
export const DELETE_CHAT = "deleteChat";
export const MESSAGES_CHAT = "messagesChat";
export const PUT_CHAT = "putChat";
export const UPDATE_FORMATION_BY_CHAT = "updateMessageByChat";
export const PURGE_CHAT = "purgeChat";
export const SELECT_CHAT = "selectChat";
export const DELETE_EMPLOYEE_CHAT = "deleteEmployeeChat";

// mutation types
export const SET_CHATS = "setChats";
export const SET_CHAT = "setChat";
export const SET_MESSAGES_CHAT = "setMessagesChat";
export const ADD_ATTACHMENTS_MESSAGES = "addAttachmentsMessage";
export const NEW_MESSAGE_USER_CHAT = "newMessageUserChat";
export const VIEWED_USER_CHAT = "viewedUserChat";
export const SET_ERROR = "setError";
export const ADD_CHAT = "addChat";
export const ADD_CHAT_SOCKET = "addChatSocket";
export const ADD_MESSAGE = "addMessage";
export const ENABLE_CHAT = "enableChat";
export const UPDATE_EMPLOYEE_CHAT = "updateEmployeeChat";

// getter types
export const GET_CHATS = "getListChats";
export const GET_CHAT = "getChat";
export const GET_ATTACHMENTS_MESSAGE = "getAttachmentsMessage";
export const GET_ATTACHMENTS_MESSAGE_UPDATE = "getAttachmentsMessageUpdate";
// messages
export const DELETE_MESSAGE = "deleteMessage";
export const DELETE_MESSAGE_CHILDREN = "deleteMessageChildren";

export const LIST_MESSAGES = "listMessages";
export const ADD_MESSAGES_CHAT = "addMessagesChat";
export const LEAVE_CHAT_USER = "leaveChatUser";

export const UPDATE_MESSAGE = "updateMessage";
export const DELETE_ATTACHMENT_MESSAGE = "deleteAttachmentMessage";
export const PURGE_MESSAGES = "purgeMessages";

const state = {
  errors: null,
  chats: [],
  messagesChat: [],
  chat: {}
};

const getters = {
  getListChats(state) {
    return state.chats;
  },
  getChat(state) {
    return state.chat;
  },
  getContentChat(state) {
    return state.messagesChat;
  }
};

const actions = {
  [GET_ATTACHMENTS_MESSAGE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/messages/attachments/${payload}`)
        .then(response => {
          context.commit(ADD_MESSAGE, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [GET_ATTACHMENTS_MESSAGE_UPDATE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/messages/attachments/${payload}`)
        .then(response => {
          context.commit(UPDATE_MESSAGE, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_MESSAGE](context, message) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/messages/${message.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_MESSAGE, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_MESSAGES](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/messages`, {
        params: payload
      })
        .then(response => {
          context.commit(ADD_MESSAGES_CHAT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SELECT_CHAT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/chats/${payload}`)
        .then(response => {
          context.commit(SET_CHAT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_CHATS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/chats`, {
        params: payload
      })
        .then(response => {
          context.commit(SET_CHATS, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [MESSAGES_CHAT](context, chat) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/chats/messages/${chat}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_MESSAGES_CHAT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_CHAT](context, chat) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/chats`, chat)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            // context.commit(ADD_CHAT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_EMPLOYEE_CHAT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/chats/delete/employee/${payload.chat}`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_EMPLOYEE_CHAT, payload);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_CHAT](context, chat) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/chats/${chat.id}`, chat)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_CHAT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_FORMATION_BY_CHAT](context, formation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/formations/${formation.id}`, formation)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_FORMATION_BY_CHAT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_CHAT](context, chat) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/chats/${chat.id}`, {
        type: chat.attributes.type
      })
        // put(`api/v1/chats/user/delete/${chat.id}`, chat)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_CHAT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_MESSAGE_CHILDREN](context, chat) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/messages/${chat.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_MESSAGE_CHILDREN, chat);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [PURGE_CHAT](context) {
    context.commit(PURGE_CHAT);
  },
  [PURGE_MESSAGES](context) {
    context.commit(PURGE_MESSAGES);
  },
  [DELETE_ATTACHMENT_MESSAGE](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/messages/attachments/${id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_MESSAGE, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_CHATS](state, chats) {
    state.chats = chats;
  },
  [SET_MESSAGES_CHAT](state, contents) {
    state.messagesChat = contents;
  },
  [UPDATE_CHAT](state, chat) {
    if (chat.attributes.parent_id) {
      state.messagesChat.relationships.children = _.map(
        state.messagesChat.relationships.children,
        function(f) {
          if (f.id == chat.id) {
            return (f = chat);
          } else {
            return f;
          }
        }
      );
    } else {
      state.chats = _.map(state.chats, function(f) {
        if (f.id == chat.id) {
          return (f = chat);
        } else {
          return f;
        }
      });
    }
  },
  [UPDATE_FORMATION_BY_CHAT](state, formation) {
    if (formation.attributes.folder_id) {
      state.messagesChat.relationships.formations = _.map(
        state.messagesChat.relationships.formations,
        function(f) {
          if (f.id == formation.id) {
            return (f = formation);
          } else {
            return f;
          }
        }
      );
    }
  },
  [DELETE_CHAT](state, chat) {
    state.chats = _.dropWhile(state.chats, function(f) {
      return f.id == chat.id;
    });
  },

  [NEW_MESSAGE_USER_CHAT](state, chat) {
    let c = _.find(state.chats, function(cu) {
      return cu.id == chat.chat_id;
    });
    if (c) {
      c.relationships.chat_user.forEach(e => {
        if (
          e.pivot.chat_id == chat.chat_id &&
          e.pivot.user_id != chat.created_by
        ) {
          e.pivot.viewed = chat.viewed;
          e.pivot.unread_messages = e.pivot.unread_messages + 1;
        }
      });
    }
  },

  [ADD_ATTACHMENTS_MESSAGES](state, message) {
    if (state.chat.id === message.attributes.messageable_id) {
      if (message.attributes.parent_id == 0) {
        let msg = _.find(state.chat.relationships.messages, function(m) {
          return m.id == message.id;
        });
        msg.relationships.attachments = [
          ...msg.relationships.attachments,
          message.relationships.attachments
        ];
      } else {
        let msg = _.find(state.chat.relationships.messages, function(m) {
          return m.id == message.parent_id;
        });
        let msg_children = _.find(msg.relationships.messages, function(m) {
          return m.id == message.id;
        });
        msg_children.relationships.attachments = [
          ...msg_children.relationships.attachments,
          message.relationships.attachments
        ];
      }
    }
  },
  [VIEWED_USER_CHAT](state, chat) {
    _.find(state.chats, function(cu) {
      if (cu.id == chat.chat_id) {
        cu.relationships.chat_user.forEach(e => {
          if (
            e.pivot.chat_id == chat.chat_id &&
            e.pivot.user_id == chat.user_id
          ) {
            e.pivot.viewed = chat.viewed;
            e.pivot.unread_messages = 0;
          }
        });
        return cu;
      }
    });
  },
  [ENABLE_CHAT](state, chat) {
    let c = _.find(state.chats, function(cu) {
      return cu.id == chat.chat_id;
    });
    if (c) {
      c.relationships.chat_user.forEach(element => {
        element.pivot.state = 1;
      });
    }
  },

  [ADD_CHAT](state, chat) {
    state.chats = [...state.chats, chat];
  },
  [ADD_CHAT_SOCKET](state, chat) {
    state.chats = [...state.chats, chat];
  },

  [SET_CHAT](state, chat) {
    state.chat = chat;
  },
  [PURGE_CHAT](state) {
    state.messagesChat = [];
  },

  [PURGE_MESSAGES](state) {
    state.chat.relationships.message = [];
  },
  [DELETE_MESSAGE_CHILDREN](state, message) {
    _.find(state.chat.relationships.messages, function(ms) {
      if (ms.id === message.id) {
        state.chat.relationships.messages.children = _.filter(
          state.chat.relationships.messages.children,
          function(m) {
            return m.id != message.id;
          }
        );
      }
      return ms;
    });
  },
  [DELETE_MESSAGE](state, message) {
    if (message.attributes.parent_id > 0) {
      state.chat.relationships.messages.forEach(element => {
        if (element.id === message.attributes.parent_id) {
          _.find(element.relationships.children, function(m) {
            if (m.id == message.id) {
              m.attributes.state = 0;
              return m;
            }
          });
        }
      });
    } else {
      _.find(state.chat.relationships.messages, function(m) {
        if (m.id == message.id) {
          m.attributes.state = 0;
          return m;
        }
      });
    }
  },
  [UPDATE_MESSAGE](state, message) {
    _.findIndex(state.chat.relationships.messages, function(o) {
      if (message.attributes.parent_id > 0) {
        _.findIndex(o.relationships.children, function(c) {
          if (c.id === message.id) {
            c.attributes.message = message.attributes.message;
            c.relationships.attachments = message.relationships.attachments;
            return c;
          }
        });
      } else if (o.id === message.id) {
        o.attributes.message = message.attributes.message;
        o.relationships.attachments = message.relationships.attachments;
        return o;
      }
    });
  },

  [ADD_MESSAGES_CHAT](state, messages) {
    let message_list = _.uniqBy(
      _.concat(messages, state.chat.relationships.messages),
      "id"
    );
    state.chat.relationships.messages = _.reverse(
      _.orderBy(message_list, ["id"], ["desc"])
    );
  },

  [LEAVE_CHAT_USER](state, data) {
    if (state.chat && state.chat.id == data.chat) {
      state.chat.relationships.employees = _.filter(
        state.chat.relationships.employees,
        function(e) {
          return e.id != data.user;
        }
      );
      state.chat.relationships.chat_user = _.filter(
        state.chat.relationships.chat_user,
        function(e) {
          return e.pivot.user_id != data.user;
        }
      );
    }
  },

  [ADD_MESSAGE](state, message) {
    let chat = state.chat;
    if (state.chat.id === message.attributes.messageable_id) {
      //si el mensaje tiene un mensaje padre
      if (message.attributes.parent_id > 0) {
        chat.relationships.messages.forEach(msg => {
          if (msg.id === message.attributes.parent_id) {
            msg.relationships.children.push(message);
            msg.relationships.children = _.uniqBy(
              msg.relationships.children,
              "id"
            );
          }
        });
      } else {
        state.chat.relationships.messages.push(message);
        state.chat.relationships.messages = _.uniqBy(
          state.chat.relationships.messages,
          "id"
        );
      }
    }
  },
  [UPDATE_EMPLOYEE_CHAT](state, data) {
    if (state.chat.id === data.chat) {
      state.chat.relationships.employees = _.filter(
        state.chat.relationships.employees,
        e => {
          return e.id != data.employee;
        }
      );
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
