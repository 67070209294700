<template>
  <div>
    <b-card
      title="Lista de los empleados"
      class="card card-custom card-stretch gutter-b"
      v-if="
        this.currentUser.attributes.rol_id === 1 ||
          this.currentUser.attributes.rol_id === 2
      "
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- ninguno --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Orden inicial"
            label-for="initial-sort-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="initial-sort-select"
              v-model="sortDirection"
              :options="['asc', 'desc']"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Escribe para buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filtro activado"
            description="Deje todo sin marcar para filtrar todos los datos"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox value="employee">Nombre</b-form-checkbox>
              <b-form-checkbox value="mail">Email</b-form-checkbox>
              <b-form-checkbox value="rol">Cargo</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Por pagina"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="7" md="6" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #cell(high_low)="row">
          <b-badge :variant="row.value ? 'success' : 'danger'">{{
            row.value ? "Alta" : "Baja"
          }}</b-badge>
        </template>

        <template #cell(jobs)="row">
          <strong v-for="(r, i) in row.value" :key="i"> {{ r.name }} , </strong>
          <!-- {{ row.value.first }} {{ row.value.last }} -->
        </template>

        <template #cell(actions)="row">
          <b-badge
            href="#"
            @click="redirect(row.item)"
            variant="dark"
            class="mb-2"
          >
            <b-icon
              aria-hidden="true"
              icon="eye"
              title="Ver perfil de empleado"
            >
            </b-icon>
            Perfil
          </b-badge>
          <b-badge
            href="#"
            @click="change_status(row.item)"
            :variant="row.item.high_low ? 'danger' : 'success'"
          >
            <b-icon
              aria-hidden="true"
              :icon="row.item.high_low ? 'x-circle' : 'check-circle'"
              :title="
                row.item.high_low ? 'Activar Empleado' : 'Desactivar Empleado'
              "
            >
            </b-icon>
            {{ row.item.high_low ? "Dar baja" : "Dar alta" }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");

// ==========sweetalert2===============
const Swal = require("sweetalert2");
import {
  LIST_EMPLOYEES_ALL,
  CHANGE_STATUS_EMPLOYEE,
  EMPLOYEE_PROFILE
} from "@/services/store/employee.module.js";

export default {
  comments: {
    Swal
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#"
        },
        {
          key: "employee",
          sortable: true,
          label: "Nombre",
          sortDirection: "desc"
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "type",
          label: "Cargo",
          sortable: true
        },
        {
          key: "jobs",
          label: "Departamento"
        },
        {
          key: "high_low",
          label: "Estado",
          sortable: true
        },
        {
          key: "actions",
          label: "Acciones"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: "Ver todo" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters(["getEmployeesAll", "currentUser"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    items() {
      let items = [];
      let employees = [];
      employees = _.orderBy(this.getEmployeesAll, ["id"], ["desc"]);
      for (var idx in employees) {
        items.push({
          index: parseInt(idx) + 1,
          id: employees[idx].id,
          employee: employees[idx].attributes.name,
          email: employees[idx].attributes.email,
          type: employees[idx].attributes.type,
          high_low: employees[idx].attributes.high_low,
          jobs: employees[idx].relationships.jobs
        });
      }
      return items;
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_EMPLOYEES_ALL);
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  beforeUpdate() {
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    change_status(employee) {
      if (employee.high_low) {
        this.change_status_employee(employee, "baja");
      } else {
        this.change_status_employee(employee, "alta");
      }
    },
    change_status_employee(employee, msg) {
      Swal.fire({
        title: "¿Estas seguro de dar de " + msg + " al empleado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, dar de " + msg
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(CHANGE_STATUS_EMPLOYEE, employee)
            .then(() => {
              Swal.fire(
                "Exito!",
                "El empleado fue dado de " + msg + ".",
                "success"
              );
              this.$store.dispatch(LIST_EMPLOYEES_ALL);
            })
            .catch(e => {
              console.error(e);
              Swal.fire(
                "Error!",
                "El empleado no fue dado de " + msg + ".",
                "danger"
              );
            });
        }
      });
    },
    redirect(profile) {
      this.$store
        .dispatch(EMPLOYEE_PROFILE, profile.id)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.$router.push({
              name: `profileEmployee`,
              params: { profile: profile.id }
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
