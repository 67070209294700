var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[(
      this.currentUser.attributes.rol === 'socio' ||
        this.currentUser.attributes.rol === 'lider'
    )?_c('WIDGETMODALCREATE'):_vm._e(),(
      this.currentUser.attributes.rol === 'socio' ||
        this.currentUser.attributes.rol === 'lider'
    )?_c('b-row',_vm._l((_vm.getFoldersParentNull),function(element,idx){return _c('WIDGETFOLDER',{key:idx,attrs:{"folder":element},on:{"show-folder":_vm.redirectFolder}})}),1):_vm._e(),_c('b-row',_vm._l((_vm.getFormationsFolderNull),function(element,idx){return _c('b-col',{key:idx,attrs:{"cols":"","md":"3"}},[(
          element.attributes.active == true ||
            element.attributes.owner == _vm.currentUser.id
        )?_c('WIDGETFORMATION',{attrs:{"formation":element},on:{"show-formation":_vm.redirectFormation}}):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }