<template>
  <b-row>
    <b-col v-if="permissions" cols md="12">
      <div
        class="d-flex align-items-center rounded p-5 gutter-b bg-light-dark newFormation"
        @click="showModal"
        style="cursor: pointer"
      >
        <span class="svg-icon mr-5 svg-icon-dark iconFolder">
          <span class="svg-icon svg-icon-lg">
            <inline-svg :src="`media/svg/icons/Files/File-plus.svg`" />
          </span>
        </span>
        <div class="d-flex flex-column flex-grow-1 mr-2">
          <a
            class="font-weight-normal text-dark-75 text-hover-dark font-size-lg mb-1"
          >
            {{ getDoctype.doctype.attributes.name }}
          </a>
        </div>
      </div>
    </b-col>

    <b-modal id="modal-payroll" size="md" hide-footer title="Nueva Nomina">
      <div class="d-block text-center">
        <PAYROLL @store-nomina="hideModal" :action="`create`" />
      </div>
    </b-modal>
    <b-modal id="modal-circular" hide-footer title="Nueva Circular">
      <div class="d-block text-center">
        <CIRCULAR @store-circular="hideModal" :action="`create`" />
      </div>
    </b-modal>
    <b-modal id="modal-contract" hide-footer title="Nuevo contrato">
      <div class="d-block text-center">
        <CONTRACT @store-contract="hideModal" :action="`create`" />
      </div>
    </b-modal>
    <b-modal
      id="modal-assessment"
      hide-footer
      title="Nueva valoracion"
      size="lg"
    >
      <div class="d-block text-center">
        <ASSESSMENT @store-assessment="hideModal" :action="`create`" />
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import PAYROLL from "@/components/employees-module/profiles/documents/payrolls/payroll";
import CIRCULAR from "@/components/employees-module/profiles/documents/circulares/circular";
import CONTRACT from "@/components/employees-module/profiles/documents/contracts/contract";
import ASSESSMENT from "@/components/employees-module/profiles/documents/ratings/assessment";
export default {
  computed: {
    ...mapGetters(["getBreadcrumbDoctype", "getDoctype", "currentUser"]),
    permissions() {
      return this.rolesCreate.indexOf(this.currentUser.attributes.rol_id) != -1;
    }
  },
  components: {
    PAYROLL,
    CIRCULAR,
    CONTRACT,
    ASSESSMENT
  },
  data() {
    return {
      rolesCreate: [1, 2]
    };
  },
  methods: {
    showModal() {
      this.$nextTick(() => {
        if (this.getDoctype.doctype.attributes.name == "nominas") {
          this.$bvModal.show("modal-payroll");
        } else if (this.getDoctype.doctype.attributes.name == "circulares") {
          this.$bvModal.show("modal-circular");
        } else if (this.getDoctype.doctype.attributes.name == "contratos") {
          this.$bvModal.show("modal-contract");
        } else if (this.getDoctype.doctype.attributes.name == "valoraciones") {
          this.$bvModal.show("modal-assessment");
        } else {
          console.log();
        }
      });
    },
    hideModal(modal) {
      this.$nextTick(() => {
        this.$bvModal.hide(modal);
      });
    }
  }
};
</script>
