<template>
  <div v-show="permissionView" class="container-fluid">
    <b-row>
      <b-col lg="12" class="my-1">
        <h4>Comentarios:</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <FORMCOMMENT
          :comment="comment"
          :action="action"
          @store-comment-update="storeCommentUpdate"
      /></b-col>
    </b-row>
    <b-row v-if="list_comments.length > 0">
      <SHOWCOMMENT
        v-for="(c, index) in list_comments"
        :key="index"
        :comment="c"
        @comment-update-w="updateCommentW"
      />
    </b-row>
    <b-row v-else>
      <b-col class="text-center">
        <b-card>
          <h5>En el perfil de empleado no tiene comentarios</h5>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EMPLOYEE_PROFILE } from "@/services/store/employee.module.js";
import SHOWCOMMENT from "@/components/employees-module/profiles/comments/show-comment";
import FORMCOMMENT from "@/components/employees-module/profiles/comments/form-comment";
var _ = require("lodash");

export default {
  props: ["profile"],
  data() {
    return {
      rolesCreate: [1, 2],
      comment: "",
      action: "create"
    };
  },
  components: {
    SHOWCOMMENT,
    FORMCOMMENT
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getEmployeeProfileComments",
      "getCommentProfile"
    ]),
    permissionView() {
      return this.rolesCreate.indexOf(this.currentUser.attributes.rol_id) != -1;
    },
    list_comments() {
      let items = [];
      items = _.orderBy(this.profile.relationships.comments, ["id"], ["desc"]);
      return items;
    }
  },
  beforeMount() {},
  methods: {
    updateCommentW() {
      this.action = "edit";
      this.comment = this.getCommentProfile;
    },
    showModal() {
      this.$bvModal.show("modal-comment");
    },
    storeCommentUpdate(value) {
      this.$nextTick(() => {
        this.$bvModal.hide(value);
        this.action = "create";
        this.comment = "";
        this.$store.dispatch(EMPLOYEE_PROFILE, this.profile.id);
      });
    }
  }
};
</script>
