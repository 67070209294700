<template>
  <div class="col-12">
    <b-card
      title="Perfil del Empleado"
      class="card card-custom card-stretch gutter-b"
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-row class="align-items-center">
            <b-avatar
              :text="name_letter"
              :src="getImage()"
              size="10rem"
              title="Foto de perfil"
              class="ml-5 mb-10"
            />
            <input
              ref="image-upload-input"
              class="image-upload-input"
              type="file"
              accept=".png, .jpg, .jpeg"
              @change="handleClick"
            />
            <b-button
              v-if="isMe && !loading"
              variant="primary"
              class="mb-10 ml-20 d-flex"
              @click="handleUpload"
              size="sm"
              >Cambiar imagen</b-button
            >
            <div v-if="loading" class="spinner mb-10 ml-20 d-flex" />
          </b-row>

          <b-form-group
            label="Nombre: "
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ name }}</label>
          </b-form-group>
          <b-form-group
            label="Email: "
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ email }}</label>
          </b-form-group>
          <b-form-group
            label="Rol:"
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <label label-cols-sm="7">{{ rol }}</label>
          </b-form-group>
          <b-form-group
            label="Departamentos:"
            label-for="initial-sort-select"
            label-cols-sm="5"
            label-align-sm="light"
            label-size="sm"
            class="mb-0"
          >
            <p label-cols-sm="12" v-for="(d, i) in jobs" :key="i">
              {{ d.name }}
            </p>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group>
            <CONTENTEMPLOYEES :profile="this.getEmployeeProfile" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CONTENTEMPLOYEES from "@/components/employees-module/profiles/widget-content";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import { EMPLOYEE_PROFILE } from "@/services/store/employee.module.js";
import { SET_USER_PICTURE } from "@/services/store/auth.module.js";

export default {
  components: {
    CONTENTEMPLOYEES
  },
  computed: {
    ...mapGetters(["getEmployeeProfile", "currentUser"]),
    name() {
      return this.getEmployeeProfile.attributes.name;
    },
    email() {
      return this.getEmployeeProfile.attributes.email;
    },
    rol() {
      return this.getEmployeeProfile.attributes.type;
    },
    jobs() {
      return this.getEmployeeProfile.relationships.jobs;
    },
    name_letter() {
      return this.getEmployeeProfile.attributes.name?.slice("")?.[0];
    },
    isMe() {
      return this.getEmployeeProfile.id == this.currentUser.id;
    }
  },
  beforeMount() {},
  data() {
    return {
      image_url: null,
      loading: false
    };
  },

  methods: {
    getImage() {
      return (
        this.image_url ??
        this.getEmployeeProfile.relationships.image?.public_url ??
        ""
      );
    },
    handleUpload() {
      this.$refs["image-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.loading = true;
      this.s3upload(rawFile).then(res => {
        this.$store
          .dispatch(EMPLOYEE_PROFILE, this.getEmployeeProfile.id)
          .then(() => this.$store.commit(SET_USER_PICTURE, res))
          .then(() => {
            this.loading = false;
          })
          .catch(e => {
            console.log(e);
            this.loading = false;
          });
      });
    },
    async s3upload(file) {
      this.load = true;
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachment_type", "App\\Models\\Message");

        ApiService.post("api/v1/attachment/profile", formData)
          .then(({ data }) => {
            //context.commit(SET_AUTH, data);
            this.load = false;
            resolve(data?.data[0] ?? {});
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
            this.load = false;
            reject(response);
          });
      });
    }
  },
  mounted() {}
};
</script>
<style scoped>
.image-upload-input {
  display: none;
  z-index: -9999;
}
</style>
