<template>
  <perfect-scrollbar style="max-height: calc(80vh - 145px); position: relative">
    <div id="contact-scroller" ref="content">
      <b-card no-body>
        <b-button-group>
          <b-button v-b-toggle.collapse-1>Canales</b-button>
          <b-button @click="addChat('public')"
            ><b-icon icon="person-plus" left></b-icon
          ></b-button>
        </b-button-group>

        <b-collapse visible id="collapse-1">
          <b-list-group flush>
            <div v-for="(d, i) in listChannel" :key="i">
              <b-list-group-item
                style="cursor: pointer"
                @click="selectChat(d)"
                v-if="isActive(d)"
              >
                <span class="symbol symbol-35">
                  <b-avatar
                    :variant="
                      d.attributes.type != 'public' ? 'primary' : 'secondary'
                    "
                    class="mr-3"
                  >
                    <b-icon
                      :icon="
                        d.attributes.type != 'public' ? 'lock-fill' : 'eye-fill'
                      "
                    ></b-icon>
                  </b-avatar>
                  <small
                    v-if="isMessage(d) > 0"
                    class="symbol-badge-white bg-danger"
                  >
                    <b-badge class="" pill variant="danger"
                      >{{ isMessage(d) }}
                    </b-badge>
                  </small>
                </span>
                <span class="mr-auto">
                  {{ d.attributes.name }}
                </span>

                <div class="float-right">
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-ver"></i>
                    </template>
                    <b-dropdown-item
                      v-if="d.attributes.created_by == currentUser.id"
                      @click="editChat(d.attributes.type, d)"
                      ><span class="navi-icon">
                        <b-icon icon="pencil" variant="dark"></b-icon>
                      </span>
                      <span class="mr-2 pl-3">Editar</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item @click="removeChat(d)">
                      <span class="navi-icon">
                        <b-icon icon="dash-circle" variant="danger"></b-icon>
                      </span>
                      <span class="mr-2 pl-3">Eliminar</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="removeChat(d)">
                      <span class="navi-icon">
                        <b-icon icon="box-arrow-up" variant="danger"></b-icon>
                      </span>
                      <span class="mr-2 pl-3">Abandonar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-collapse>
      </b-card>
      <b-card class="h-50" no-body>
        <b-button-group>
          <b-button sm="10" lg="9" v-b-toggle.collapse-2
            >Mensajes Directos</b-button
          >
          <b-button sm="2" lg="3" @click="addChat('direct')"
            ><b-icon icon="plus-circle" left></b-icon
          ></b-button>
        </b-button-group>
        <b-collapse visible id="collapse-2">
          <b-list-group flush>
            <div v-for="(e, i) in listDirect" :key="i">
              <b-list-group-item
                style="cursor: pointer"
                class="d-flex align-items-center"
                @click="selectChat(e)"
                v-if="isActive(e)"
              >
                <span class="symbol symbol-35">
                  <b-avatar
                    variant="info"
                    :text="_getNameDirect(e.attributes)"
                    :src="_getPictureDirectShow(e.relationships)"
                    class="mr-3"
                  ></b-avatar>
                  <small
                    v-if="isMessage(e) > 0"
                    class="symbol-badge-white bg-danger"
                  >
                    <b-badge class="" pill variant="danger"
                      >{{ isMessage(e) }}
                    </b-badge>
                  </small>
                </span>

                <span class="mr-auto">
                  {{ _getNameDirectShow(e.attributes) }}
                  <!-- <small v-if="isMessage(e) > 0">
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="danger"
                    ></b-icon>
                  </small> -->
                </span>
                <!-- <div class="float-right">
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-ver"></i>
                    </template>

                    <b-dropdown-item @click="removeChat(e)">
                      <span class="navi-icon">
                        <b-icon icon="dash-circle" variant="danger"></b-icon>
                      </span>
                      <span class="mr-2 pl-3">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div> -->
              </b-list-group-item>
            </div>
          </b-list-group>
        </b-collapse>
      </b-card>

      <b-modal
        v-model="showModalAddChat"
        id="modal-chat"
        size="lg"
        hide-footer
        :title="action === 'edit' ? 'Editar Chat' : 'Nuevo Chat'"
      >
        <div class="d-block">
          <FORMADDCHAT
            :chat="chat"
            :action="action"
            :type="type"
            @close-modal-add="closeModalAddChat"
          />
        </div>
      </b-modal>
    </div>
  </perfect-scrollbar>
</template>
<script>
import { mapGetters } from "vuex";
import {
  LIST_CHATS,
  DELETE_CHAT,
  SELECT_CHAT
} from "@/services/store/chat.module";
import FORMADDCHAT from "@/components/chats-module/form/form-add-chat";
var _ = require("lodash");
export default {
  data() {
    return {
      page: 0,
      chat: {},
      action: "create",
      type: "",
      type_direct: [{ name: "direct", label: "Directo" }],
      type_channel: [
        { name: "public", label: "Publico" },
        { name: "private", label: "Privado" }
      ],

      showModalAddChat: false
    };
  },
  computed: {
    ...mapGetters([
      "getEmployeesAll",
      "getDepartments",
      "getListChats",
      "getChat",
      "currentUser"
    ]),
    listChannel() {
      return _.filter(this.getListChats, c => {
        return c.attributes.type != "direct";
      });
    },
    listDirect() {
      return _.filter(this.getListChats, c => {
        return c.attributes.type == "direct";
      });
    }
  },
  components: {
    FORMADDCHAT
  },
  mounted() {
    this.sockets.subscribe("MESSAGE_UPDATE", data => {
      this.$store.commit("updateMessage", data);
    });

    this.sockets.subscribe("CHAT_LEAVE", data => {
      this.$store.dispatch(LIST_CHATS);
      if (this.getChat.id == data.chat) {
        this.$store.commit("leaveChatUser", data);
      }
    });
  },

  beforeMount() {
    this.$store.dispatch(LIST_CHATS);
  },
  methods: {
    isMessage(d) {
      let res = 0;
      let user = this.currentUser;
      let chat = _.find(d.relationships.chat_user, function(cu) {
        return cu.pivot.user_id === user.id;
      });
      if (chat) {
        res = chat.pivot.viewed === 0 ? chat.pivot.unread_messages : 0;
      }
      return res;
    },
    _getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },

    _getNameDirect(data) {
      let names = data.name.split("-");
      let name = this.currentUser.id === data.created_by ? names[0] : names[1];
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },

    _getNameDirectShow(data) {
      let names = data.name.split("-");
      return this.currentUser.id === data.created_by ? names[0] : names[1];
    },

    _getPictureDirectShow(data) {
      console.log(data);
      const peerEmployee = data.employees?.filter(
        _e => _e.id != this.currentUser.id
      );
      return peerEmployee?.[0]?.relationships?.image?.public_url;
    },

    selectChat(chat) {
      this.$emit("select-chat", chat);
    },

    addChat(type) {
      this.chat = {};
      this.action = "create";
      this.type = type;
      this.showModalAddChat = true;
    },
    closeModalAddChat(chat) {
      if (chat != null) {
        if ("/chats/" + btoa(chat.id) != this.$route.path) {
          this.$router.push({
            name: `chats`,
            params: { chat: btoa(chat.id) }
          });
        }
      }
      this.$store.dispatch(SELECT_CHAT, atob(this.$route.params.chat));
      this.showModalAddChat = false;
    },
    editChat(type, chat) {
      this.chat = chat;
      this.action = "edit";
      this.type = type;
      this.showModalAddChat = true;
      this.chat.employees_select = _.map(
        this.chat.relationships.employees,
        d => {
          return { id: d.id, name: d.attributes.name };
        }
      );
    },
    removeChat(chat) {
      let user = this.currentUser.id;
      this.$store.dispatch(DELETE_CHAT, chat).then(response => {
        this.$store.commit("deleteChatUser", response.data.data);
        this.$socket.emit("LEAVE_CHAT", { chat: chat.id, user: user });
      });
      this.$store.dispatch(LIST_CHATS);
    },

    // removeChatDirect(chat) {
    //   this.$store.dispatch(DELETE_CHAT, chat).then(response => {
    //     this.$store.commit("deleteChatUserDirect", response.data.data);
    //   });
    //   this.$store.dispatch(LIST_CHATS);
    // },

    isActive(d) {
      let res = false;
      let user = this.currentUser;
      let chat = _.find(d.relationships.chat_user, function(cu) {
        return cu.pivot.user_id === user.id;
      });
      if (chat) {
        res = chat.pivot.state === 1 ? true : false;
      }
      return res;
    }
  }
};
</script>
