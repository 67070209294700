import ApiService from "@/services/api.service";

// action types
export const LIST_SEGMENTS = "listSegments";
export const SHOW_SEGMENT = "showSegment";
export const STORE_SEGMENT = "storeSegment";
export const UPDATE_SEGMENT = "updateSegment";
export const DELETE_SEGMENT = "deleteSegment";
export const ADD_SEGMENT = "addSegment";

// mutation types
export const SET_SEGMENTS = "setSegments";
export const SET_ERROR = "setError";

// getter types
export const GET_SEGMENTS = "getSegments";

const state = {
  errors: null,
  segments: []
};

const getters = {
  [GET_SEGMENTS](state) {
    return state.segments;
  }
};

const actions = {
  [LIST_SEGMENTS](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/segments`)
        .then(response => {
          context.commit(SET_SEGMENTS, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_SEGMENT](context, segment) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/segments`, segment)
        .then(response => {
          context.commit(ADD_SEGMENT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(true);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_SEGMENTS](state, segments) {
    state.segments = segments;
  },
  [ADD_SEGMENT](state, segment) {
    let segments = [...state.segments];
    segments.push(segment);
    state.segments = segments;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
