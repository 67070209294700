<template>
  <div class="mb-3">
    <b-form>
      <b-form-group lg="12" id="input-group-1" label="" label-for="input-1">
        <b-form-textarea
          id="input-1"
          v-model="$v.form.comment.$model"
          :state="validateState('comment')"
          placeholder="Escriba el comentario sobre el empleado"
          type="text"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-form-invalid-feedback id="input-1-live-feedback">
          El texto es requerido
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        type="button"
        ref="btnSubmit"
        variant="outline-primary"
        @click="onSubmit"
        >Guardar</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  STORE_COMMENT_PROFILE,
  UPDATE_COMMENT_PROFILE
} from "@/services/store/comment.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  props: ["comment", "action"],
  computed: {
    ...mapGetters([
      "getBreadcrumbSegment",
      "getBreadcrumbParent",
      "getBreadcrumbPath",
      "getBreadcrumbFolder",
      "currentUser",
      "getEmployeeProfile"
    ])
  },
  data() {
    return {
      form: {
        id: this.action === "edit" ? this.comment.id : null,
        comment: this.action === "edit" ? this.comment.attributes.comment : "",
        commentable_id:
          this.action === "edit" ? this.comment.attributes.commentable_id : ""
        // user_id: this.action === "edit" ? this.comment.attributes.user_id : ""
      }
    };
  },
  validations: {
    form: {
      comment: {
        required
      }
    }
  },
  methods: {
    validateState(comment) {
      const { $dirty, $error } = this.$v.form[comment];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.form.comment = this.$v.form.comment.$model;
      this.form.commentable_id = this.getEmployeeProfile.id;
      // this.form.user_id = this.currentUser.id;

      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.action === `edit`) {
        await this.put();
      } else {
        await this.store();
      }
      // this.$v.form.comment = "";
      this.$v.form.comment.$model = "";
      this.$v.form.$reset();
    },
    async store() {
      const submitButton = this.$refs["btnSubmit"];
      await this.$store
        .dispatch(STORE_COMMENT_PROFILE, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );

            Swal.fire("Ok!", "Se creo el comentario exitosamente", "success");

            this.$emit("store-comment-update", "modal-comment");
          }
        })
        .catch(e => {
          console.log(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    },
    async put() {
      const submitButton = this.$refs["btnSubmit"];
      await this.$store
        .dispatch(UPDATE_COMMENT_PROFILE, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            // this.$v.form.comment.$model = "";
            // this.form.id
            Swal.fire("Ok!", "Se modifico la carpeta exitosamente", "success");

            this.$emit("store-comment-update", `modal-comment-${this.form.id}`);
          }
        })
        .catch(e => {
          console.log(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  }
};
</script>
