import { render, staticRenderFns } from "./show-list-employees-chat.vue?vue&type=template&id=f211a1a4&"
import script from "./show-list-employees-chat.vue?vue&type=script&lang=js&"
export * from "./show-list-employees-chat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports