<template>
  <div>
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
          <div class="col-xl-12 col-xxl-12">
            <div
              class="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h6>
                <strong>Nombre reporte: </strong>
                {{ this.department.attributes.name }}
              </h6>
              <p>Descripcion: {{ this.department.attributes.description }}</p>
              <b-form-group
                id="input-group-1"
                label="Titulo del documento"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  type="text"
                  required
                  placeholder="Titulo del documento"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  El nombre es requerido
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button
                v-if="!editReport"
                variant="success"
                size="sm"
                class="float-light"
                @click="editReport = true"
              >
                <b-icon icon="pen" font-scale="1"></b-icon>
                Editar plantilla</b-button
              >
              <b-button
                v-if="!editReport"
                variant="danger"
                size="sm"
                class="float-right ml-3"
                @click="deleteReport"
              >
                Eliminar plantilla</b-button
              >
              <br />
              <VUEEXCELEDITOR
                :editReport="editReport"
                :hideButton="editReport"
                :dataTable="{ rows: tableData, columns: tableHeader }"
                @change-data="changeData"
                @template-report="saveTemplateReport"
                :key="componentVueExcelEditorKey"
                :exportFile="true"
                :importFile="true"
                :onDataChange="onDataChange"
              />
              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>
              <div class="text-right mt-3">
                <b-button
                  v-if="!editReport"
                  ref="btnSubmit"
                  @click="saveReport"
                  variant="success"
                  size="sm"
                >
                  Añadir
                </b-button>

                <b-button
                  class="ml-3"
                  variant="secondary"
                  size="sm"
                  @click="closeModal()"
                >
                  Cerrar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VUEEXCELEDITOR from "@/components/departments-module/reports/vue-excel-editor";

import { STORE_INSERT_REPORT } from "@/services/store/report.module";
import {
  SHOW_DEPARTMENT,
  REPORTS_DEPARTMENTS,
  DELETE_DEPARTMENT_REPORT,
  UPDATE_TEMPLATE_REPORT
} from "@/services/store/department.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  components: {
    VUEEXCELEDITOR
  },
  computed: {
    ...mapGetters([
      "getReportsDepartments",
      "getDepartments",
      "getShowDepartment"
    ])
  },
  mixins: [validationMixin],
  props: ["action", "department", "dep"],
  name: "UploadExcel",
  created() {
    this.tableData = JSON.parse(this.form.data);
    this.tableHeader = JSON.parse(this.form.columns);
  },
  data() {
    return {
      componentVueExcelEditorKey: 0,
      editReport: false,
      tableData: [],
      tableHeader: [],
      header_edit: true,
      form: {
        id: this.action === `edit` ? "this.department.id" : "",
        department: this.action === `edit` ? "this.department.id" : "",
        name: "",
        data: this.action === `edit` ? this.department.attributes.data : "",
        columns:
          this.action === `edit` ? this.department.attributes.columns : ""
      },
      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  methods: {
    onDataChange({ results, header }) {
      this.tableData = results;
      this.tableHeader = header;
    },
    closeModal() {
      if (this.editReport) {
        Swal.fire({
          title: "Estas seguro de cerrar el modal?",
          text: `No se guardara la informacion, guarde los cambios antes de cerrar`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, Cerrar",
          cancelButtonText: "Atras"
        }).then(result => {
          if (result.isConfirmed) {
            this.$emit("close-modal-info", false);
          }
        });
      } else {
        this.$emit("close-modal-info", false);
      }
    },
    changeData(data) {
      this.tableHeader = data.columns;
      this.tableData = data.rows;
      this.componentVueExcelEditorKey += 1;
    },

    saveColumn(column) {
      this.tableHeader.push(column);
    },

    saveTemplateReport(data) {
      this.changeData(data);
      let colName = data.columns;
      this.$store
        .dispatch(UPDATE_TEMPLATE_REPORT, {
          report: this.department.id,
          department: this.dep.id,
          columns: colName,
          data: this.tableData
        })
        .then(response => {
          let data = response.data.data;
          this.tableHeader = JSON.parse(data.attributes.columns);
          this.tableData = JSON.parse(data.attributes.data);

          this.editReport = false;
        });
      this.$store.dispatch(SHOW_DEPARTMENT, this.dep.id);
      this.$store.dispatch(REPORTS_DEPARTMENTS);
    },
    deleteReport() {
      var text;
      text = "¿Quieres eliminar la plantilla de reporte?";
      Swal.fire({
        title: "Estas seguro?",
        text: `${text}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_DEPARTMENT_REPORT, {
            report: this.department.id,
            department: this.dep.id
          });
          this.$store.dispatch(SHOW_DEPARTMENT, this.dep.id);
          this.$store.dispatch(REPORTS_DEPARTMENTS);
          this.$emit("close-modal-info", false);
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    noOrdenar() {
      return null;
    },
    saveReport() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let colName = this.tableHeader;

      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.form.name =
        this.department.attributes.name + " - " + this.$v.form.name.$model;
      this.form.type = "xlsx";
      this.form.department = this.getShowDepartment.id;
      this.form.report = this.department.id;
      this.form.columns = colName;
      this.form.data = this.tableData;
      this.$store
        .dispatch(STORE_INSERT_REPORT, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se creo el reporte exitosamente", "success");
            this.$store.dispatch(SHOW_DEPARTMENT, this.getShowDepartment.id);
            this.$store.dispatch(REPORTS_DEPARTMENTS);
            this.$emit("close-modal-info", false);
          }
        })
        .catch(e => {
          Swal.fire("", "Por favor! intente de nuevo", "error");
          console.log(e);
        });
    },
    newCol() {
      this.header_edit = !this.header_edit;
    },
    newRecord() {
      const rec = {};
      this.tableData.push(rec);
    }
  }
};
</script>
