<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/services/store/config.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import { INITIAL_STATE_FORMATIONS } from "@/services/store/formation.module.js";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "PortalEmpleados",
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    ...mapGetters([
      "getEmployeesAll",
      "getDepartments",
      "getListChats",
      "getChat",
      "currentUser"
    ])
  },
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods: {
    logout() {
      const payload = {
        type_work: this.currentUser.attributes.type_work
      };

      this.$store.dispatch(LOGOUT, payload).then(() => {
        this.$socket.emit("LOGOUT_APP");
        this.sockets.unsubscribe("MESSAGE_SEND");
        this.$store.commit(INITIAL_STATE_FORMATIONS);
      });

      Swal.fire({
        icon: "info",
        title: "Sesión Expirada.",
        text: "Has acumulado 3 horas de inactividad.",
        closeModal: false
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.replace({ name: "login" });
        }
      });
    }
  },
  watch: {
    isIdle() {
      if (this.$route.name !== "login") {
        if (this.$store.state.idleVue.isIdle) {
          this.logout();
        }
      }
    }
  }
};
</script>
