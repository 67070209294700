// action types
export const APPEND_BREADCRUM = "appendBreadcrumb";

export const SET_BREADCRUMB_SEGMENT = "setBreadCrumbSegment";
export const SET_BREADCRUMB_PARENT = "setBreadCrumbParent";
export const SET_BREADCRUMB_PATH = "setBreadCrumbPath";
export const PURGE_BREADCRUMB_FOLDER_AND_FORMATION =
  "purgeBreadcrumbFolderAndFormation";
export const SET_BREADCRUMB_DOCTYPE = "setBreadCrumbDoctype";
export const SET_BREADCRUMB_DOCUMENT = "setBreadCrumbDocument";

export const SET_BREADCRUMB_FOLDER = "setBreadCrumbFolder";
export const SET_BREADCRUMB_FORMATION = "setBreadCrumbFormation";

// mutation types
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";
export const PURGE_BREADCRUMB = "purgeBreadcrumb";
export const SET_SEGMENT_ID = "setSegmentID";
var _ = require("lodash");

export default {
  state: {
    breadcrumbs: [],
    segment: {},
    doctype: {},
    folder: {},
    formation: {},
    document: {},
    parent: {},
    path: "",
    segmentID: ""
  },
  getters: {
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    getBreadcrumbSegment(state) {
      return state.segment;
    },
    getBreadcrumbDoctype(state) {
      return state.doctype;
    },
    getBreadcrumbFolder(state) {
      return state.folder;
    },
    getBreadcrumbFormation(state) {
      return state.formation;
    },
    getBreadcrumbDocument(state) {
      return state.document;
    },
    getBreadcrumbParent(state) {
      return state.parent;
    },
    getBreadcrumbPath(state) {
      return state.path;
    },
    getItems(state) {
      return state.items;
    }
  },
  actions: {
    [SET_BREADCRUMB](context, payload) {
      context.commit(SET_BREADCRUMB, payload);
    },
    [SET_BREADCRUMB_SEGMENT](context, segment) {
      context.commit(SET_BREADCRUMB_SEGMENT, segment);
    },
    [SET_BREADCRUMB_DOCTYPE](context, doctype) {
      context.commit(SET_BREADCRUMB_DOCTYPE, doctype);
    },
    [SET_BREADCRUMB_FOLDER](context, folder) {
      context.commit(SET_BREADCRUMB_FOLDER, folder);
    },
    [SET_BREADCRUMB_FORMATION](context, formation) {
      context.commit(SET_BREADCRUMB_FORMATION, formation);
    },
    [SET_BREADCRUMB_DOCUMENT](context, document) {
      context.commit(SET_BREADCRUMB_DOCUMENT, document);
    },
    [SET_BREADCRUMB_PATH](context, path) {
      context.commit(SET_BREADCRUMB_PATH, path);
    },

    [ADD_BREADCRUMB](context, payload) {
      if (payload.name === `formations`) {
        context.commit(PURGE_BREADCRUMB);
      }
      context.commit(APPEND_BREADCRUM, payload);
    },
    [PURGE_BREADCRUMB_FOLDER_AND_FORMATION](context) {
      context.commit(PURGE_BREADCRUMB_FOLDER_AND_FORMATION);
    }
  },
  mutations: {
    [APPEND_BREADCRUM](state, payload) {
      if (payload.name !== "formations") {
        state.breadcrumbs.push({
          segment: payload.params.segment,
          folder: {
            id: "",
            children: []
          }
        });

        if (typeof payload.params.parent !== "undefined") {
          let found = _.find(state.breadcrumbs.folder, {
            id: payload.params.parent
          });
          found.children.push(payload);
        } else {
          let breadcrumbs = _.find(state.breadcrumbs, {
            segment: state.segment.name
          });
          (breadcrumbs.folder.id = payload.params.id),
            breadcrumbs.folder.children.push({
              folder: { id: payload.params.id, children: [] }
            });
        }
      }
    },
    [SET_BREADCRUMB](state, payload) {
      state.breadcrumbs = payload;
    },
    [SET_BREADCRUMB_SEGMENT](state, payload) {
      state.segment = payload;
    },
    [SET_BREADCRUMB_DOCTYPE](state, payload) {
      state.doctype = payload;
    },
    [SET_BREADCRUMB_FOLDER](state, payload) {
      state.folder = payload;
    },
    [SET_BREADCRUMB_FORMATION](state, payload) {
      state.formation = payload;
    },
    [SET_BREADCRUMB_DOCUMENT](state, payload) {
      state.document = payload;
    },
    [SET_BREADCRUMB_PATH](state, payload) {
      state.path = payload;
    },
    [PURGE_BREADCRUMB_FOLDER_AND_FORMATION](state) {
      state.folder = {};
      state.formation = {};
      state.path = "";
    },
    [PURGE_BREADCRUMB](state) {
      state.breadcrumbs = [];
    },
    [SET_SEGMENT_ID](state, id) {
      state.segmentID = id;
    }
  }
};
