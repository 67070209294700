<template>
  <b-row>
    <b-modal
      id="modal-folder"
      ref="modal-folder"
      hide-footer
      title="Nueva Formación"
    >
      <div class="d-block text-center">
        <FOLDERFORM @store-folder="hideModal" :action="`create`" />
      </div>
    </b-modal>

    <b-modal
      id="modal-formation-doc"
      ref="modal-formation-doc"
      hide-footer
      :title="
        `Nuevo Documento para la formación: ${this.getCurrentlyFormationSelected.name}`
      "
    >
      <div class="d-block text-center">
        <FORMATIONFORM
          @store-formation="hideModal"
          :action="`create`"
          :segmentID="1"
          type="doc"
        />
      </div>
    </b-modal>

    <b-modal
      id="modal-formation-video"
      ref="modal-formation-video"
      hide-footer
      :title="
        `Nuevo Video para la formación: ${this.getCurrentlyFormationSelected.name}`
      "
    >
      <div class="d-block text-center">
        <FORMATIONFORM
          @store-formation="hideModal"
          :action="`create`"
          :segmentID="2"
          type="video"
        />
      </div>
    </b-modal>

    <b-modal
      id="modal-test"
      ref="modal-test"
      size="xl"
      hide-footer
      :title="
        `Nuevo Test para la formación: ${this.getCurrentlyFormationSelected.name}`
      "
    >
      <div class="d-block text-center">
        <TESTFORM
          @store-formation="hideModal"
          :action="`create`"
          :segmentID="3"
        />
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
import FOLDERFORM from "@/components/formations-module/forms/folder";
import FORMATIONFORM from "@/components/formations-module/forms/formation";
import TESTFORM from "@/components/formations-module/forms/test";

export default {
  computed: {
    ...mapGetters(["getBreadcrumbSegment", "getCurrentlyFormationSelected"])
  },
  components: {
    FOLDERFORM,
    FORMATIONFORM,
    TESTFORM
  },
  methods: {
    hideModal(modal) {
      this.$refs[modal].hide();
    }
  }
};
</script>
