<template>
  <div>
    <b-form>
      <b-form-group
        id="input-group-1"
        label="Nombre de la formación"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          type="text"
          required
          placeholder="Nombre de la formación"
        />
      </b-form-group>

      <b-button
        type="button"
        ref="btnSubmit"
        variant="outline-primary"
        @click="onSubmit"
        >Guardar</b-button
      >
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  props: ["folder", "action"],
  data() {
    return {
      form: {
        id: this.action === `edit` ? this.folder.id : null,
        name: this.action === `edit` ? this.folder.attributes.name : ""
      },
      codeStatus: [200, 201]
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.form.name = this.$v.form.name.$model;
      const submitButton = this.$refs["btnSubmit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.action === `edit`) {
        await this.update();
      } else {
        await this.store();
      }
    },
    async store() {
      const submitButton = this.$refs["btnSubmit"];
      await this.$store
        .dispatch("storeFolder", this.form)
        .then(response => {
          if (this.codeStatus.indexOf(response.status) != -1) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se creo la formación exitosamente", "success");
            this.$emit("store-folder", "modal-folder");
          }
        })
        .catch(e => {
          console.error(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    },
    async update() {
      const submitButton = this.$refs["btnSubmit"];
      await this.$store
        .dispatch("updateFolder", this.form)
        .then(response => {
          if (this.codeStatus.indexOf(response.status) != -1) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire(
              "Ok!",
              "Se modifico la formación exitosamente",
              "success"
            );
            this.$emit("update-folder", "modal-folder");
          }
        })
        .catch(e => {
          console.error(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  },
  mounted() {}
};
</script>
