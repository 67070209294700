<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <Header></Header>
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <div class="d-lg-flex flex-row-fluid">
                <KTAside v-if="asideEnabled"></KTAside>
                <div class="content-wrapper flex-row-fluid">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import Header from "@/components/header/header.vue";
import HtmlClass from "@/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/services/store/htmlclass.module.js";
import { LIST_VACATIONS_REQUEST } from "@/services/store/holiday.module.js";
import { SHOW_PROFILE } from "@/services/store/profile.module.js";
import push from "push.js";
import { LIST_CHATS } from "@/services/store/chat.module";
import { ACTIVITY } from "@/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "Layout",
  components: {
    KTAside,
    Header,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      msg: "El Login es por?"
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getStatus",
      "currentUser",
      "getListChats"
    ]),
    havePermission() {
      return this.currentUser.attributes.permission_add ? true : false;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
    toolbarDisplay() {
      return true;
    }
  },
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    this.$store.dispatch(LIST_CHATS);
    HtmlClass.init(this.layoutConfig());
    window.onload = function() {
      if (!push.Permission.GRANTED) {
        push.Permission.request();
      }
    };
  },
  mounted() {
    this.sockets.subscribe("NOTIFIACION_PARA_APROBAR", data => {
      if (this.currentUser.attributes.rol_id === 2 && this.havePermission) {
        let body = "Nombre:" + " " + data.relationships.user.attributes.name;
        let title = "Tienes una nueva solicitud de vacación";
        this.makeToast("primary", body, title);
        push.create("Nueva solicitud de vacación", {
          body: `Nombre: ${data.relationships.user.attributes.name}`,
          icon: "/media/svg/icons/Communication/Chat1.svg",
          timeout: 10000,
          vibrate: [100, 100, 100],
          onClick: function() {
            this.$router.push({ name: "holidays" });
          }
        });
      }
      this.$store.dispatch(LIST_VACATIONS_REQUEST);
    });

    this.sockets.subscribe("NOTIFICACION_VACACION", data => {
      if (this.currentUser.id === data.attributes.profile_id) {
        let body = "Estado:" + " " + data.relationships.status.attributes.name;
        let title = "Tienes una respuesta a tu solicitud de vacación";
        this.makeToast("success", body, title);
        push.create("Status de la solicitud", {
          body: `Estado: ${data.relationships.status.attributes.name}`,
          icon: "/media/svg/icons/Communication/Chat1.svg",
          timeout: 10000,
          vibrate: [100, 100, 100],
          onClick: function() {
            this.$router.push({ name: "holidays" });
          }
        });
        this.$store.dispatch(SHOW_PROFILE, this.currentUser.id);
      }
    });

    if (
      !this.isAuthenticated ||
      typeof this.currentUser.attributes === "undefined"
    ) {
      this.$router.push({ name: "login" });
    }
    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      });
    },
    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    },
    setActivity(value) {
      this.$store
        .dispatch(ACTIVITY, value)
        .then(response => {
          if (response === true) {
            Swal.close();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    }
  }
};
</script>
