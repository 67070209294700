<template>
  <b-modal
    id="modal-show-task"
    size="xl"
    :title="form.title"
    @hide="handleHide"
    scrollable
  >
    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Titulo</label>
      </b-col>
      <b-col sm="9">
        <b-form-group id="input-group-1" label-for="input-1">
          <b-form-input
            v-if="permissions"
            id="input-1"
            v-model="$v.form.title.$model"
            :state="validateState('title')"
            type="text"
            required
            placeholder="Titulo de la tarea"
          />
          <b-form-input
            v-else
            id="input-1"
            v-model="$v.form.title.$model"
            :state="validateState('title')"
            type="text"
            required
            disabled
            placeholder="Titulo de la tarea"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            El titulo de la tarea es requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Creador de la tarea</label>
      </b-col>
      <b-col sm="9">
        <b-form-input disabled v-model="form.owner" />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Responsable</label>
      </b-col>
      <b-col sm="9" v-if="permissions && showInThisRoute">
        <multiselect
          id="input-2"
          :options="employees"
          v-model="form.responsible"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccione el responsable de la tarea"
          label="text"
          track-by="text"
          :state="validateState('responsible')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} seleccionado</span
            ></template
          >
        </multiselect>
        <span style="color: red" v-if="this.errors.employee">
          Debes seleccionar un empleado
        </span>
      </b-col>
      <b-col sm="9" v-else>
        <b-form-input disabled v-model="form.responsible.text" />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Cliente</label>
      </b-col>
      <b-col sm="9" v-if="permissions && showInThisRoute">
        <multiselect
          id="input-2"
          :options="customers"
          v-model="form.customer"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccione el cliente"
          label="text"
          track-by="text"
          :state="validateState('customer')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} seleccionado</span
            ></template
          >
        </multiselect>
        <span style="color: red" v-if="this.errors.customer">
          Debes seleccionar un cliente
        </span>
      </b-col>
      <b-col sm="9" v-else>
        <b-form-input disabled v-model="form.customer.text" />
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Fecha de entrega</label>
      </b-col>
      <b-col sm="9" v-if="permissions">
        <b-form-datepicker
          id="datepicker-sm"
          size="sm"
          locale="es"
          class="mb-2"
          v-model="form.date_end_utc"
        ></b-form-datepicker>
      </b-col>
      <b-col sm="9" v-else>
        <b-form-datepicker
          id="datepicker-sm"
          size="sm"
          locale="es"
          class="mb-2"
          disabled
          v-model="form.date_end_utc"
        ></b-form-datepicker>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="title">Descripcion</label>
      </b-col>
      <b-col sm="9">
        <b-form-group id="input-group-2" label-for="input-2">
          <b-form-textarea
            v-if="permissions"
            id="textarea-no-resize"
            placeholder="Descripción"
            rows="3"
            no-resize
            v-model="$v.form.description.$model"
            :state="validateState('description')"
          />
          <b-form-textarea
            v-else
            id="textarea-no-resize"
            placeholder="Descripción"
            rows="3"
            disabled
            no-resize
            v-model="$v.form.description.$model"
            :state="validateState('description')"
          />
          <b-form-invalid-feedback id="input-2-live-feedback">
            La descripcion de la tarea es requerida
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-1 mt-1 mb-6" v-if="permissions">
      <b-col cols="auto" class="ml-auto">
        <b-button variant="outline-primary" id="editTask" @click="editTask">
          <b-spinner small type="grow" v-if="this.spinner.edit"></b-spinner>
          Editar
        </b-button>
      </b-col>
    </b-row>
    <hr class="rounded mt-8" />

    <!-- attachments -->
    <b-row class="mt-6" v-if="this.getTask">
      <b-col md="12" class="mb-4">
        <h5 for="comments"><strong>Archivos</strong></h5>
      </b-col>
      <b-col
        cols
        md="4"
        v-for="(element, idx) in this.getTask.relationships.attachments"
        :key="idx"
      >
        <b-card class="card-custom card-stretch gutter-b" style="">
          <b-row>
            <b-col class="d-flex">
              <b-col cols md="11" class="mr-left">
                <table>
                  <tr>
                    <td
                      class="align-content-start"
                      style="cursor:pointer;"
                      @click="showAttachment(element)"
                      width="80%"
                    >
                      {{ element.name }}
                    </td>
                    <td width="20%" v-if="element.type !== `video/mp4`">
                      <b-button @click.prevent="downloadFile(element)">
                        <b-icon icon="file-earmark-arrow-down"></b-icon>
                      </b-button>
                    </td>
                  </tr>
                </table>
              </b-col>

              <b-col cols="auto" class="mr-auto">
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-ver"></i>
                  </template>
                  <div class="navi navi-hover ">
                    <b-dropdown-text tag="div" class="navi-item">
                      <div
                        class="navi-link"
                        style="cursor: pointer"
                        @click="deleteAttachment(element)"
                      >
                        <span class="navi-icon">
                          <b-icon icon="dash-circle" variant="danger"></b-icon>
                        </span>
                        <span class="mr-2 navi-text">Eliminar</span>
                      </div>
                    </b-dropdown-text>
                  </div>
                </b-dropdown>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- end attachments -->

    <!-- send attachments -->
    <b-row class="my-1 mt-6">
      <b-col md="3">
        <label for="title">Adjuntar Archivo(s)</label>
      </b-col>
      <b-col cols="auto" md="7" class="mr-auto">
        <b-form-file
          multiple
          v-model="attachments"
          :file-name-formatter="formatNames"
          placeholder="Subir un archivo"
          drop-placeholder="Arrastrar archivo aquí..."
        />
        <b-progress
          :max="max"
          v-if="load"
          class="mt-4 mb-2"
          style="margin-bottom: 5px"
        >
          <b-progress-bar
            :value="this.loading"
            :label="`${this.loading}%`"
          ></b-progress-bar>
        </b-progress>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          id="sendAttachment"
          @click="sendAttachments"
        >
          <b-spinner
            small
            type="grow"
            v-if="this.spinner.attachment"
          ></b-spinner>
          Subir
        </b-button>
      </b-col>
    </b-row>
    <!-- end send attachments -->

    <hr class="rounded mt-8" />

    <!-- commnets -->
    <b-row class="my-1 mt-8">
      <b-col md="12">
        <h5 for="comments"><strong>Comentarios</strong></h5>
      </b-col>
    </b-row>

    <div v-if="this.getTask">
      <b-row
        class="my-1 mt-7"
        v-for="(comment, idx) in this.getTask.relationships.comments"
        :key="idx"
      >
        <b-col cols md="1">
          <b-avatar :text="_getName(comment.relationships.owner)" />
        </b-col>
        <b-col cols md="11">
          <b-alert show variant="secondary">
            <h6 class="alert-heading">
              <strong>{{ comment.relationships.owner }} </strong> comento:
            </h6>
            <p>
              {{ comment.attributes.comment }}
            </p>
            <hr />
            <p class="mb-0">
              <strong>Publicado: </strong> {{ comment.attributes.created_at }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
    </div>
    <!-- end commnets -->

    <!-- sendComment -->
    <b-row class="my-1 mt-6">
      <b-col cols="auto" md="10" class="mr-auto">
        <b-form-group id="input-group-2" label-for="input-2">
          <b-form-textarea
            id="textarea-no-resize"
            placeholder="Comentario"
            rows="3"
            no-resize
            v-model="comment"
          />
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-primary"
          size="lg"
          id="sendComment"
          @click="sendComment"
        >
          <b-spinner small type="grow" v-if="this.spinner.comment"></b-spinner>
          Enviar
        </b-button>
      </b-col>
    </b-row>
    <!-- end sendComment -->

    <MODALATTACHMENT ref="modalAttachment" />

    <MODALDELETEACTION ref="modalDeleteAction" @close-modal-show="handleHide" />

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          size="sm"
          class="float-left"
          @click="logTask"
          v-if="permissions"
        >
          Log
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="float-right"
          @click="deleteTask"
          v-if="permissions"
        >
          Eliminar
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right mr-5"
          @click="handleHide"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  STORE_COMMENT,
  STORE_ATTACHMENTS,
  PURGE_TASK,
  UPDATE_TASK
} from "@/services/store/task.module";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import MODALATTACHMENT from "@/components/tasks-module/modal-attachment";
import MODALDELETEACTION from "@/components/modal-delete";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
var _ = require("lodash");

export default {
  mixins: [validationMixin],
  components: {
    MODALATTACHMENT,
    MODALDELETEACTION
  },
  data() {
    return {
      modalId: "",
      comment: "",
      attachments: [],
      form: {
        id: "",
        owner: "",
        description: "",
        title: "",
        responsible: {
          value: "",
          text: ""
        },
        customer: "",
        date_end_utc: ""
      },
      spinner: {
        attachment: false,
        comment: false,
        edit: false
      },
      errors: {
        employee: false,
        customer: false
      },
      loading: 0,
      load: false,
      max: 100,
      rolesEdit: [1, 2],
      doNotShowInTheseRoutes: ["showTasksUser", "profileEmployee"]
    };
  },
  validations: {
    form: {
      title: {
        required
      },
      description: {
        required
      },
      responsible: {
        required
      },
      customer: {}
    }
  },
  computed: {
    ...mapGetters([
      "getEmployees",
      "getTask",
      "currentUser",
      "getAllCustomers"
    ]),
    employees() {
      if (typeof this.getEmployees !== `undefined`) {
        let total_employees = this.getEmployees;

        if (this.currentUser.attributes.rol == "empleado") {
          total_employees = total_employees.filter(_e => _e.rol_id == 5);
        }

        const cur_employees = _.map(total_employees, e => {
          return { value: e.id, text: e.name };
        });

        return cur_employees;
      }
      return { value: "", text: "" };
    },
    permissions() {
      return (
        this.rolesEdit.indexOf(this.currentUser.attributes.rol_id) != -1 ||
        (this.currentUser.attributes.rol_id == 3 &&
          this.currentUser.id == this.getTask?.attributes?.created_by)
      );
    },
    showInThisRoute() {
      let show = true;
      if (this.doNotShowInTheseRoutes.indexOf(this.$route.name) != -1) {
        show = false;
        return;
      }
      return show;
    },
    customers() {
      return _.map(this.$store.state.tasks.customers, e => {
        return { value: e.id, text: `${e.dni_nie} (${e.name})` };
      });
    }
  },
  methods: {
    downloadFile(file) {
      window.open(file.public_url);
    },
    async show() {
      await this.setFormData();
      this.$bvModal.show("modal-show-task");
    },
    async setFormData() {
      this.form = {
        id: this.getTask.id,
        owner: this.getTask.relationships.owner.attributes.name,
        description: this.getTask.attributes.description,
        title: this.getTask.attributes.title,
        responsible: {
          value: this.getTask.relationships.responsible.id,
          text: this.getTask.relationships.responsible.attributes.name
        },
        customer: {
          value: this.getTask.relationships.customer?.id,
          text: this.getTask.relationships.customer?.attributes.name
        },
        date_end: this.getTask.attributes.date_end,
        date_end_utc: this.getTask.attributes.date_end_utc
      };
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    _getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} archivos seleccionados`;
    },
    logTask() {
      this.$store
        .dispatch("listLogTasks", this.form.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$bvModal.show("modal-log-tasks");
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    showAttachment(file) {
      let arrayAllowMIME = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        "video/x-msvideo",
        "video/mp4",
        "video/webm",
        "video/ogg",
        "video/x-matroska",
        "video/quicktime"
      ];

      if (file.type) {
        if (arrayAllowMIME.indexOf(file.type) != -1) {
          this.$refs.modalAttachment.view(file);
        } else {
          window.open(file.url);
        }
      }
    },
    handleHide(value = "") {
      this.$bvModal.hide("modal-show-task");
      this.$store.dispatch(PURGE_TASK);
      this.$emit("delete-task", value);
    },
    editTask() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.errors.employee = this.$v.form.responsible.$anyError;
        this.errors.customer = this.$v.form.customer.$anyError;
        return;
      }
      this.spinner.edit = true;
      var element = document.getElementById(`editTask`);
      element.setAttribute("disabled", "true");
      const payload = {
        task_id: this.getTask.id,
        form: this.form
      };
      this.$store
        .dispatch(UPDATE_TASK, payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            element.removeAttribute("disabled");
            this.spinner.edit = false;
          }
        })
        .catch(e => {
          console.error(e);
          element.removeAttribute("disabled");
          this.spinner.edit = false;
        });
    },
    deleteTask() {
      let obj = {
        id: this.form.id,
        name: this.form.title,
        action: `deleteTask`
      };
      this.$refs.modalDeleteAction.setData(obj);
    },
    deleteAttachment(element) {
      let obj = {
        id: element.id,
        name: element.name,
        action: `deleteAttachment`
      };
      this.$refs.modalDeleteAction.setData(obj);
    },
    sendComment() {
      var element = document.getElementById(`sendComment`);
      element.setAttribute("disabled", "true");
      this.spinner.comment = true;
      const payload = {
        task_id: this.getTask.id,
        comment: this.comment
      };
      this.$store
        .dispatch(STORE_COMMENT, payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.comment = "";
            element.removeAttribute("disabled");
            this.spinner.comment = false;
          }
        })
        .catch(e => {
          console.error(e);
          element.removeAttribute("disabled");
          this.spinner.comment = false;
        });
    },
    async sendAttachments() {
      var element = document.getElementById(`sendAttachment`);
      element.setAttribute("disabled", "true");
      this.spinner.attachment = true;
      if (this.attachments) {
        var promises = [];
        let files = this.attachments;
        for (var idx in files) {
          promises.push(this.s3upload(files[idx]));
        }

        let urls = await Promise.all(promises);
        this.attachments = urls.map(_u => _u.id);
      }

      const payload = {
        task_id: this.getTask.id,
        attachments: this.attachments
      };

      this.attachments = [];
      this.$store
        .dispatch(STORE_ATTACHMENTS, payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.attachments = "";
            element.removeAttribute("disabled");
            this.spinner.attachment = false;
          }
        })
        .catch(e => {
          console.error(e);
          element.removeAttribute("disabled");
          this.spinner.attachment = false;
        });
    },
    async s3upload(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachment_type", "App\\Models\\Task");

        ApiService.post("api/v1/attachment/file", formData)
          .then(({ data }) => {
            //context.commit(SET_AUTH, data);
            this.load = false;
            resolve(data?.data[0] ?? {});
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
            this.load = false;
            reject(response);
          });
      });
    }
  },
  mounted() {}
};
</script>
<style>
/* Rounded border */
hr.rounded {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}
</style>
