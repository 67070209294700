<template>
  <b-row>
    <b-col>
      <WIDGETDEPARTMENT />
    </b-col>
  </b-row>
</template>

<script>
import WIDGETDEPARTMENT from "@/components/departments-module/widget-department";
export default {
  components: {
    WIDGETDEPARTMENT
  },
  computed: {},
  mounted() {}
};
</script>
