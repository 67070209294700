import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

export const LIST_EMPLOYEES_ALL = "listEmployeesAll";
export const LIST_EMPLOYEES_CHAT = "listEmployeesChat";

export const CHANGE_STATUS_EMPLOYEE = "changeStatusEmployee";
export const EMPLOYEE_PROFILE = "employeeProfile";
export const EMPLOYEE_PROFILE_ACTIVITY = "employeeProfileActivity";
export const LIST_FORMATIONS_PROFILE = "listFormationsProfile";

export const GET_EMPLOYEES_ALL = "getEmployeesAll";
export const GET_EMPLOYEES_CHAT = "getEmployeesChat";

export const GET_EMPLOYEE_PROFILE = "getEmployeeProfile";
export const GET_EMPLOYEE_PROFILE_ACTIVITY = "getEmployeeProfileActivity";
export const GET_LIST_FORMATIONS_PROFILE = "getListFormationsProfile";
export const GET_EMPLOYEE_PROFILE_COMMENTS = "getEmployeeProfileComments";
export const JOBS_DOCUMENTS_USER = "jobsDepartmentsUser";

export const SET_EMPLOYEE_PROFILE = "setEmployeeProfile";
export const SET_EMPLOYEE_PROFILE_ACTIVITY = "setEmployeeProfileActivity";
export const SET_EMPLOYEE_PROFILE_FORMATIONS = "setEmployeeProfileFormation";
export const SET_EMPLOYEE_PROFILE_COMMENTS = "setEmployeeProfileComments";
export const SET_JOBS_USER = "setJobsUser";

export const SET_ERROR = "setError";
export const SET_EMPLOYEES = "setEmployees";
export const SET_EMPLOYEES_CHAT = "setEmployeesChat";

export const SET_CHANGE_STATUS_EMPLOYEE = "setChangeStatusEmployee";

export const GET_JOBS_USER = "getJobsUser";

const state = {
  errors: null,
  employees_all: [],
  employees_chat: [],

  pagination: {
    total: null,
    per_page: null,
    current_page: null,
    last_page: null
  },
  employee: "",
  log: "",
  employee_profile: "",
  profile_activity: "",
  profile_task: "",
  profile_formations: "",
  profile_comments: [],
  user_jobs: [],
  formationsGroupByLesson: [],
  userCircular: []
};
const getters = {
  [GET_EMPLOYEES_ALL](state) {
    return state.employees_all;
  },
  [GET_EMPLOYEES_CHAT](state) {
    return state.employees_chat;
  },
  [GET_EMPLOYEE_PROFILE](state) {
    return state.employee_profile;
  },
  [GET_EMPLOYEE_PROFILE_ACTIVITY](state) {
    return state.profile_activity;
  },

  [GET_LIST_FORMATIONS_PROFILE](state) {
    return state.profile_formations;
  },
  [GET_EMPLOYEE_PROFILE_COMMENTS](state) {
    return state.profile_comments;
  },
  [GET_JOBS_USER](state) {
    return state.user_jobs;
  }
};
const actions = {
  [LIST_EMPLOYEES_ALL](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/employees`, {
        params: payload
      })
        .then(response => {
          context.commit(SET_EMPLOYEES, response.data);
          context.commit(SET_ERROR, null);
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_EMPLOYEES_CHAT](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/employees/all/chat`)
        .then(response => {
          context.commit(SET_EMPLOYEES_CHAT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [CHANGE_STATUS_EMPLOYEE](context, employee) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/employees/change_status/${employee.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_CHANGE_STATUS_EMPLOYEE, employee);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [EMPLOYEE_PROFILE](context, id_employee) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/employees/profile/${id_employee}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_EMPLOYEE_PROFILE, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [EMPLOYEE_PROFILE_ACTIVITY](context, id_employee) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/activities/user/${id_employee}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_EMPLOYEE_PROFILE_ACTIVITY, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_FORMATIONS_PROFILE](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/profile/formation`, {
        params: payload
      })
        .then(response => {
          context.commit(SET_EMPLOYEE_PROFILE_FORMATIONS, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [JOBS_DOCUMENTS_USER](context, user) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/employees/jobs/user/${user}`)
        .then(response => {
          context.commit(SET_JOBS_USER, response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  }
};
const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_EMPLOYEES](state, employees) {
    state.employees_all = employees.data;
  },
  [SET_EMPLOYEES_CHAT](state, employees) {
    state.employees_chat = employees;
  },
  [SET_CHANGE_STATUS_EMPLOYEE](state, employee) {
    _.map(state.employees_all, function(e) {
      if (e.id == employee.id) {
        return (e = employee);
      } else {
        return e;
      }
    });
  },
  [SET_EMPLOYEE_PROFILE](state, profile) {
    state.employee_profile = profile;
  },
  [SET_EMPLOYEE_PROFILE_ACTIVITY](state, activity) {
    state.profile_activity = activity;
  },

  [SET_EMPLOYEE_PROFILE_FORMATIONS](state, formations) {
    state.profile_formations = formations;
  },
  [SET_EMPLOYEE_PROFILE_COMMENTS](state, comments) {
    state.profile_comments = comments;
    state.errors = {};
  },
  [SET_JOBS_USER](state, employees_all) {
    state.user_jobs = employees_all;
    state.errors = {};
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
