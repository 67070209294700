import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

//action types
export const LIST_DOCUMENTS = "listDocuments";
export const LIST_DOCUMENTS_USER = "listDocumentsUser";
export const USER_DOCUMENT_VIEW = "userDocumentView";

export const SHOW_DOCUMENT = "showDocument";
export const STORE_DOCUMENT = "storeDocument";
export const UPDATE_DOCUMENT = "updateDocument";
export const DELETE_DOCUMENT = "deleteDocument";
export const ADD_DOCUMENT = "addDocument";
export const VIEW_DOCUMENT_BY_USER = "viewDocumentByUser";
export const CHANGE_STATUS_DOCUMENT = "changeStatusDocument";
export const PURGE_DOCUMENT = "purgeDocument";

//mutation types
export const SET_DOCUMENTS = "setDocuments";
export const SET_DOCUMENT = "setDocument";
export const SET_ERROR = "setError";
export const SET_LOG = "setLog";
export const PURGE_LOG = "purgeLog";
export const SET_STATUS_DOCUMENT = "setStatusDocument";
export const DELETE_DOCUMENT_CHILDREN = "deleteDocumentChildren";

//getter types
export const GET_DOCUMENTS = "getDocuments";
export const GET_DOCUMENT = "getDocument";

const state = {
  errors: null,
  documents: [],
  pagination: {
    total: null,
    per_page: null,
    current_page: null,
    last_page: null
  },
  document: [],
  log: ""
};
const getters = {
  [GET_DOCUMENTS](state) {
    return state.documents;
  },
  getDocument(state) {
    return state.document;
  }
};
const actions = {
  [USER_DOCUMENT_VIEW](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/documents/user/view/${payload.document_id}`, {
        params: payload
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [LIST_DOCUMENTS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/documents`, {
        params: payload
      })
        .then(response => {
          context.commit(SET_DOCUMENTS, response.data);
          resolve(response.data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [LIST_DOCUMENTS_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/documents/user/auth`, {
        params: payload
      })
        .then(response => {
          context.commit(SET_DOCUMENTS, response.data);
          resolve(response.data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [STORE_DOCUMENT](context, params) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/documents`, params)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_DOCUMENT, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [SHOW_DOCUMENT](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/documents/${id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_DOCUMENT, response.data.data);
            resolve(response.data.data);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [VIEW_DOCUMENT_BY_USER](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/documents/user`, payload)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            if (response.data.action === "enter") {
              context.commit(SET_LOG, response.data.data);
            } else {
              context.commit(PURGE_LOG);
            }
            resolve(response.data.data);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [CHANGE_STATUS_DOCUMENT](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/document/status/${payload}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_STATUS_DOCUMENT, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [UPDATE_DOCUMENT](context, document) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/documents/${document.id}`, document)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_DOCUMENT, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [DELETE_DOCUMENT](context, document) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/documents/${document.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_DOCUMENT, document);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [PURGE_DOCUMENT](context) {
    context.commit(PURGE_DOCUMENT);
  },
  [DELETE_DOCUMENT_CHILDREN](context, document) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/documents/${document.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(DELETE_DOCUMENT_CHILDREN, document);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  }
};
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_DOCUMENTS](state, documents) {
    state.documents = documents.data;
    state.errors = {};
  },
  [SET_STATUS_DOCUMENT](state, document) {
    let documents = [...state.documents];
    documents.map(f => {
      if (f.id == document.id) {
        f.attributes.active = document.attributes.active;
      }
    });
    state.documents = documents;
  },
  [ADD_DOCUMENT](state, document) {
    state.documents = [...state.documents, document];
  },
  [SET_DOCUMENT](state, document) {
    let doc = document;
    if (doc.attributes.type === `xlsx`) {
      doc.attributes.url = JSON.parse(doc.attributes.url);
    }
    state.document = doc;
  },
  [SET_LOG](state, log) {
    state.log = log.id;
  },
  [PURGE_LOG](state) {
    state.log = "";
  },
  [UPDATE_DOCUMENT](state, document) {
    state.documents = _.map(state.documents, function(f) {
      if (f.id == document.id) {
        return (f = document);
      } else {
        return f;
      }
    });
  },
  [USER_DOCUMENT_VIEW](state, data) {
    state.documents.forEach(document => {
      if (document.id == data.document_id) {
        _.find(document.relationships.document_user, function(du) {
          if (du.pivot.user_id == data.user_id) {
            du.pivot.viewed = 1;
            return du;
          }
        });
      }
    });
  },
  [DELETE_DOCUMENT](state, document) {
    state.documents = _.dropWhile(state.documents, {
      id: document.id
    });
  },
  [PURGE_DOCUMENT](state) {
    state.document = [];
  },
  [DELETE_DOCUMENT_CHILDREN](state, document) {
    state.documents = _.dropWhile(state.documents, function(f) {
      if (f.id == document.id) {
        return f.id == document.id;
      } else {
        return f;
      }
    });
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
