<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span
          class="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline"
          >{{ _getNameUser(this.currentUser.attributes.name) }}</span
        >
        <span
          class="text-white font-weight-bolder font-size-sm d-none d-md-inline"
          >{{ this.currentUser.attributes.rol }}</span
        >
      </div>
      <span class="symbol symbol-35">
        <b-avatar
          :text="name"
          :src="picture"
          size="3rem"
          rounded
          variant="light"
        />
        <i v-if="notifications" class="symbol-badge bg-danger"></i>
      </span>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">Perfil</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <b-avatar :text="name" :src="picture" size="8rem" rounded />
            <!-- <i class="symbol-badge bg-success"></i>-->
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bold font-size-h5 text-dark-75">
              {{ this.currentUser.attributes.name }}
            </span>
            <div class="text-muted mt-1">
              {{ this.currentUser.attributes.rol }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ this.currentUser.attributes.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="showModal">
              Cerrar sesión
            </button>
          </div>
        </div>
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <div class="navi navi-spacer-x-0 p-0">
          <div class="navi-item" style="cursor: pointer" @click="showTask">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Mis Tareas</div>
                <div class="text-muted">Ver mis tareas</div>
              </div>
            </div>
          </div>
          <router-link
            to="#"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div @click="showProfile" class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Mi perfil</div>
                <div class="text-muted">
                  Ver mi perfil
                  {{ this.currentUser.attributes.name }}
                </div>
              </div>
            </div>
          </router-link>
          <router-link
            to="#"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div @click="showHoliday" class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Mis vacaciones</div>
                <div class="text-muted">Ver mis vacaciones</div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="separator separator-dashed my-7"></div>
        <div>
          <h5 class="mb-5">Notificaciones recientes</h5>
          <template v-for="(item, i) in unreadMessages">
            <div
              @click="goChat(item)"
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-success`"
              v-bind:key="`chat-${i}`"
            >
              <span class="svg-icon mr-5" v-bind:class="`svg-icon-success`">
                <span class="svg-icon svg-icon-lg">
                  <inline-svg src="/media/svg/icons/Communication/Chat1.svg" />
                </span>
              </span>
              <div
                class="d-flex flex-column flex-grow-1 mr-2"
                style="cursor: pointer"
              >
                <a
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{
                    item.attributes.type == "direct"
                      ? _getNameDirectShow(item.attributes)
                      : item.attributes.name
                  }}
                </a>
                <span class="text-muted font-size-sm"> Mensaje nuevo </span>
              </div>
            </div>
          </template>

          <b-overlay :show="overlay" rounded="md">
            <template v-for="item in unreadCircular">
              <div
                @click="goCircular(item)"
                class="d-flex align-items-center rounded p-5 gutter-b"
                v-bind:class="`bg-light-warning`"
                v-bind:key="`circular-${item.id}`"
              >
                <span class="svg-icon mr-5" v-bind:class="`svg-icon-warning`">
                  <span class="svg-icon svg-icon-lg">
                    <inline-svg src="/media/svg/shapes/pdf.svg" />
                  </span>
                </span>
                <div
                  class="d-flex flex-column flex-grow-1 mr-2"
                  style="cursor: pointer"
                >
                  <a
                    class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                  >
                    <strong>Nueva Circular</strong><br />
                    {{ item.attributes.name }}
                  </a>
                  <span class="text-muted font-size-sm"
                    >{{ item.attributes.created }}
                  </span>
                </div>
              </div>
            </template>
          </b-overlay>
        </div>
      </perfect-scrollbar>
    </div>
    <b-modal
      id="modal-circular-show"
      scrollable
      hide-footer
      title="Ver documento"
      size="lg"
      ok-only
    >
      <SHOWDOCUMENT :data="getDocument" />
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
var _ = require("lodash");

var moment = require("moment");
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Swal from "sweetalert2";
import { EMPLOYEE_PROFILE } from "@/services/store/employee.module.js";
import { SHOW_USER_VACATION } from "@/services/store/holiday.module.js";
import { LIST_HOLIDAYS } from "@/services/store/festivity.module.js";
import { LIST_CHATS } from "@/services/store/chat.module.js";
import { LIST_DOCUMENTS_USER } from "@/services/store/document.module.js";
import SHOWDOCUMENT from "@/components/employees-module/profiles/documents/show";
import { INITIAL_STATE_FORMATIONS } from "@/services/store/formation.module.js";
import push from "push.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      overlay: false,
      ENDWORK: 2, // salida
      docType: [2], // circular,
      docSelected: ""
    };
  },
  components: {
    SHOWDOCUMENT
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getVacation",
      "getDocuments",
      "getDocument",
      "getHolidays",
      "getListChats"
    ]),
    picture() {
      return this.currentUser.relationships?.image?.public_url;
    },
    name() {
      return this.currentUser.attributes.name?.slice("")?.[0];
    },
    routeProfile() {
      return "/empleados/" + this.currentUser.id;
    },
    unreadMessages() {
      let user = this.currentUser;
      let chats = [];
      this.getListChats.forEach(chat => {
        let c = _.find(chat.relationships.chat_user, function(cu) {
          if (cu.pivot.user_id == user.id && cu.pivot.viewed == 0) {
            return cu;
          }
        });
        c ? chats.push(chat) : "";
      });
      return chats;
    },
    unreadCircular() {
      let documents = [];
      for (let i = 0; i < this.getDocuments.length; i++) {
        for (
          let j = 0;
          j < this.getDocuments[i].relationships.document_user.length;
          j++
        ) {
          if (
            this.getDocuments[i].relationships.document_user[j].pivot.user_id ==
            this.currentUser.id
          ) {
            if (
              this.docType.indexOf(
                this.getDocuments[i].attributes.doc_type_id
              ) != -1
            ) {
              if (
                !this.getDocuments[i].relationships.document_user[j].pivot
                  .viewed
              ) {
                documents.push(this.getDocuments[i]);
              }
            }
          }
        }
      }
      return documents;
    },
    notifications() {
      return (
        this?.unreadMessages?.length > 0 || this?.unreadCircular?.length > 0
      );
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_HOLIDAYS);
    this.$store.dispatch(LIST_CHATS);
    this.$store.dispatch(LIST_DOCUMENTS_USER, {
      doctype: 2
    });
  },
  created() {
    this.sockets.subscribe("DOCUMENT_CIRCULAR_ADD", document => {
      const found = _.find(document.relationships.document_user, doc_user => {
        return doc_user.pivot.user_id === this.currentUser.id;
      });
      if (found) {
        this.getDocuments.push(document);
        let title = "Nuevo circular: " + document.attributes.name;
        let body = "Descripcion: " + document.attributes.description;
        this.makeToast("warning", body, title);
      }
    });

    this.sockets.subscribe("LISTENER_CIRCULAR_DELETE", document => {
      const found = _.find(document?.relationships?.document_user, doc_user => {
        return doc_user.pivot.user_id == this.currentUser.id;
      });
      if (found) {
        this.$store.dispatch(LIST_DOCUMENTS_USER, {
          doctype: 2
        });
      }
    });
  },
  mounted() {
    this.sockets.subscribe("CHAT_ADD", chat => {
      this.$store.dispatch(LIST_CHATS);
      this.$store.commit("addChatUser", chat);
    });
    this.sockets.subscribe("CHAT_USER_VIEW", data => {
      this.$store.commit("viewedUserChat", data);
    });
    this.sockets.subscribe("MESSAGE_SEND", data => {
      this.$store.commit("enableChat", {
        chat_id: data.attributes.messageable_id
      });
      this.$store.commit("newMessageUserChat", {
        chat_id: data.attributes.messageable_id,
        viewed: 0,
        created_by: data.attributes.created_by
      });
      if (this.currentUser.id != data.attributes.created_by) {
        let chat = _.find(this.currentUser.relationships.chat_user, function(
          c
        ) {
          return c.pivot.chat_id == data.attributes.messageable_id;
        });
        if (chat) {
          let ca = _.find(this.getListChats, function(e) {
            return e.id == data.attributes.messageable_id;
          });
          let body = "";
          let title = "";
          if (ca.attributes.type != "direct") {
            //cuando es un chat publico o privado
            title = `${ca.attributes.name}`;
            body = `${data.relationships.createdBy.name}:  ${data.attributes.message} `;
          } else {
            //cuando es un chat directo
            body = `${data.attributes.message}`;
            title = `${data.relationships.createdBy.name}`;
          }

          push.create(title, {
            body: body,
            icon: "/media/svg/icons/Communication/Chat1.svg",
            timeout: 10000,
            vibrate: [100, 100, 100],
            onClick: function() {
              this.$router.push({
                name: `chats`,
                params: { chat: btoa(data.attributes.messageable_id) }
              });
              this.close();
            }
          });
          this.makeToast("success", body, title);
        }
      }
    });

    if (typeof this.currentUser.attributes === "undefined") {
      this.$router.push({ name: "login" });
    }
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    _getNameUser(data) {
      let names = data.split(" ");
      return names[0];
    },
    goChat(chat) {
      if ("/chats/" + btoa(chat.id) != this.$route.path) {
        this.$router.push({
          name: `chats`,
          params: { chat: btoa(chat.id) }
        });
      }
      this.closeOffcanvas();
    },

    goCircular(circular) {
      this.overlay = true;
      this.$store
        .dispatch("showDocument", circular.id)
        .then(res => {
          if (res) {
            this.overlay = false;
            this.closeOffcanvas();
            this.$socket.emit("VIEW_DOCUMENT_USER", {
              user_id: this.currentUser.id,
              document_id: circular.id
            });
            this.$bvModal.show("modal-circular-show");
          }
        })
        .catch(e => {
          this.overlay = false;
          if (e.response.status == 404) {
            Swal.fire("", "La circular fue eliminada!", "info");
          }
          console.error(e.response.status);
        });
    },

    _getNameDirectShow(data) {
      let names = data.name.split("-");
      return this.currentUser.id === data.created_by ? names[0] : names[1];
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      });
    },
    _getName(name) {
      return name
        .split(" ")
        .map(item => item.charAt(0))
        .slice(0, 2)
        .join("");
    },
    handleLogout() {
      const payload = {
        type_work: this.currentUser.attributes.type_work
      };
      this.$store.dispatch(LOGOUT, payload).then(() => {
        Swal.close();
        this.$router.replace({ name: "login" });
        this.$socket.emit("LOGOUT_APP");
        this.sockets.unsubscribe("MESSAGE_SEND");
        this.$store.commit(INITIAL_STATE_FORMATIONS);
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    showModal() {
      Swal.fire({
        title: "<strong>Cerrar sesión</strong>",
        icon: "info",
        html: "¿Estas seguro de cerrar la sesión?",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Si!',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> No'
      }).then(result => {
        if (result.isConfirmed) {
          this.handleLogout();
        } else {
          Swal.close();
        }
      });
    },
    showTask() {
      const payload = {
        userId: this.currentUser.id
      };
      this.$store
        .dispatch("listTasksUser", payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$router.push({
              name: "showTasksUser",
              params: {
                username: this.currentUser.attributes.name
              }
            });
          }
        })
        .catch(e => console.error({ e }));
    },
    showProfile() {
      this.$store
        .dispatch(EMPLOYEE_PROFILE, this.currentUser.id)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.$router.push({
              name: `profileEmployee`,
              params: { profile: this.currentUser.id }
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    showHoliday() {
      let action = "create";
      this.$store.dispatch(EMPLOYEE_PROFILE, this.currentUser.id);
      this.$store
        .dispatch(SHOW_USER_VACATION, { profile: this.currentUser.id })
        .then(response => {
          if (response.data.data) {
            action = "edit";
          }
          let fechas = [];
          this.getHolidays.forEach(value => {
            fechas.push(moment(value.date_calendar).format("YYYY, M, DD"));
          });
          this.$router.push({
            name: `holidayEmployee`,
            params: {
              vacation: this.currentUser.id,
              action: action,
              request: this.getVacation,
              holiday: this.getHolidays,
              prueba: fechas
            }
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
