import ApiService from "@/services/api.service";

// action types
export const LIST_ROLES = "listRoles";

// mutation types
export const SET_ROLES = "setRoles";
export const SET_ERROR = "setError";

// getter types
export const GET_ROLES = "getRoles";

const state = {
  errors: null,
  roles: []
};

const getters = {
  getRoles(state) {
    return state.roles;
  }
};

const actions = {
  [LIST_ROLES](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/roles`)
        .then(response => {
          context.commit(SET_ROLES, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_ROLES](state, roles) {
    state.roles = roles;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
