import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

//action
export const LIST_VACATIONS = "listVacations";
export const LIST_VACATIONS_REQUEST = "listVacationsRequest";
export const LIST_VACATIONS_ALL = "listVacationsAll";
export const LIST_EMPLOYEES_SETTING = "listEmployeesSetting";
export const CHANGE_EMPLOYEE_PERMISSION = "changeEmployeePermission";

export const SHOW_VACATION = "showVacation";
export const STORE_VACATION = "storeVacation";
export const DELETE_VACATION = "deleteVacation";
export const UPDATE_VACATION = "updateVacation";
export const STATUS_VACATION = "statusVacation";
export const PURGE_VACATION = "purgeVacation";
export const UPDATE_USER_DAYS = "updateUserDays";
export const SHOW_USER_VACATION = "showUserVacation";

//mutation
export const SET_VACATIONS = "setVacations";
export const SET_VACATIONS_REQUEST = "setVacationsRequest";
export const SET_VACATIONS_ALL = "setVacationsAll";
export const SET_VACATION = "setVacation";
export const SET_ERROR = "setError";
export const SET_STATUS_VACATION = "setStatusVacation";
export const SET_UPDATE_VACATION = "setUpdateVacation";
export const ADD_VACATION = "addVacation";
export const SET_UPDATE_USER_DAYS = "setUpdateUserDays";
export const SET_EMPLOYEES_SETTING = "setEmployeesSetting";
export const SET_CHANGE_EMPLOYEE_PERMISSION = "setChangeEmployeePermission";

//getter
export const GET_VACATIONS = "getVacations";
export const GET_VACATIONS_REQUEST = "getVacationsRequest";
export const GET_VACATION = "getVacation";
export const GET_VACATIONS_ALL = "getVacationAll";
export const GET_EMPLOYEES_SETTING = "getEmployeesSetting";

//other

const state = {
  errors: null,

  vacations: [],
  vacation: "",
  employees: "",
  vacations_request: [],
  vacations_all: [],
  employees_setting: []
};

const getters = {
  [GET_VACATIONS](state) {
    return state.vacations;
  },
  [GET_VACATION](state) {
    return state.vacation;
  },
  [GET_VACATIONS_REQUEST](state) {
    return state.vacations_request;
  },
  [GET_VACATIONS_ALL](state) {
    return state.vacations_all;
  },

  [GET_EMPLOYEES_SETTING](state) {
    return state.employees_setting;
  }
};

const actions = {
  [CHANGE_EMPLOYEE_PERMISSION](context, employee) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/employees/change_permission/${employee.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_CHANGE_EMPLOYEE_PERMISSION, employee);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_EMPLOYEES_SETTING](context) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/employees/setting/vacation`)
        .then(response => {
          if (response.status == 200) {
            context.commit(SET_EMPLOYEES_SETTING, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_VACATION);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },

  [STORE_VACATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/vacations`, payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(ADD_VACATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_VACATION](context, vacation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/vacations/${vacation.id}`)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(DELETE_VACATION, vacation);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [LIST_VACATIONS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/vacations`, { params: payload })
        .then(response => {
          if (response.status == 200) {
            context.commit(SET_VACATIONS, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_VACATION);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_VACATIONS_REQUEST](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/vacation/request`, { params: payload })
        .then(response => {
          if (response.status == 200) {
            context.commit(SET_VACATIONS_REQUEST, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_VACATION);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [LIST_VACATIONS_ALL](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/vacation/all/vacations`, { params: payload })
        .then(response => {
          if (response.status == 200) {
            context.commit(SET_VACATIONS_ALL, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response.data.data);
          } else if (response.status == 403) {
            context.commit(PURGE_VACATION);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_VACATION](context, id) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/vacations/${id}`)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_VACATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  //para actualizar solicitud de vacacion
  [UPDATE_VACATION](context, vacation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/vacations/update/${vacation.id}`, vacation)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_UPDATE_VACATION, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  //para aprobar o rechazar la solicitud
  [STATUS_VACATION](context, vacation) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/vacation/approved123/${vacation.id}`, vacation)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_STATUS_VACATION, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },

  //para actualizar available_days_vacation de user
  [UPDATE_USER_DAYS](context, user) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/users/update/vacation/${user.id_profile}`, user)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_UPDATE_USER_DAYS, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [SHOW_USER_VACATION](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(
        payload.id
          ? `api/v1/show/user/vacation/${payload.profile}/${payload.id}`
          : `api/v1/show/user/vacation/${payload.profile}`
      )
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_VACATION, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [PURGE_VACATION](context) {
    context.commit(PURGE_VACATION);
  }
};

const mutations = {
  [ADD_VACATION](state, vacation) {
    state.vacations_request = [...state.vacations_request, vacation];
  },
  [PURGE_VACATION](state) {
    state.vacation = [];
  },
  [SET_UPDATE_USER_DAYS](state, employees) {
    state.employees = _.map(state.employees, function(f) {
      if (f.id == employees.id) {
        return (f = employees);
      } else {
        return f;
      }
    });
  },
  [DELETE_VACATION](state, vacation) {
    state.vacations = _.dropWhile(state.vacations, { id: vacation.id });
  },
  [SET_UPDATE_VACATION](state, vacation) {
    state.vacations = _.map(state.vacations, function(f) {
      if (f.id == vacation.id) {
        return (f = vacation);
      } else {
        return f;
      }
    });
  },
  [SET_STATUS_VACATION](state, vacation) {
    state.vacations_all = _.map(state.vacations_all, function(f) {
      if (f.id == vacation.id) {
        return (f = vacation);
      } else {
        return f;
      }
    });
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_VACATIONS](state, vacations) {
    state.vacations = vacations;
    state.errors = {};
  },
  [SET_VACATIONS_REQUEST](state, vacations_request) {
    state.vacations_request = vacations_request;
    state.errors = {};
  },
  [SET_VACATIONS_ALL](state, vacations_all) {
    state.vacations_all = vacations_all;
    state.errors = {};
  },
  [SET_VACATION](state, vacation) {
    state.vacation = vacation;
  },
  [SET_EMPLOYEES_SETTING](state, employees) {
    state.employees_setting = employees;
  },
  [SET_CHANGE_EMPLOYEE_PERMISSION](state, employee) {
    _.map(state.employees_setting, function(e) {
      if (e.id == employee.id) {
        return (e = employee);
      } else {
        return e;
      }
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
