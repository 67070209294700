<template>
  <div class="card-body p-0">
    <div
      class="wizard wizard-3"
      id="kt_wizard_v3"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
        <div class="col-xl-12 col-xxl-12">
          <div
            class="pb-4"
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <h4 class="mb-4 font-weight-bold text-dark">
              Información del contrato
            </h4>
            <b-form>
              <b-form-group
                id="input-group-1"
                label="Titulo del documento"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  type="text"
                  required
                  placeholder="Titulo del documento"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  El nombre es requerido
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Descripción del contrato"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea-no-resize"
                  placeholder="Descripción"
                  rows="3"
                  no-resize
                  v-model="form.description"
                />
              </b-form-group>

              <b-form-group
                label="Archivo"
                label-for="input-3"
                v-if="this.action != `edit`"
              >
                <b-form-file
                  id="attachment"
                  v-model="attachment"
                  :state="Boolean(attachment)"
                  :accept="allowedExtensions"
                  placeholder="Subir un archivo"
                  drop-placeholder="Arrastrar archivo aquí..."
                />
              </b-form-group>

              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>

              <b-button
                type="button"
                ref="btnSubmit"
                variant="outline-primary"
                @click="onSubmit"
                >Guardar</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  STORE_DOCUMENT,
  UPDATE_DOCUMENT
} from "@/services/store/document.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";

import Swal from "sweetalert2";

//var _ = require("lodash");

export default {
  mixins: [validationMixin],
  props: ["document", "action"],
  components: {},
  data() {
    return {
      attachment: null,
      form: {
        id: this.action === `edit` ? this.document.id : "",
        name: this.action === `edit` ? this.document.attributes.name : "",
        description:
          this.action === `edit` ? this.document.attributes.description : "",
        url: "",
        type: ""
      },
      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters([
      "getBreadcrumbDoctype",
      "getBreadcrumbPath",
      "getDoctype",
      "getEmployeeProfile"
    ]),
    allowedExtensions() {
      let extensions = "";
      if (this.getBreadcrumbDoctype.name === "contratos") {
        extensions = ".pdf";
      }
      return extensions;
    }
  },
  methods: {
    nameWithLang({ text }) {
      return `${text}`;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.action === `edit`) {
        this.update();
      } else {
        const files = document.getElementById("attachment").files;
        if (!files[0]) {
          Swal.fire("", "Debes subir algún archivo", "error");
          return;
        }

        const submitButton = this.$refs["btnSubmit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        await this.s3upload();
      }
    },
    async s3upload() {
      const files = document.getElementById("attachment").files;
      var file = files[0];

      this.form.name = this.$v.form.name.$model;
      this.form.type = file.type;
      this.form.doctype = this.getDoctype.doctype.id;
      //usuario de perfil
      this.form.employees = [
        {
          value: this.getEmployeeProfile.id,
          text: this.getEmployeeProfile.attributes.name
        }
      ];

      this.form.path = this.getBreadcrumbPath
        ? `${this.getBreadcrumbPath}/${this.form.name}`
        : "";
      this.load = true;

      const ACTION = this.form.document ? STORE_DOCUMENT : STORE_DOCUMENT;

      return await new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachment_type", "App\\Models\\Document");

        ApiService.post("api/v1/attachment/file", formData)
          .then(({ data }) => {
            this.load = false;
            this.form.doctype = this.getDoctype.doctype.id;
            this.form.attachment = data?.data[0]?.id;
            this.$store.dispatch(ACTION, this.form).then(response => {
              if (response.status == 200 || response.status == 201) {
                this.$refs["btnSubmit"].classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
                Swal.fire("Ok!", "Se creo el contrato exitosamente", "success");
                this.$emit("store-contract", "modal-contract");
              }
            });
            resolve(data?.data[0] ?? {});
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
            this.load = false;
            Swal.fire("", "Por favor! intente de nuevo", "error");
            reject(response);
          });
      });
    },
    async update() {
      const submitButton = this.$refs["btnSubmit"];
      this.form.doctype = this.getBreadcrumbDoctype.id ?? "";
      const ACTION = this.form.document ? UPDATE_DOCUMENT : UPDATE_DOCUMENT;
      await this.$store
        .dispatch(ACTION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se modifico el contrato exitosamente", "success");
            this.$emit(
              "store-contract-update",
              `modal-contract-${this.form.id}`
            );
            //this.$emit("store-contract", "modal-contract");
          }
        })
        .catch(e => {
          console.log(e.response.data);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  },
  mounted() {}
};
</script>
