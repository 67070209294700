import ApiService from "@/services/api.service";
var _ = require("lodash");
const httpStatus = [200, 201];

// action types
export const LIST_COMMENTS = "listCommentsProfile";
export const SHOW_COMMENT_PROFILE = "showCommentProfile";
export const STORE_COMMENT_PROFILE = "storeCommentProfile";
export const UPDATE_COMMENT_PROFILE = "updateCommentProfile";
export const DELETE_COMMENT_PROFILE = "deleteCommentProfile";
export const PUT_COMMENT = "putCommentProfile";
export const SET_DELETE_COMMENT_PROFILE = "setDeleteCommentProfile";
export const SET_COMMENT_PROFILE = "setCommentProfile";
export const COMMENT_PROFILE = "commentProfile";
// mutation types
export const SET_COMMENTS = "setCommentsProfile";
export const SET_ERROR = "setError";
export const ADD_COMMENT = "addComment";
export const ADD_FORMATION = "addFormation";
// getter types
export const GET_COMMENTS = "getCommentsProfile";

const state = {
  errors: null,
  comments: [],
  contentsComment: [],
  commentProfile: ""
};

const getters = {
  [GET_COMMENTS](state) {
    return state.comments;
  },
  getContentComment(state) {
    return state.contentsComment;
  },
  getCommentProfile(state) {
    return state.commentProfile;
  }
};

const actions = {
  [LIST_COMMENTS](context, payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/comments`, { params: payload })
        .then(response => {
          context.commit(SET_COMMENTS, response.data.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [STORE_COMMENT_PROFILE](context, comment) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/employees/profile/comment/store`, comment)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(ADD_COMMENT, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },

  [UPDATE_COMMENT_PROFILE](context, comment) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `api/v1/employees/profile/comment/update/${comment.id}`,
        comment
      )
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(UPDATE_COMMENT_PROFILE, response.data.data);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },

  [DELETE_COMMENT_PROFILE](context, comment) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/employees/profile/comment/${comment.id}`)
        .then(response => {
          if (httpStatus.indexOf(response.status) != -1) {
            context.commit(SET_DELETE_COMMENT_PROFILE, comment);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  },
  [COMMENT_PROFILE](context, comment) {
    context.commit(SET_COMMENT_PROFILE, comment);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_COMMENTS](state, comments) {
    state.comments = comments;
    state.errors = {};
  },
  [UPDATE_COMMENT_PROFILE](state, comment) {
    if (comment.attributes.parent_id) {
      state.contentsComment.relationships.children = _.map(
        state.contentsComment.relationships.children,
        function(f) {
          if (f.id == comment.id) {
            return (f = comment);
          } else {
            return f;
          }
        }
      );
    } else {
      state.comments = _.map(state.comments, function(f) {
        if (f.id == comment.id) {
          return (f = comment);
        } else {
          return f;
        }
      });
    }
  },

  [SET_DELETE_COMMENT_PROFILE](state, comment) {
    state.comments = _.dropWhile(state.comments, function(f) {
      return f.id == comment.id;
    });
  },

  [ADD_COMMENT](state, comment) {
    if (comment.attributes.parent_id) {
      let source = state.contentsComment;
      source.relationships.children.unshift(comment);
    } else {
      state.comments.unshift(comment);
    }
  },

  [SET_COMMENT_PROFILE](state, comment) {
    state.comment = comment;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
