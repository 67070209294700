import Vue from "vue";
import Router from "vue-router";
import store from "@/services/store";
import LAYOUT from "@/view/layout/Layout";

// formations
import SEGMENT from "@/view/pages/segments/show";
import FORMATION from "@/view/pages/formations/show";
import FORMATIONS from "@/view/pages/formations";
// ===================== //

// activities
import ACTIVITIES from "@/view/pages/activities";
import EMPLOYEES from "@/view/pages/employees";
import PROFILEEMPLOYEE from "@/view/pages/employees/profile";
import SHOWACTIVITESUSER from "@/view/pages/activities/show";

//holidays
import HOLIDAYS from "@/view/pages/holidays";
import HOLIDAYEMPLOYEE from "@/view/pages/holidays/show";
import SHOWSTATUSVACATION from "@/components/holidays-module/vacation/widget-status-vacation";
import LISTUSERVACATION from "@/components/holidays-module/list/list-user-vacation";
import DEPARTMENTS from "@/view/pages/departments";
import SHOWDEPARTMENT from "@/view/pages/departments/show";
import REPORTDEPARTMENT from "@/view/pages/departments/report";
import SHOWREPORT from "@/components/departments-module/show-report-department";
// ===================== //
// tasks
import TASKS from "@/view/pages/tasks";
import CHATS from "@/view/pages/chats";

import SHOWDEPARTMENTTASKS from "@/view/pages/tasks/showDepartmentTasks";
import SHOWUSERTASKS from "@/view/pages/tasks/showUserTasks";
// ===================== //
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: LAYOUT,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/",
      redirect: "/admin",
      component: LAYOUT,
      children: [
        {
          path: "/admin",
          name: "admin",
          component: () => import("@/view/pages/AdminDashboard.vue"),
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/formaciones",
      component: LAYOUT,
      children: [
        {
          path: "/formaciones",
          name: "formations",
          component: FORMATIONS,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":segment",
          name: "formationSegment",
          component: SEGMENT,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":path+",
          name: "showFormation",
          component: FORMATION,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/actividades",
      component: LAYOUT,
      children: [
        {
          path: "/actividades",
          name: "activities",
          component: ACTIVITIES,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":username",
          name: "showActivitiesUser",
          component: SHOWACTIVITESUSER,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/tareas",
      component: LAYOUT,
      children: [
        {
          path: "/tareas",
          name: "tasks",
          component: TASKS,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":department",
          name: "showDepartmentTasks",
          component: SHOWDEPARTMENTTASKS,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":username",
          name: "showTasksUser",
          component: SHOWUSERTASKS,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/chats",
      component: LAYOUT,
      children: [
        {
          path: ":chat",
          name: "chats",
          component: CHATS,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/empleados",
      component: LAYOUT,
      children: [
        {
          path: "/empleados",
          name: "employees",
          component: EMPLOYEES
        },
        {
          path: ":profile",
          name: "profileEmployee",
          component: PROFILEEMPLOYEE,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/departamentos",
      component: LAYOUT,
      children: [
        {
          path: "/departamentos",
          name: "departments",
          component: DEPARTMENTS
        },
        {
          path: ":department",
          name: "showDepartment",
          component: SHOWDEPARTMENT,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":report",
          name: "showReportDepartment",
          component: REPORTDEPARTMENT,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":department/:report",
          name: "showReport",
          component: SHOWREPORT,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/vacaciones",
      component: LAYOUT,
      children: [
        {
          path: "/vacaciones",
          name: "holidays",
          component: HOLIDAYS
        },
        {
          path: ":vacation",
          name: "holidayEmployee",
          component: HOLIDAYEMPLOYEE,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":vacation",
          name: "showStatusVacation",
          component: SHOWSTATUSVACATION,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        },
        {
          path: ":username",
          name: "showVacationUser",
          component: LISTUSERVACATION,
          beforeEnter: (to, from, next) => {
            if (store.state.auth.errors) {
              if (
                store.state.auth.errors.status == 401 ||
                store.state.auth.errors.status == 403
              ) {
                store.commit("logOut");
                next({ name: "login" });
              } else {
                next();
              }
            }
            next();
          }
        }
      ]
    },
    {
      path: "/login",
      redirect: "/login",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
          beforeEnter: (to, from, next) => {
            delete localStorage.token;
            next();
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
