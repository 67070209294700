<template>
  <b-container>
    <b-overlay :show="overlay" rounded="md">
      <b-row>
        <b-col cols md="12">
          <b-btn variant="primary" @click="showOwnerTasks"
            >Ver mis tareas</b-btn
          >
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols md="12 mt-4">
          <h3>Departamentos</h3>
        </b-col>
      </b-row>

      <b-row v-if="departments.length > 0">
        <b-col
          cols
          md="3"
          class="mt-4"
          v-for="(element, idx) in getDepartments"
          :key="idx"
        >
          <b-card
            class="card-custom card-stretch gutter-b bg-success"
            @click="show(element)"
            style='cursor:pointer;background-position: right top;
                background-size: 18%; background-image: url("media/svg/shapes/departments.svg");'
          >
            <b-row>
              <blockquote class="blockquote mb-0">
                <p
                  class="font-weight-bold text-light-75 text-light font-size-lg mb-1"
                  style="cursor:pointer; font-size: 16px"
                >
                  {{ element.attributes.name }}
                </p>
              </blockquote>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols md="12" class="mt-4">
          <b-card no-body>
            <b-card-text class="text-center">
              <h5>
                <img src="media/svg/misc/empty.svg" alt="" />
                <p class="mt-6">No perteneces a ningún departamento</p>
              </h5>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { LIST_DEPARTMENTS } from "@/services/store/department.module";
import { LIST_EMPLOYEES } from "@/services/store/task.module";
export default {
  data() {
    return {
      overlay: false
    };
  },
  computed: {
    ...mapGetters(["getDepartments", "currentUser"]),
    departments() {
      let arrayDepartments = [];

      if (typeof this.getDepartments !== undefined) {
        arrayDepartments = this.getDepartments;
      }
      return arrayDepartments;
    }
  },
  beforeMount() {
    this.$store.dispatch(LIST_DEPARTMENTS);
  },
  methods: {
    show(element) {
      this.overlay = true;
      this.$store
        .dispatch(LIST_EMPLOYEES, element.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            let department = response.data.department;
            this.$store.commit("setDepartmentID", department.id);
            this.overlay = false;
            this.$router.push({
              name: "showDepartmentTasks",
              params: { department: department.name }
            });
          }
        })
        .catch(e => console.error(e));
    },
    showOwnerTasks() {
      const payload = {
        userId: this.currentUser.id
      };
      this.$store
        .dispatch("listTasksUser", payload)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.$router.push({
              name: "showTasksUser",
              params: {
                username: this.currentUser.attributes.name
              }
            });
          }
        })
        .catch(e => console.error({ e }));
    }
  },
  mounted() {}
};
</script>
