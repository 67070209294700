import ApiService from "@/services/api.service";
var _ = require("lodash");

// action types

export const STORE_REPORT = "storeReport";
export const STORE_INSERT_REPORT = "storeInsertReport";
export const UPDATE_INSERT_REPORT = "updateInsertReport";
export const DELETE_REPORT = "deleteReport";
// export const DELETE_REPORTS_DEPARTMENT = "deleteReportDepartment";

// mutation types
export const SET_REPORTS = "setReports";
export const SET_ERROR = "setError";
export const ADD_REPORT = "addReport";
export const ADD_INSERT_REPORT = "addInsertReport";
export const SET_DELETE_REPORT = "setDeleteReport";

// getter types
export const GET_REPORTS = "getReports";
export const GET_INSERT_REPORTS = "getInsertReports";

const state = {
  errors: null,
  reports: [],
  contentsReport: [],
  insertReport: []
};

const getters = {
  [GET_REPORTS](state) {
    return state.reports;
  },
  [GET_INSERT_REPORTS](state) {
    return state.insertReport;
  }
};

const actions = {
  [STORE_REPORT](context, report) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/reports`, report)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(ADD_REPORT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [STORE_INSERT_REPORT](context, report) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/reports/department/report`, report)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(ADD_INSERT_REPORT, response.data.data);
            context.commit(SET_ERROR, null);
            resolve(response);
          }
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [UPDATE_INSERT_REPORT](context, report) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/reports/department/report/${report.id}`, report)
        .then(response => {
          context.commit(UPDATE_INSERT_REPORT, response.data.data);
          context.commit(SET_ERROR, null);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response);
          } else {
            context.commit(SET_ERROR, error);
          }
          reject(error);
        });
    });
  },
  [DELETE_REPORT](context, report) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/v1/reports/${report.id}`)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            context.commit(SET_DELETE_REPORT, report);
            resolve(response);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data.errors);
          reject(error);
        });
    });
  }
  // [DELETE_REPORTS_DEPARTMENT](context, report) {
  //   ApiService.setHeader();
  //   return new Promise((resolve, reject) => {
  //     ApiService.delete(`api/v1/departments/report/delete/${report.id}`)
  //       .then(response => {
  //         if (response.status == 200 || response.status == 201) {
  //           context.commit(DELETE_REPORTS_DEPARTMENT, response.data.data);
  //           resolve(response);
  //         }
  //       })
  //       .catch(error => {
  //         context.commit(SET_ERROR, error.response.data.errors);
  //         reject(error);
  //       });
  //   });
  // }
};
const mutations = {
  [SET_ERROR](state, error) {
    sessionStorage.setItem("auth.errors", error);
  },
  [SET_REPORTS](state, reports) {
    state.reports = reports;
  },
  [ADD_REPORT](state, report) {
    state.reports = [...state.reports, report];
  },
  [ADD_INSERT_REPORT](state, datos) {
    state.insertReport = [...state.insertReport, datos];
  },
  [UPDATE_INSERT_REPORT](state, datos) {
    state.insertReport = [...state.insertReport, datos];
    // state.insertReport = _.map(state.insertReport, function(f) {
    //   if (f.id == datos.id) {
    //     return (f = datos);
    //   } else {
    //     return f;
    //   }
    // });
  },
  [SET_DELETE_REPORT](state, report) {
    state.insertReport = _.dropWhile(state.insertReport, {
      id: report.id
    });
  }

  // [DELETE_REPORTS_DEPARTMENT](state, report) {
  //   state.reports = _.dropWhile(state.reports, function(f) {
  //     return f.id == report.id;
  //   });
  // }
};

export default {
  state,
  actions,
  mutations,
  getters
};
