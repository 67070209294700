<template>
  <div class="card-body p-0">
    <div
      class="wizard wizard-3"
      id="kt_wizard_v3"
      data-wizard-state="step-first"
      data-wizard-clickable="true"
    >
      <div class="row justify-content-center py-12 px-8 py-lg-1 px-lg-10">
        <div class="col-xl-12 col-xxl-12">
          <div
            class="pb-5"
            data-wizard-type="step-content"
            data-wizard-state="current"
          >
            <h4 class="mb-4 font-weight-bold text-dark">
              Información del Circular
            </h4>
            <b-form>
              <b-form-group
                id="input-group-1"
                label="Titulo del documento"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  type="text"
                  required
                  placeholder="Titulo de la Circular"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  El nombre es requerido
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Descripción de la circular"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea-no-resize"
                  placeholder="Descripción"
                  rows="3"
                  no-resize
                  v-model="form.description"
                />
              </b-form-group>

              <b-form-group
                label="Archivo"
                label-for="input-3"
                v-if="this.action != `edit`"
              >
                <b-form-file
                  id="attachment"
                  v-model="attachment"
                  :state="Boolean(attachment)"
                  :accept="allowedExtensions"
                  placeholder="Subir un archivo"
                  drop-placeholder="Arrastrar archivo aquí..."
                />
              </b-form-group>
              <!-- aqui es para editar multiselect -->
              <div v-if="this.action === `edit`">
                <h6>Actualmente estos usuarios pueden ver el documento</h6>

                <b-badge
                  variant="secondary"
                  v-for="user in this.getUsersByDocument"
                  :key="user.id"
                  class="m-2 mb-4"
                >
                  {{ user.text }}
                </b-badge>
              </div>
              <!-- fin editar -->
              <hr />
              <multiselect
                v-model="form.employees"
                :options="users_all"
                :multiple="true"
                :group-select="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Seleccione un(os) usuarios(s)"
                label="text"
                track-by="text"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} seleccionados</span
                  ></template
                >
              </multiselect>
              <b-form-group
                id="input-group-6"
                label="Usuarios que pueden ver el circular"
                label-for="input-6"
              >
              </b-form-group>
              <!-- fin ultiselct -->
              <b-progress
                :max="max"
                v-if="load"
                class="mb-4"
                style="margin-bottom: 5px"
              >
                <b-progress-bar
                  :value="this.loading"
                  :label="`${this.loading}%`"
                ></b-progress-bar>
              </b-progress>

              <b-button
                type="button"
                ref="btnSubmit"
                variant="outline-primary"
                @click="onSubmit"
                >Guardar</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

import {
  STORE_DOCUMENT,
  UPDATE_DOCUMENT
} from "@/services/store/document.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/services/api.service";

import Swal from "sweetalert2";

var _ = require("lodash");
export default {
  mixins: [validationMixin],
  props: ["document", "action"],
  components: {
    Multiselect
  },
  data() {
    return {
      attachment: null,
      form: {
        id: this.action === `edit` ? this.document.id : "",
        name: this.action === `edit` ? this.document.attributes.name : "",
        description:
          this.action === `edit` ? this.document.attributes.description : "",
        employees: this.action == `edit` ? this.getUsersByDocument : [],
        url: "",
        type: ""
      },
      errors: "",
      loading: 0,
      load: false,
      max: 100
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters([
      "getEmployeesAll",
      "getBreadcrumbDoctype",
      "getBreadcrumbPath",
      "getDoctype",
      "getEmployeeProfile",
      "getDocument"
    ]),
    users_all() {
      const employees = [
        {
          value: 0,
          text: "Todos"
        }
      ];

      _.forEach(this.getEmployeesAll, d => {
        employees.push({ value: d.id, text: d.attributes.name });
      });

      return employees;
    },

    getUsersByDocument() {
      if (this.action === `edit`) {
        return _.map(this.document.relationships.employees, d => {
          return { value: d.id, text: d.name };
        });
      }
      return [];
    },
    allowedExtensions() {
      let extensions = "";
      if (this.getBreadcrumbDoctype.name === "circulares") {
        extensions = ".pdf";
      }
      return extensions;
    }
  },
  methods: {
    nameWithLang({ text }) {
      return `${text}`;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (_.some(this.form.employees, ["value", 0])) {
        this.form.employees = _.filter(this.users_all, d => {
          return d.value != 0;
        });
      }

      if (this.action === `edit`) {
        this.update();
      } else {
        const files = document.getElementById("attachment").files;
        if (!files[0]) {
          Swal.fire("", "Debes subir algún archivo", "error");
          return;
        }

        if (this.form.employees == 0) {
          Swal.fire("Error!", "Debes Seleccionar al menos un Usuario", "error");

          return;
        }

        const submitButton = this.$refs["btnSubmit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        await this.s3upload();
      }
    },
    async s3upload() {
      const files = document.getElementById("attachment").files;
      var file = files[0];

      this.form.name = this.$v.form.name.$model;
      this.form.type = file.type;
      this.form.doctype = this.getDoctype.id;
      this.form.path = this.getBreadcrumbPath
        ? `${this.getBreadcrumbPath}/${this.form.name}`
        : "";
      this.load = true;

      const ACTION = this.form.document ? STORE_DOCUMENT : STORE_DOCUMENT;

      return await new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("attachment_type", "App\\Models\\Document");

        ApiService.post("api/v1/attachment/file", formData)
          .then(({ data }) => {
            this.load = false;
            this.form.doctype = this.getDoctype.doctype.id;
            this.form.attachment = data?.data[0]?.id;
            this.$store.dispatch(ACTION, this.form).then(response => {
              if (response.status == 200 || response.status == 201) {
                this.$refs["btnSubmit"].classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
                );
                Swal.fire("Ok!", "Se creo la circular exitosamente", "success");
                this.$socket.emit("ADD_DOCUMENT_CIRCULAR", response.data.data);

                this.$emit("store-circular", "modal-circular");
              }
            });
            resolve(data?.data[0] ?? {});
          })
          .catch(({ response }) => {
            //context.commit(SET_ERROR, response.data.errors);
            this.load = false;
            Swal.fire("", "Por favor! intente de nuevo", "error");
            reject(response);
          });
      });
    },
    async update() {
      const submitButton = this.$refs["btnSubmit"];
      this.form.doctype = this.getBreadcrumbDoctype.id ?? "";
      const ACTION = this.form.document ? UPDATE_DOCUMENT : UPDATE_DOCUMENT;
      await this.$store
        .dispatch(ACTION, this.form)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire("Ok!", "Se modifico la circular exitosamente", "success");
            this.$emit(
              "store-circular-update",
              `modal-circular-${this.form.id}`
            );
          }
        })
        .catch(e => {
          console.log(e);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire("", "Por favor! Intente de nuevo.", "error");
        });
    }
  },
  mounted() {}
};
</script>
