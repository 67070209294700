<template>
  <b-container>
    <b-overlay :show="overlay" rounded="md">
      <b-card>
        <AVATAREMPLOYEES
          @show-task-by-user="showTaskByUser"
          v-if="permissions && showInThisRoute"
        />
        <MODALADDTASK v-if="permissions && showInThisRoute" />
        <KANBANBOARD ref="kanbanComponent" @show-task="showTask" />
      </b-card>
      <MODALTASK ref="modalTaskShow" @delete-task="deleteTask" />
    </b-overlay>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import { VueDraggableDirective } from "vue-draggable";
import AVATAREMPLOYEES from "@/components/tasks-module/avatar-employees";
import MODALADDTASK from "@/components/tasks-module/modal-add-task";
import MODALTASK from "@/components/tasks-module/modal-task";
import KANBANBOARD from "@/components/tasks-module/kanban-board";
import { CHANGE_STATUS } from "@/services/store/task.module";

export default {
  directives: {
    dragAndDrop: VueDraggableDirective
  },
  components: {
    MODALADDTASK,
    MODALTASK,
    KANBANBOARD,
    AVATAREMPLOYEES
  },
  data() {
    return {
      errors: "",
      overlay: false,
      users: [],
      modalShow: false,
      taskSelected: "",
      rolesCreate: [1, 2, 3],
      doNotShowInTheseRoutes: ["showTasksUser", "profileEmployee"]
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    permissions() {
      return this.rolesCreate.indexOf(this.currentUser.attributes.rol_id) != -1;
    },
    showInThisRoute() {
      let show = true;
      if (this.doNotShowInTheseRoutes.indexOf(this.$route.name) != -1) {
        show = false;
        return;
      } else if (
        this.$route.params.department == this.currentUser.attributes.name
      ) {
        //show = false;
        //return;
      }
      return show;
    }
  },
  methods: {
    added(payload) {
      this.$store.dispatch(CHANGE_STATUS, payload);
    },
    deleteTask(task) {
      this.$refs.kanbanComponent.listenerRemoveTask(task);
    },
    showTask(task) {
      this.overlay = true;
      this.$store
        .dispatch("showTask", task.id)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            this.overlay = false;
            this.$refs.modalTaskShow.show();
          }
        })
        .catch(e => {
          this.overlay = false;
          console.error(e);
        });
    },
    showTaskByUser(id) {
      if (this.users.includes(id)) {
        const index = this.users.indexOf(id);
        if (index > -1) {
          this.users.splice(index, 1);
        }
      } else {
        this.users.push(id);
      }

      this.$refs.kanbanComponent.setValue(this.users);
    },
    hideModal() {
      this.taskSelected = "";
      this.modalShow = false;
    }
  }
};
</script>
